import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {injectIntl} from "react-intl"
import {createSelector} from "reselect"
import {replace} from "connected-react-router"
import queryString from "query-string"
import isEqual from "lodash/isEqual"
import {Row, Col} from "react-grid-system"
import styled, {css, ThemeProvider} from "styled-components"

import * as actions from "../../../data/actions"
import * as selectors from "../../../data/selectors"
import {BranchManager} from "../../../data/utils"

import CButton from "../../../view/components/CButton"
import CIcon from "../../../view/components/CIcon"
import CBranchQrCode from "../../../view/components/CBranchQrCode"

import CH3 from "../../../view/components/text/CH3"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CSectionGalleryPublicWalls", ...args)

const AMOUNT_WALLS_INIT = 4
const AMOUNT_WALLS_ADDED_PER_LOAD_MORE_STEP = 4
const USE_LOAD_MORE = true // not working in conjunction with artworks (yet)

class CSectionGalleryPublicWalls extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    defaultThemeProps: PropTypes.object,

    labelHeader: PropTypes.string,
    labelSubheader: PropTypes.string,
    makeYourOwnGalleryButtonLabel: PropTypes.string,
    noWallsYetLabel: PropTypes.string,
    noBottomSeparatorMobile: PropTypes.bool,
    loadMoreButtonLabel: PropTypes.string,
    ownGalleryUrl: PropTypes.string, // optional, only when used in SGalleryLandingArtworks
    centerContent: PropTypes.bool,

    galleryTitle: PropTypes.string,
    gallerySlug: PropTypes.string.isRequired,
    gallerySecretCode: PropTypes.string,
    galleryBranchShareInfos: PropTypes.object,
    publicWalls: PropTypes.array,
    fetching: PropTypes.bool,

    // connect
    embeddedMode: PropTypes.bool,
    queryProps: PropTypes.object,
    wallsAmountShown: PropTypes.number,
  }
  static defaultProps = {
    debug: DEBUG,
    defaultThemeProps: {
      // backgroundColor: "white",
      textColor: "black",
    },
    maxHeightDescription: 200,
    centerContent: false,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  loadMoreItems = () => {
    const {wallsAmountShown} = this.props
    this.updateQueryProps({mw: wallsAmountShown + AMOUNT_WALLS_ADDED_PER_LOAD_MORE_STEP})
  }

  updateQueryProps = (propsShortKeyed = {}) => {
    let propsNext = {...this.props.queryProps, ...propsShortKeyed}
    let propsFiltered = {}
    for (const queryKey of Object.keys(propsNext)) {
      switch (queryKey) {
        case "embed":
          // ignore
          break
        case "ma":
        case "mw": {
          // max amount shown walls
          propsFiltered[queryKey] = propsNext[queryKey]
          break
        }
        default: {
          if (__DEV__) {
            throw new Error(`updateQueryProps(): Unhandled queryKey '${queryKey}'.`)
          }
        }
      }
    }
    this.props.dispatch(replace({search: queryString.stringify(propsFiltered, {encode: false})}))
  }

  showLightboxForWall = wall =>
    this.props.dispatch(
      actions.showImageOverlay({
        //
        title: wall.artwork.title,
        subtitle: wall.artwork.artistsNames.join(", "),
        url: wall.imageUrlFull,
        extraData: {type: "wall", wallId: wall.id},
      }),
    )

  render = () => {
    const {intl, intlLocale, embeddedMode, ownGalleryUrl, galleryBranchShareInfos, defaultThemeProps, labelHeader, gallerySecretCode, labelSubheader, publicWalls, makeYourOwnGalleryButtonLabel, noWallsYetLabel, noBottomSeparatorMobile, loadMoreButtonLabel, fetching, wallsAmountShown} = this.props
    const {centerContent} = this.props
    const d = {debug: DEBUG || this.props.debug}

    const amountWalls = !!publicWalls ? publicWalls.length : 0
    const hasMoreItems = amountWalls > wallsAmountShown
    const wallsUsed = !publicWalls || !publicWalls.length ? null : USE_LOAD_MORE ? publicWalls.slice(0, Math.min(wallsAmountShown, publicWalls.length)) : publicWalls
    const buttonUrlTo = !!ownGalleryUrl ? ownGalleryUrl : BranchManager.getBranchUrl(gallerySecretCode)

    const rowProps = {
      type: "flex",
      align: "center",
      justify: !centerContent ? "start" : "center",
      width: "100%",
      height: "100%",
      gutter: 0,
    }

    debby("render()", {intlLocale, fetching, wallsAmountShown, hasMoreItems})
    return (
      <ThemeProvider theme={{...defaultThemeProps}}>
        <PaddedContainer {...d} variant={"header"}>
          <Content {...d}>
            <HeaderContainer {...d}>
              <div style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
                <H3Container {...d}>
                  <CH3 text={labelHeader} />
                </H3Container>
                <Subheader>{labelSubheader}</Subheader>
              </div>
              {/* hidden on <= md */}
              <ButtonOwnGalleryContainer {...d}>
                {!embeddedMode && (
                  <CButton
                    //
                    label={makeYourOwnGalleryButtonLabel}
                    urlTo={buttonUrlTo}
                    openInNewWindow
                    variant={`gallery-landing-own-gallery`}
                  />
                )}
                {embeddedMode && !!galleryBranchShareInfos && (
                  <CBranchQrCode
                    //
                    label={intl.formatMessage({id: `GalleryLandingViewAtHome`})}
                    qrImageUrl={galleryBranchShareInfos.qrImageUrl}
                  />
                )}
              </ButtonOwnGalleryContainer>
            </HeaderContainer>

            {/* Loading Walls ("...") */}
            {!wallsUsed && fetching && (
              <WallsMissingContainer {...d}>
                <NoWallsIconContainer>
                  <CIcon id={"NoWalls"} color={"#666"} />
                </NoWallsIconContainer>
                <NoWallsText {...d}>...</NoWallsText>
              </WallsMissingContainer>
            )}

            {/* No walls for gallery */}
            {!wallsUsed && !fetching && (
              <WallsMissingContainer {...d}>
                <NoWallsIconContainer>
                  <CIcon id={"NoWalls"} color={"#666"} />
                </NoWallsIconContainer>
                <NoWallsText {...d}>{noWallsYetLabel}</NoWallsText>
              </WallsMissingContainer>
            )}

            {/* Walls Grid */}
            {!!wallsUsed && (
              <ItemsContainer>
                <Row {...rowProps}>
                  {wallsUsed.map(wall => (
                    <Col key={`wall_${wall.id}`} lg={3} md={4} sm={6} xs={6}>
                      <ItemContainer>
                        <div onClick={() => this.showLightboxForWall(wall)}>
                          <img src={wall.imageUrlSquare} width={"100%"} />
                        </div>
                      </ItemContainer>
                    </Col>
                  ))}
                </Row>
              </ItemsContainer>
            )}

            {hasMoreItems && USE_LOAD_MORE && (
              <div style={{width: "100%", paddingTop: 20}}>
                <CButton
                  //
                  variant={"home-whitepaper"}
                  label={loadMoreButtonLabel}
                  onClick={this.loadMoreItems}
                />
              </div>
            )}

            <ButtonOwnGalleryContainerMobile>
              <CButton
                //
                label={makeYourOwnGalleryButtonLabel}
                urlTo={buttonUrlTo}
                openInNewWindow={true}
                variant={`gallery-landing-own-gallery-mobile`}
              />
              {!noBottomSeparatorMobile && <BottomSeparatorMobile />}
            </ButtonOwnGalleryContainerMobile>
          </Content>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

const PaddedContainer = styled.div`
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  ${props => props.debug && selectors.getDebugOverlayCss(props, "CSectionGalleryPublicWalls.PaddedContainer", "rgba(0,0,255,0.35)")}
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  background-color: white;
`

const Content = styled.div`
  padding-top: 20px;
  padding-bottom: 40px;
  width: 100%;
  ${props => selectors.getDebugOverlayCss(props, "Content", "rgba(0,255,0,0.35)")}
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`

const H3Container = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "H3Container", "rgba(0,0,255,0.35)")}
  ${props => css`
    ${props.theme.media.mddown} {
      text-align: center;
    }
  `}
`

const ButtonOwnGalleryContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; // align to bottom
  ${props => props.debug && selectors.getDebugOverlayCss(props, "ButtonOwnGalleryContainer", "rgba(0,255,255,0.35)")}
  ${props => css`
    ${props.theme.media.mddown} {
      display: none;
    }
  `}
`

const Subheader = styled.div`
  font-size: 18px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  letter-spacing: 0.1em;
  color: #282828;
  opacity: 0.75;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "Subheader", "rgba(0,255,255,0.35)")}
  ${props => css`
    ${props.theme.media.mddown} {
      text-align: center;
    }
  `}
`

const ItemsContainer = styled.div`
  margin-left: -5px;
  margin-right: -5px;
`

const ItemContainer = styled.div`
  padding: 5px;
  cursor: pointer;
`

const WallsMissingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 40px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "WallsMissingContainer", "rgba(0,255,255,0.35)")}
  flex-direction: row;
  ${props => css`
    ${props.theme.media.mddown} {
      flex-direction: column;
    }
  `}
`

const ButtonOwnGalleryContainerMobile = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  ${props => css`
    ${props.theme.media.mddown} {
      display: block;
      padding-top: 30px;
      padding-bottom: 50px;
    }
  `}
`

const BottomSeparatorMobile = styled.div`
  margin-top: 50px;
  width: 100%;
  height: 2px;
  opacity: 0.3;
  background-color: ${props => props.theme.vars.colors.trout};
`

const NoWallsIconContainer = styled.div`
  width: 175px;
`

const NoWallsText = styled.div`
  flex: 1;
  font-size: 18px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  letter-spacing: 0.1em;
  color: #282828;
  opacity: 0.75;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "NoWallsText", "rgba(255,0,255,0.35)")}
  padding: 20px 30px;

  ${props => css`
    ${props.theme.media.mddown} {
      text-align: center;
    }
  `}
`

const getPropsPublicWalls = (state, props) => props.publicWalls
const getWallsAmountShown = createSelector([getPropsPublicWalls, selectors.getQueryProps], (publicWalls, queryProps) => {
  if (!publicWalls || !publicWalls.length) {
    return 0
  }
  const queryAmount = parseInt(god(queryProps, "mw", AMOUNT_WALLS_INIT))
  const amount = Math.min(publicWalls.length, queryAmount)
  return amount
})

const mapStateToProps = (state, props) => ({
  embeddedMode: state.session.embeddedMode,
  queryProps: selectors.getQueryProps(state, props),
  wallsAmountShown: getWallsAmountShown(state, props),
})
export default injectIntl(withRouter(connect(mapStateToProps)(CSectionGalleryPublicWalls)))
