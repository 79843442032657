import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled from "styled-components"

import * as selectors from "../../data/selectors"

import CContent from "./CContent"

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class CContentParts extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    parts: PropTypes.array,
    //
    textColor: PropTypes.string,
    onImageClick: PropTypes.func,
    onLinkClick: PropTypes.func,
    onClick: PropTypes.func,
  }
  static defaultProps = {
    debug: false && __DEV__,
    textColor: "#1e1e1e",
    resourceId: -1,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {parts, textColor, onImageClick, onLinkClick, onClick, debug} = this.props
    const d = {debug: debug || CContentParts.defaultProps.debug}

    if (!parts || !parts.length) {
      return null
    }

    return parts.map((part, i) => {
      const {isHtml, content, tag} = part // eslint-disable-line
      if (isHtml) {
        return (
          <CContent
            //
            {...d}
            key={`content_part_${i}`}
            html={content}
            textColor={textColor}
            onImageClick={onImageClick}
            onLinkClick={onLinkClick}
            onClick={onClick}
          />
        )
      }

      // fallback
      let Element = null
      switch (tag) {
        case "pre":
        case "code":
          Element = ContentPre
          break
        default:
          debugger
      }

      return (
        <Element {...d} key={`content_part_${i}`}>
          {content}
        </Element>
      )
    })
  }
}

export const ContentPre = styled.pre`
  margin-top: 0px;
  margin-bottom: 0px;
  line-break: normal;
  white-space: normal;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `ContentPre`, `rgba(255,0,255,0.5)`)}
  border: 1px solid #CCC;
  background-color: #eee;
  line-height: 1.6;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  font-family: ${props => props.theme.vars.fonts.families.mono};
  font-weight: ${props => props.theme.vars.fonts.weights.regular}; // 400
`

export default CContentParts
