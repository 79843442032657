import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {motion} from "framer-motion"
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import {CONFIG} from "../../config"
import * as selectors from "../../data/selectors"
import CIcon from "../../view/components/CIcon"
import CLink from "../../view/components/CLink"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CButton", ...args)

class CButton extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    themeKey: PropTypes.string,
    defaultThemeProps: PropTypes.object,
    size: PropTypes.oneOf(["regular", "small"]),
    variant: PropTypes.oneOf([``, `home-tutorials`, `home-whitepaper`, `home-demo`, `right-aligned`, `centered`, `referrer-popup`, `portfolio-top`, `testimonial`, `press-appearance`, `press-kit`, `gallery-landing-demo`, `gallery-landing-own-gallery`, `gallery-landing-own-gallery-mobile`, `gallery-landing-sales-inquiry`, "umbrella-why-iazzu"]),
    canBeBusy: PropTypes.bool,
    busy: PropTypes.bool,
    linkTo: PropTypes.string,
    urlTo: PropTypes.string,
    noHoverScale: PropTypes.bool, // only used in CFooter > CFooterNewsletter
    stretched: PropTypes.bool, // stretch across parent container
    openInNewWindow: PropTypes.bool, // for embedded mode
  }
  static defaultProps = {
    debug: false && __DEV__,
    defaultThemeProps: {
      backgroundColor: CONFIG.layout.colors.codGray,
      borderColor: CONFIG.layout.colors.codGray,
      textColor: "white",
    },
    variant: "",
    size: "regular",
    label: "Okay",
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onClick = e => !this.props.disabled && !!this.props.onClick && this.props.onClick(e)

  getThemeOverrides = () => {
    const {themeKey, variant} = this.props
    let themeOverrides = {}
    if (themeKey === "dark") {
      themeOverrides = {...themeOverrides, borderColor: "white", backgroundColor: "rgba(0,0,0,0)"}
    }
    if (themeKey == "transparent-light") {
      themeOverrides = {...themeOverrides, backgroundColor: "rgba(0,0,0,0)", textColor: "white", borderColor: "white"}
    }
    if (variant == "home-tutorials" || variant == "referrer-popup" || variant == "gallery-landing-own-gallery" || variant == "gallery-landing-own-gallery-mobile" || variant == "gallery-landing-demo") {
      themeOverrides = {...themeOverrides, backgroundColor: "white", textColor: CONFIG.layout.colors.codGray, borderColor: CONFIG.layout.colors.codGray}
    }
    if (variant == "testimonial") {
      themeOverrides = {...themeOverrides, backgroundColor: "white", textColor: CONFIG.layout.colors.siccoro, borderColor: CONFIG.layout.colors.siccoro}
    }
    if (variant == "home-demo") {
      themeOverrides = {...themeOverrides, backgroundColor: "rgba(0,0,0,0)", textColor: "white", borderColor: "white"}
    }
    if (variant == "press-appearance") {
      themeOverrides = {...themeOverrides, backgroundColor: "rgba(0,0,0,0)", textColor: CONFIG.layout.colors.siccoro, borderColor: CONFIG.layout.colors.siccoro}
    }
    if (variant == "press-kit") {
      themeOverrides = {...themeOverrides, backgroundColor: "rgba(0,0,0,0)", textColor: CONFIG.layout.colors.codGray, borderColor: CONFIG.layout.colors.codGray}
    }
    return themeOverrides
  }

  renderButton = () => {
    const {debug, label, defaultThemeProps, canBeBusy, variant, disabled, busy, size, noHoverScale, stretched} = this.props
    const d = {debug: debug || CButton.defaultProps.debug}
    const theme = {...defaultThemeProps, ...this.getThemeOverrides()}
    return (
      <MotionButton
        //
        {...d}
        canBeBusy={JSON.stringify(canBeBusy)}
        variant={variant}
        size={size}
        disabled={disabled}
        stretched={stretched}
        busy={busy}
        whileHover={{scale: disabled || busy || noHoverScale ? 1 : 1.05}}
        onClick={this.onClick}
      >
        {canBeBusy && (
          <IconContainer
            //
            {...d}
            visible={busy}
            size={size}
          >
            <CIcon id={"ButtonBusy"} color={theme.textColor} scale={size == "small" ? 0.5 : 1} />
          </IconContainer>
        )}
        <Label {...d} size={size}>
          {label}
        </Label>
        {canBeBusy && <IconContainer size={size} />}
      </MotionButton>
    )
  }

  render = () => {
    const {debug, defaultThemeProps, variant, linkTo, urlTo, stretched, openInNewWindow} = this.props
    const d = {debug: debug || CButton.defaultProps.debug}
    const theme = {...defaultThemeProps, ...this.getThemeOverrides()}
    debby("render()", {label: this.props.label, linkTo, urlTo, stretched})
    return (
      <ThemeProvider theme={theme}>
        <HorizontalAligner {...d} variant={variant} stretched={stretched}>
          {!linkTo && !urlTo && this.renderButton()}
          {!!linkTo && !urlTo && (
            <CLink to={linkTo} stretched={stretched} openInNewWindow={openInNewWindow}>
              {this.renderButton()}
            </CLink>
          )}
          {!linkTo && !!urlTo && (
            <CLink url={urlTo} stretched={stretched} openInNewWindow={openInNewWindow}>
              {this.renderButton()}
            </CLink>
          )}
        </HorizontalAligner>
      </ThemeProvider>
    )
  }
}

const HorizontalAligner = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: visible;
  padding: 3px 0px;

  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.35)")}

  ${props =>
    props.variant == "home-tutorials" &&
    css`
      ${props.theme.media.smdown} {
        justify-content: center;
      }
    `}

  ${props =>
    props.variant == "gallery-landing-sales-inquiry" &&
    css`
      ${props.theme.media.mddown} {
        justify-content: center;
      }
    `}

  ${props =>
    props.variant == "right-aligned" &&
    css`
      justify-content: flex-end;
    `}

  ${props =>
    (props.variant == "home-whitepaper" || props.variant == "home-demo" || props.variant == "portfolio-top" || props.variant == "testimonial" || props.variant == "centered" || props.variant == "press-appearance" || props.variant == "gallery-landing-demo" || props.variant == "gallery-landing-own-gallery-mobile" || props.variant === "umbrella-why-iazzu") &&
    css`
      justify-content: center;
    `}

  ${props =>
    props.variant == "press-kit" &&
    css`
      ${props.theme.media.smdown} {
        justify-content: center;
      }
    `}

  ${props =>
    props.variant == "referrer-popup" &&
    css`
      justify-content: center;
    `}
`

const MotionButton = styled(motion.button)`
  border: 2px solid ${props => props.theme.borderColor};
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.backgroundColor};
  transition: background-color ${props => props.theme.vars.transitions.themeTransitionDuration}s linear, border ${props => props.theme.vars.transitions.themeTransitionDuration}s linear, color ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;

  ${props =>
    props.size == "regular" &&
    css`
      padding: 5px 30px;
      ${props.canBeBusy &&
      css`
        padding: 5px 15px;
      `}
    `}

  ${props =>
    props.size == "small" &&
    css`
      padding: 3px 15px;
      ${props.canBeBusy &&
      css`
        padding: 3px 10px;
      `}
    `}

  outline: rgba(255, 255, 255, 0.5);

  font-family: ${props => props.theme.vars.fonts.families.default};
  font-weight: ${props => props.theme.vars.fonts.weights.regular};
  font-size: 13px;
  text-transform: uppercase;
  font-kerning: none;
  letter-spacing: 0.2em;
  cursor: ${props => (props.disabled ? "no-drop" : props.busy ? "progress" : "pointer !important")};
  opacity: ${props => (props.disabled ? 0.8 : 1)};

  display: flex;
  align-items: stretch;
  justify-content: stretch;

  ${props =>
    props.stretched &&
    css`
      // background-color: ${props => (props.debug ? "blue" : "none")};
      width: 100%;
      flex: 1;
      align-items: center;
      justify-content: center;
    `}

  ${props => selectors.getDebugOverlayCss(props, "CButton.MotionButton")}
`

const IconContainer = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;

  opacity: ${props => (props.visible ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;

  width: ${props => (props.size == "small" ? 20 : 35)}px;
  // background-color: yellow;

  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,255,255,0.4)")}
`

const Label = styled.div`
  ${props =>
    props.size == "regular" &&
    css`
      padding: 10px 0px;
    `}

  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.5)")}
`

export default CButton
