export const getBranchShareLinkKey = ({type, lang, id, slug}) => {
  let key = `${type}_${lang}`
  switch (type) {
    case "wall":
      key += `_${id}`
      break
    case "artist":
    case "artwork":
    case "gallery":
    case "event":
      key += `_${slug}`
      break
    default:
      if (__DEV__) {
        throw new Error(`getBranchShareLinkKey(): Unhandled type '${type}'.`)
      }
      key += `_unknown-type`
      break
  }
  return key
}
