import * as selectors from "../selectors"

var HelperPlatform = {
  getPlatform() {
    return "web"
  },

  getPlatformIsWeb() {
    return this.getPlatform() == "web"
  },

  getDeviceLocale() {
    const localeLang = selectors.getBrowserLang() // already lower-case
    return localeLang
  },

  getDeviceInfosFirebase() {
    return {
      platformName: "web",
      userAgent: !!navigator ? navigator.userAgent : "",
      versionApp: this.getAppVersion(),
    }
  },

  getWindowScrollY() {
    let supportPageOffset = window.pageXOffset !== undefined
    let isCSS1Compat = (document.compatMode || "") === "CSS1Compat"
    let scroll = {
      // x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
      y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop,
    }
    const scrollY = scroll.y
    return scrollY
  },

  setDocumentLang(lang) {
    window.document.documentElement.lang = lang
  },
}

export default HelperPlatform
