import objectPath from "object-path"
import startCase from "lodash/startCase"
import he from "he"

import {CONFIG} from "../../config"

import BugReportManager from "../../data/utils/BugReportManager" // eslint-disable-line

import * as images from "../../images"

const MUTE_DEBBY = false || !__DEV__

// const MUTE_DEBBY = false // TODO: Change this for Release
const getConsoleFunc = (warn = false) => (!warn ? console.log : console.warn)
export const debbify = (prefix, funcName, data = {}, isWarning = false) => !MUTE_DEBBY && process.env.NODE_ENV != "test" && (Object.keys(data).length ? getConsoleFunc(isWarning)(`${prefix}.` + funcName, data) : getConsoleFunc(isWarning)(`${prefix}.` + funcName))

// Device

const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "other"
  }

  if (/android/i.test(userAgent)) {
    return "android"
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios"
  }
  return "other"
}

export const getIsIos = () => getMobileOperatingSystem() == "ios"
export const getIsAndroid = () => getMobileOperatingSystem() == "android"
export const getIsIosOrAndroid = () => getIsIos() || getIsAndroid()

// Locale

export const getDeviceLocale = () => getBrowserLang()

export const getLocaleUsable = locale => CONFIG.intl.langs.indexOf(locale) != -1

export const getLocaleSafe = locale => (getLocaleUsable(locale) ? locale : CONFIG.intl.baseLang)

export const getDeviceLocaleUsable = () => getLocaleSafe(getDeviceLocale())

export const getBrowserLang = () => {
  let localeLang = "en" // default
  if (!!navigator && !!navigator.language) {
    var locale = navigator.language.split("-")
    localeLang = locale[0].toLowerCase()
  }
  return localeLang
}

// String Manipulation

export const getFirstCapital = (string, changeAfter = false) => {
  if (!string || string.length == 0) {
    return ""
  }

  const stringNew = string.substr(0, 1).toUpperCase() + (changeAfter ? string.substr(1).toLowerCase() : string.substr(1))
  return stringNew
}

export const getEmailIsValid = email => {
  // var regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+[^<>()\.,;:\s@\"]{2,})$/; // eslint-disable-line
  // let regex = new RegExp(/([\w\.\-_]+)?\w+@[\w-_]+(\.\w+){1,}/,'igm') // eslint-disable-line
  var regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i // eslint-disable-line
  var valid = regex.test(email)
  return valid
}

export const getCC = input => {
  const inputStart = startCase(input) // facebook-page --> Facebook Page
  const inputStartWithLink = `${inputStart}` // --> Link Facebook Page
  const result = inputStartWithLink.split(" ").join("")
  return result
}

export const getArrayUnique = arr => {
  if (!arr || !arr.length) {
    return []
  }
  const onlyUnique = (value, index, self) => self.indexOf(value) === index
  const unique = arr.filter(onlyUnique)
  return unique
}

// Object Manipulation

export const getObjectDeep = (obj, pathRaw, fallback = undefined, fallbackCompare = undefined) => {
  let value = fallback
  try {
    if (!!pathRaw && Array.isArray(pathRaw)) {
      // if (pathRaw.indexOf("stephan.mueller@gmx.at") != -1) { debugger } // prettier-ignore
      value = objectPath.get(obj, pathRaw)
      if (value == fallbackCompare) {
        return fallback
      }
      return value
    }

    let path = pathRaw.split("/").join(".")
    value = objectPath.get(obj, path)
    if (value == fallbackCompare) {
      return fallback
    }
  } catch (error) {
    debugger
  }
  return value
}

export const setObjectDeep = (obj, pathRaw, value, levelSeparator = "/") => {
  let path = pathRaw
  path = pathRaw.split(levelSeparator).join(".") //.split (".")

  var objNew = Object.assign({}, obj)
  if (value != null) {
    objectPath.set(objNew, path, value)
  } else {
    objectPath.del(objNew, path)
  }
  return objNew
}

// Routing

export const getAllUrlParams = url => {
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1)

  var obj = {}

  if (queryString) {
    queryString = queryString.split("#")[0]

    var arr = queryString.split("&")

    for (var i = 0; i < arr.length; i++) {
      var a = arr[i].split("=")

      var paramName = a[0]
      var paramValue = typeof a[1] === "undefined" ? true : a[1]

      paramName = paramName.toLowerCase()
      if (typeof paramValue === "string") {
        paramValue = paramValue.toLowerCase()
      }

      // if (paramName.match(/\[(\d+)?\]$/)) {
      //   var key = paramName.replace(/\[(\d+)?\]/, "")
      //   if (!obj[key]) obj[key] = []

      //   if (paramName.match(/\[\d+\]$/)) {
      //     var index = /\[(\d+)\]/.exec(paramName)[1]
      //     obj[key][index] = paramValue
      //   } else {
      //     obj[key].push(paramValue)
      //   }
      // }
      // else {
      if (!obj[paramName]) {
        obj[paramName] = paramValue
      }
      // else if (obj[paramName] && typeof obj[paramName] === "string") {
      //   obj[paramName] = [obj[paramName]]
      //   obj[paramName].push(paramValue)
      // }
      // else {
      //   obj[paramName].push(paramValue)
      // }
      // }
    }
  }

  return obj
}

export const getSitemapDataAndLangForPathname = (sitemap, pathname, params = {}) => {
  const debug = getObjectDeep(params, "debug", false) // eslint-disable-line
  if (debug) debugger
  if (!sitemap || !pathname) {
    return null
  }
  const pathnameSplit = pathname.split("/").filter(p => !!p)

  let langFound = null
  let entryFull = null
  let entryExact = null

  // if (debug) { debugger } // prettier-ignore

  // first, check for EXACT matches in entries without pageTemplate (e.g. /docs-de/steff-first-test-knowledge-artikel-deutsche-version)
  // debugger
  // for (const entry of sitemap) {
  //   if (!!entry.langs && !entry.hasOwnProperty("pageTemplate")) {
  //     // eslint-disable-next-line
  //     for (const lang of Object.keys(entry.langs)) {
  //       const pathnameLangSplit = entry.langs[lang].path.split("/").filter(p => !!p)
  //       console.log(`comparing ${pathnameSplit} with ${pathnameLangSplit}..`)
  //       if (pathnameSplit.join("/") == pathnameLangSplit.join("/")) {
  //         debugger // check 1:1 path matches
  //       }
  //     }
  //   }
  // }
  // debugger
  // then, solve direct hits or

  for (const entry of sitemap) {
    // eslint-disable-next-line
    if (false && !entry.hasOwnProperty("pageTemplate") && debug) {
      debugger
    }

    if (!!entry && !!entry.langs && !langFound) {
      const {langs} = entry

      for (const lang of Object.keys(langs)) {
        if (!langFound) {
          const {path} = langs[lang]

          // if (debug) { debugger } // prettier-ignore

          const pathClean = !!path ? path.split("/").filter((p) => !!p).join("/") : "" //  prettier-ignore

          if (pathClean == pathnameSplit.join("/")) {
            // 1:1 match
            langFound = lang
            entryFull = entry
            entryExact = entryFull.langs[langFound]
          } else {
            // Also handle parameterized routes, e.g. /artworks/123
            const pathCleanSplit = pathClean.split("/").filter(p => !!p)

            if (pathCleanSplit.length == pathnameSplit.length) {
              const ppMatching = pathnameSplit.filter((pathnamePart, i) => {
                const pathCleanPart = pathCleanSplit[i]
                // pPart
                const isParam = pathCleanSplit[i].charAt(0) == ":"
                // if (debug && path.indexOf ("/g/:gallerySlug/p") != -1) { debugger } // prettier-ignore
                if (isParam) {
                  return true
                } else {
                  return pathCleanPart.toLowerCase() == pathnamePart.toLowerCase()
                }
              })

              if (ppMatching.length == pathnameSplit.length) {
                // debugger
                langFound = lang
                entryFull = {
                  ...entry,
                  langs: {
                    [langFound]: {
                      path: pathname,
                    },
                  },
                }
                // entryFull = entry
                entryExact = entryFull.langs[langFound]
                // TODO: Construct link..
                // debugger
              }
            }
          }
        }
      }
    }
  }

  if (debug) { debugger } // prettier-ignore

  return {lang: langFound, entryFull, ...entryExact} // {lang, entryFull {pageTemplate, langs}, langs, pageTemplate}
}

export const getPathnameLocalized = (pathname, locale, sitemapData, debug = false) => {
  const entry = getSitemapDataAndLangForPathname(sitemapData, pathname, {debug})
  const pathnameLocalized = getObjectDeep(entry, `entryFull.langs.${locale}.path`, pathname)
  return pathnameLocalized
}

export const getUrlStripped = url => {
  var oldURL = url
  var index = 0
  var newURL = oldURL
  index = oldURL.indexOf("?")
  if (index == -1) {
    index = oldURL.indexOf("#")
  }
  if (index != -1) {
    newURL = oldURL.substring(0, index)
  }
  return newURL
}

export const getHrefRecursively = target => {
  if (target.nodeName == "A") {
    return target.href
  } else if (!!target.parentNode) {
    return getHrefRecursively(target.parentNode)
  }
  return null
}
export const getHrefByClickEvent = event => (!event ? null : getHrefRecursively(event.target))

export const getHrefProps = (hrefDirty, params = null) => {
  const sitemapData = !!params && params.hasOwnProperty("sitemapData") ? params.sitemapData : null
  const locale = !!params && params.hasOwnProperty("locale") ? params.locale : null
  const debug = getObjectDeep(params, "debug", false)

  let props = {
    href: hrefDirty,
    isValid: !!hrefDirty && !!hrefDirty.toLowerCase,
    isRelative: undefined,
    isRemote: undefined,
    isLocal: undefined,
    // hrefRelative: undefined,
    linkTo: undefined,
    linkToWithBasePath: undefined,
    debug: {
      hrefWithoutPrefix: undefined,
    },
    sitemapEntry: undefined,
  }

  //  find out if it is relative
  const remoteBasePath = CONFIG.server.root + "/" // e.g. https://iazzu.com/
  const currentURL = window.location.href // returns the absolute URL of a page
  const protocol = !!currentURL ? currentURL.split("://")[0] : "https"
  const localBasePath = `${protocol}://localhost`

  if (debug) { debugger } // prettier-ignore

  if (props.isValid) {
    let href = getUrlStripped(hrefDirty)
    props.isRemote = href.toLowerCase().indexOf(remoteBasePath.toLowerCase()) == 0
    props.isLocal = href.toLowerCase().indexOf(localBasePath.toLowerCase()) == 0
    props.isRelative = !props.isRemote && !props.isLocal && href[0] == "/"

    if (props.isRemote || props.isLocal || props.isRelative) {
      //  first, check if it already contains a server prefix
      if (props.isRelative) {
        props.linkTo = href
      }
      if (props.isRemote) {
        //  strip beginning
        let hrefWithoutPrefix = href.substr(remoteBasePath.length - 1)
        props.linkTo = hrefWithoutPrefix
      }
      if (props.isLocal) {
        //  eslint-disable-next-line
        props.linkTo = href.replace(/^.*\/\/[^\/]+/, "") // make URL relative to server root
      }

      let pathParts = props.linkTo.split("/").filter(p => !!p).map(p => p.trim()) // prettier-ignore
      if (!!pathParts.length && pathParts[0] == CONFIG.routing.basename) {
        pathParts.splice(0, 1)
      }
      const linkToPathParts = "/" + pathParts.join("/")
      props.linkTo = linkToPathParts

      if (debug) { debugger } // prettier-ignore

      if (!!sitemapData && !!props.linkTo) {
        props.sitemapEntry = getSitemapDataAndLangForPathname(sitemapData, props.linkTo, {debug})

        if (!!locale && !!props.sitemapEntry && props.sitemapEntry.lang != locale) {
          const localePath = getPathnameLocalized(props.linkTo, locale, sitemapData)
          props.sitemapEntry = getSitemapDataAndLangForPathname(sitemapData, localePath, {debug})
        }
        props.linkTo = props.sitemapEntry.path
      }

      if (!!props.linkTo) {
        props.linkToWithBasePath = (CONFIG.routing.basename ? `/${CONFIG.routing.basename}` : ``) + props.linkTo
      }
    }
  }

  return props
}

export const getSrcAndSrcSetByWordpressImage = (wpImage, options = {}) => {
  const useCropped = getObjectDeep(options, "useCropped", false)
  const minHeight = getObjectDeep(options, "minHeight", null)

  let src = null
  let srcSet = null
  if (!!wpImage) {
    const {sizes} = wpImage
    if (!sizes || !Object.keys(sizes).length) {
      console.warn(`getSrcAndSrcSetByWordpressImage(): Missing 'sizes' prop.`)
      return {src, srcSet}
    }
    let srcSetEntries = []
    Object.keys(sizes).map(sizeKey => {
      const {url, width, height} = sizes[sizeKey] // eslint-disable-line
      const add = !minHeight || height >= minHeight
      if (add) {
        if (!useCropped) {
          if (!!sizeKey.match(/[0-9]+$/gm)) {
            add && srcSetEntries.push({url, srcSetValue: `${url} ${width}w`})
          }
        } else {
          if (!!sizeKey.match(/[0-9]+c$/gm)) {
            if (width != height) {
              // BugReportManager.sendMessageOncePerSession(`Image is not optimized for useCropped: ${url}`)
            } else {
              srcSetEntries.push({url, srcSetValue: `${url} ${width}w`})
            }
          }
        }
      }
    })
    if (!!srcSetEntries.length) {
      src = srcSetEntries[0].url // first entry
      srcSet = srcSetEntries.map(entry => entry.srcSetValue).join(",")
    } else if (!!minHeight) {
      console.warn(`getSrcAndSrcSetByWordpressImage(): No image found for minHeight. Returning basic set.`)
      return getSrcAndSrcSetByWordpressImage(wpImage, {...options, minHeight: null})
    }
  }
  return {src, srcSet}
}

export const getWordPressEditLinkForResourceId = resourceId => (!resourceId ? null : `https://iazzu.com/wp/wp-admin/post.php?post=${resourceId}&action=edit`)

export const getImageWithLimitedSrcSet = (image, resolution = 1) => {
  if (!image || !image.srcSet) {
    return image
  }
  let maxWidth = window.innerWidth * resolution
  let srcSetOrig = image.srcSet
  const srcSetParts = srcSetOrig.split(",")
  let srcSetNewParts = []
  for (const part of srcSetParts) {
    const url = part.split(" ")[0]
    const w = parseInt(part.split(" ")[1].replace(/\D/g, ""))
    if (w < maxWidth) {
      srcSetNewParts.push(`${url} ${w}w`)
    }
  }
  return {...image, srcSet: srcSetNewParts.join(",")}
}

export const getPersonDisplayName = person => {
  if (!person) {
    return null
  }
  const {firstName, middleName, lastName} = person
  let parts = []
  !!firstName && parts.push(firstName)
  !!middleName && parts.push(middleName)
  !!lastName && parts.push(lastName)
  return parts.join(" ")
}

export const getFirstAvailablePersonLink = (links, linkTypesAvailable) => {
  if (!links || !links.length) {
    return null
  }
  if (!linkTypesAvailable || !linkTypesAvailable.length) {
    return links[0]
  }
  let linksAvailable = []
  linkTypesAvailable.map(linkType => {
    const linkFound = links.find(l => l.type == linkType)
    if (!!linkFound) {
      linksAvailable.push(linkFound)
    }
  })
  if (!!linksAvailable.length) {
    return linksAvailable[0]
  }
  return null
}

export const getFrameNumberAsString = num => {
  if (num < 10) {
    return "00" + num
  } else if (num < 100) {
    return "0" + num
  } else if (num < 1000) {
    return num
  } else {
    console.error("Only frame numbers between 0 and 1000 are allowed.")
  }
}

export const getFadedImageSourcesByProps = ({prefix, frameNumbersEn, intlLocale}) => {
  let sources = []

  const langs = ["en", "de", "es", "fr"]
  const langAdd = 25 * langs.indexOf(intlLocale)

  sources.push(images[`Mockup${prefix}Blank`]) // "blank" is always the first one

  for (const frameNumberEn of frameNumbersEn) {
    const frameNumberIntlLocaleString = getFrameNumberAsString(frameNumberEn + langAdd) // 1 (en), 26 (de), ..
    const imageKey = `${prefix}_Render_${frameNumberIntlLocaleString}` // "iPhoneiPad_Render_001"
    sources.push(images[imageKey])
  }
  return sources
}

export const getTimestampFormattedString = ({intl, timestamp, formatIntlId}) => {
  const date = new Date(timestamp)
  const dateDay = date.getDay()
  // const dateMonth = date.getMonth ()
  const dateMonth1 = date.getMonth() + 1

  const stringFromNum = (num, digits = 2) => {
    var ret = "" + num
    while (ret.length < digits) {
      ret = "0" + ret
    }
    return ret
  }

  const values = {
    // equal to http://php.net/manual/en/function.date.php

    //  example date: April 1, 1985, 7:05:02

    ["w"]: dateDay, // weekday, 0-6
    ["N"]: dateDay + 1, // weekday, 1-7
    ["l"]: intl.formatMessage({id: `DateTimeWeekday${dateDay}`}), // Monday, etc.

    ["d"]: stringFromNum(date.getDate()), // 01
    //  TODO: "D" - Mon through Sun
    ["j"]: date.getDate(), // 1

    ["m"]: stringFromNum(dateMonth1), // 04
    ["n"]: dateMonth1, // 4
    ["F"]: intl.formatMessage({id: `DateTimeMonth${dateMonth1}`}), // January, February

    ["Y"]: date.getFullYear(), // 1985

    ["G"]: date.getHours(), // 7
    ["H"]: stringFromNum(date.getHours()), // 07

    ["i"]: stringFromNum(date.getMinutes()), // 05
    ["s"]: stringFromNum(date.getSeconds()), // 02
  }

  const timestampFormattedString = intl.formatMessage({id: formatIntlId}, values)
  return timestampFormattedString
}

export const getUrlReadable = url => {
  if (!url) {
    return ""
  }
  let urlReadable = `${url}`
  urlReadable = urlReadable.split("https://").join("")
  urlReadable = urlReadable.split("http://").join("")
  return urlReadable
}

export const getArrayShuffledWithSeed = (array, seed) => {
  if (!array) {
    return null
  }
  let currentIndex = array.length,
    temporaryValue,
    randomIndex
  seed = seed || 1
  let random = function () {
    var x = Math.sin(seed++) * 10000
    return x - Math.floor(x)
  }
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(random() * currentIndex)
    currentIndex -= 1
    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}

export const getHtmlSafe = (html, resourceId = -1) => {
  if (!html) {
    return html
  }
  //  input:  https://iazzu.com/contact
  //  output: https://iazzu.com/v3/contact <- with basePath

  let htmlClean = html
  const {basename} = CONFIG.routing

  let amountsLooped = 0

  const occurencesAmount = htmlClean.split("https://iazzu.com/").length - 1
  if (!!occurencesAmount) {
    const regexHref = /href=["|']{1}https:\/\/iazzu.com\/(?!(wp\/))([A-Za-z]*)["|']{1}/gm // https://regex101.com/r/FJONgt/1
    let matches = [...htmlClean.matchAll(regexHref)]
    do {
      if (!!matches.length) {
        const firstMatch = matches[0] // eslint-disable-line
        const linkOriginal = firstMatch[0] // href="https://iazzu.com/contact"
        const pathOriginal = firstMatch[2] // contact
        const index = firstMatch.index

        const basenameAdd = !!basename ? `${basename}/` : ``
        const htmlReplaced = htmlClean.substr(0, index) + `href="https://iazzu.com/${basenameAdd}${pathOriginal}"` + htmlClean.substr(index + linkOriginal.length)
        htmlClean = htmlReplaced
      }
      matches = [...htmlClean.matchAll(regexHref)] // eslint-disable-line
      amountsLooped++
    } while (!!matches.length && amountsLooped < 100)
  }

  if (amountsLooped >= 100) {
    BugReportManager.sendMessageOncePerSession(`Infinite loop detected (ID: ${resourceId}).`)
  }

  return htmlClean
}

export const getArrayOrNull = array => (!!array && !!array.length ? array : null)

export const getTextLong = (text, mode = "featureMatrixFirstCol") => {
  const textLength = text.length
  const words = text.split(" ").filter(w => !!w.trim())
  const isSingleWord = words.length == 1
  switch (mode) {
    case "featureMatrixFirstCol": {
      const hasSingleWordLong = !!words.filter(w => w.length > 10).length
      return (isSingleWord && textLength > 10) || (!isSingleWord && textLength > 25) || (!isSingleWord && hasSingleWordLong)
    }
  }
  return false
}

export const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

export const getTimestampNowProps = (key, forReal = false) =>
  !forReal
    ? {}
    : {
        //
        [`${key}`]: new Date().getTime(),
        [`${key}R`]: new Date().toLocaleString(),
      }

export const getTextFromHtml = (html, options = null) => {
  const spaceForListItems = getObjectDeep(options, "spaceForListItems", false)
  let text = html
  if (spaceForListItems && text.indexOf("</li>") != -1) {
    text = text.split("</li>").join(". ")
  }
  text = text.replace(/<(?:.|\n)*?>/gm, " ")
  while (text.indexOf("  ") != -1) {
    text = text.replace("  ", " ")
  }
  while (text.indexOf("..") != -1) {
    text = text.replace("..", ".")
  }
  text = he.decode(text)
  return text
}

export const getExcerptWordsFromText = (text, wordsMax = 15) => {
  const words = text.split(" ").filter(w => !!w && !!w.trim())
  const wordsExcerpt = words.slice(0, Math.min(words.length, wordsMax))
  return wordsExcerpt.join(" ")
}

export const getIntlIdForArtworkButton = (status, price, hasPurchaseLink) => {
  switch (status) {
    case "tempunavailable":
    case "unavailable":
    case "sold":
    case "gifted":
    case "privateownership":
      return "WebArtworkDetailsButtonMoreInfosLabel"
  }

  if (hasPurchaseLink) {
    return "WebArtworkDetailsButtonPurchaseLinkLabel"
  }

  return "GalleryLandingArtworkSectionGeneralSalesInquiryButtonLabel"
}

export const getSubjectSalesInquiryEmail = (status /*, price, hasPurchaseLink*/) => {
  switch (status) {
    case "tempunavailable":
    case "unavailable":
    case "sold":
    case "gifted":
    case "privateownership":
      return "More Infos"
  }

  return "Sales Inquiry"
}

// same as in app/src/data/selectors/helpers.js
export const getIntlIdForArtworkPriceProp = ({status, price}) => {
  let intlId = null // will stay "null" in case the price value should be shown
  switch (status) {
    case "available":
    case "reserved":
    case "tempunavailable":
      if (!price) {
        intlId = "PriceOnRequestLabel"
      }
      break
    case "unavailable":
      // if (!price) {
      intlId = "PriceNotAvailableLabel" // never show price, according to https://app.asana.com/0/153328560206677/1201064250848462/f
      // }
      break
    case "sold":
    case "gifted":
    case "privateownership":
      intlId = "PriceNotAvailableLabel"
      break
    case "forrent":
      intlId = "PriceOnRequestLabel"
      break
    case "forsaleorrent":
      if (!price) {
        intlId = "PriceOnRequestLabel"
      }
      break
    default:
      console.warn(`Unknown status '${status}'`)
      break
  }
  return intlId
}

export const getContentParts = (contentOriginal, tagsNoHtml = ["pre", "code"]) => {
  if (!contentOriginal || !contentOriginal.trim()) {
    return null
  }

  let parts = [{isHtml: true, content: contentOriginal}]

  for (const tagNoHtml of tagsNoHtml) {
    let partsNew = []

    for (const part of parts) {
      const {isHtml, content} = part
      if (!isHtml) {
        partsNew.push(part) // already parsed
      } else {
        // eslint-disable-next-line
        // const regexp = new RegExp(`<${tagNoHtml}[^>]*>(.+?)<\/${tagNoHtml}\s*>`, "gm") // /<pre[^>]*>(.+?)<\/pre\s*>/gm

        const regexp = new RegExp(`<${tagNoHtml}[^>]*>(.+?)</${tagNoHtml}s*>`, "gm") // /<pre[^>]*>(.+?)<\/pre\s*>/gm
        const matchExec = regexp.exec(content)
        if (!matchExec) {
          partsNew.push(part)
        } else {
          let contentRest = content
          let counter = 0 // avoid endless loops
          while (!!contentRest.length && counter < 1000) {
            regexp.lastIndex = 0
            const matchRestExec = regexp.exec(contentRest)
            if (!matchRestExec) {
              partsNew.push({isHtml: true, content: contentRest})
              contentRest = "" // we done here
            } else {
              const matchWithTag = matchRestExec[0]
              const matchWithoutTag = matchRestExec[1]
              const matchIndex = matchRestExec.index
              const textBefore = contentRest.substr(0, matchIndex)
              const textAfter = contentRest.substr(matchIndex + matchWithTag.length)

              if (!!textBefore) {
                partsNew.push({isHtml: true, content: textBefore})
              }
              partsNew.push({isHtml: false, tag: tagNoHtml, content: matchWithoutTag})
              contentRest = !!textAfter ? textAfter.trim() : ""
            }
            counter++
          }
          if (counter >= 1000) {
            debugger // this shouldn't happen
          }
        }
      }
    }
    parts = [...partsNew]
  }
  return !!parts && !!parts.length ? parts : null
}
