import React, {Component} from "react"
import PropTypes, {instanceOf} from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {withController} from "react-scroll-parallax"
import styled, {ThemeProvider, css} from "styled-components"
import {withCookies, Cookies} from "react-cookie"
import moment from "moment"
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton} from "react-share" // eslint-disable-line

import * as selectors from "../../../data/selectors"
import * as actions from "../../../data/actions"
import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, pageTemplate, ..}

import CEditInWordPress from "../../../view/components/CEditInWordPress"
import CFooter from "../../../view/components/CFooter"
import CSpacer from "../../../view/components/CSpacer"
import CLink from "../../../view/components/CLink"
import CIcon from "../../../view/components/CIcon"
import CContentParts from "../../../view/components/CContentParts"

import CH2 from "../../../view/components/text/CH2"

import CArticlesOnlyEnglish from "../SKnowledgeBase/CArticlesOnlyEnglish"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SKnowledgeBaseArticle", ...args)
const bgd = (c, cregular = "none") => ({backgroundColor: DEBUG ? c : cregular}) // eslint-disable-line

import {CONFIG} from "../../../config" // eslint-disable-line

const god = selectors.getObjectDeep // eslint-disable-line

const COOKIE_SINGLE_ARTICLE_ENGLISH_ONLY_ACCEPTED_NAME = "iazzuAcceptedKbSingleArticleEnglishOnly"

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class SKnowledgeBaseArticle extends Component {
  static propTypes = {
    parallaxController: PropTypes.object.isRequired,

    stageWidth: PropTypes.number,
    fixedSectionsItems: PropTypes.array,
    defaultThemeProps: PropTypes.object,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    wordPressUserId: PropTypes.number,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),

    // withCookies
    cookies: instanceOf(Cookies).isRequired,
    singleArticleEnglishOnlyAccepted: PropTypes.bool,

    articles: PropTypes.array,
  }
  static defaultProps = {
    defaultThemeProps: {
      backgroundColor: "white",
      textColor: "black",
    },
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentDidMount = async () => {
    this.props.dispatch(actions.requestKnowledgeBaseArticles())
    const d = {debug: DEBUG}
    if (d.debug) {
      setTimeout(() => this.props.cookies.set(COOKIE_SINGLE_ARTICLE_ENGLISH_ONLY_ACCEPTED_NAME, JSON.stringify(false), {path: "/"}), 5000)
    }
  }

  onCloseSingleArticleOnlyEnglishClick = () => this.props.cookies.set(COOKIE_SINGLE_ARTICLE_ENGLISH_ONLY_ACCEPTED_NAME, JSON.stringify(true), {path: "/"})

  getPrevNextArticles = () => {
    const {postData, articles} = this.props
    let prevArticle = null
    let nextArticle = null
    if (!!articles && !!articles.length) {
      const prevArticleSlug = god(postData, "sectionPrevNext.prev.slug") // could be in any lang
      if (!!prevArticleSlug) {
        const articleBySlug = articles.find(a => a.slugsAllLangs.indexOf(prevArticleSlug) != -1)
        if (!!articleBySlug) {
          prevArticle = {title: articleBySlug.title, pathLocalized: articleBySlug.pathLocalized}
        }
      }
      const nextArticleSlug = god(postData, "sectionPrevNext.next.slug") // could be in any lang
      if (!!nextArticleSlug) {
        const articleBySlug = articles.find(a => a.slugsAllLangs.indexOf(nextArticleSlug) != -1)
        if (!!articleBySlug) {
          nextArticle = {title: articleBySlug.title, pathLocalized: articleBySlug.pathLocalized}
        }
      }
    }
    return {prevArticle, nextArticle}
  }

  onContentClick = event => {
    const {nodeName, parentNode} = event.target
    if (nodeName == "IMG") {
      event.preventDefault()
      let title = null
      if (parentNode.nodeName == "FIGURE") {
        const childNodes = parentNode.childNodes
        for (let i = 0; i < childNodes.length; i++) {
          const child = childNodes[i] // eslint-disable-line
          if (child.nodeName == "FIGCAPTION") {
            title = child.innerText
          }
        }
      }
      const subtitle = !!title ? god(this.props.postData, "title") : null
      const url = event.target.getAttribute("src") // full width
      this.props.dispatch(actions.showImageOverlay({title, subtitle, url}))
    }
  }

  getShareProps = () => {
    const {postData} = this.props
    const shareUrl = god(postData, "url")
    const shareTitle = god(postData, "title")
    const shareDescription = "" // TODO?
    return {shareUrl, shareTitle, shareDescription}
  }

  render = () => {
    const {defaultThemeProps, intl, intlLocale, postData, wordPressUserId, editInWordPressProps, singleArticleEnglishOnlyAccepted, articles} = this.props
    const d = {debug: DEBUG}
    const themeOverrides = {}
    const dateReadable = moment(god(postData, "dateTimestamp", 0) * 1000).locale(intlLocale).format(intl.formatMessage({id: `KnowledgeBaseArticleDateFormat`})) // prettier-ignore
    const showOnlyInEnglishNote = god(postData, "seoData.lang", "en") != intlLocale // article lang != intlLocale

    const {prevArticle, nextArticle} = this.getPrevNextArticles()
    const shareProps = this.getShareProps()
    const {shareUrl, shareTitle, shareDescription} = shareProps
    const contentFilteredParts = selectors.getContentParts(god(postData, "contentFiltered")) // eslint-disable-line

    debby("render()", {wordPressUserId, amountArticles: !!articles ? articles.length : 0, postData, shareProps, prevArticle, nextArticle, contentFilteredParts})

    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <CSpacer {...d} variant={`headermenu`} />
        <Container {...d}>
          <PaddedContainer {...d} variant={`header`} hasMaxWidth>
            <HeaderContainer {...d}>
              <CH2 text={god(postData, "title")} variant={"knowledgebase-article"} />
            </HeaderContainer>

            {showOnlyInEnglishNote && !singleArticleEnglishOnlyAccepted && <CArticlesOnlyEnglish onCloseClick={this.onCloseSingleArticleOnlyEnglishClick} />}
            <Date {...d}>{dateReadable}</Date>

            <ShareContainer {...d}>
              <ShareCaption {...d}>{intl.formatMessage({id: `KnowledgeBaseArticleShareLabel`})}</ShareCaption>
              <ShareIcon {...d}>
                <FacebookShareButton url={shareUrl}>
                  <CIcon id={"FooterFacebook"} color={CONFIG.layout.colors.trout} />
                </FacebookShareButton>
              </ShareIcon>
              <ShareIcon {...d}>
                <TwitterShareButton url={shareUrl} title={shareTitle} via={"iazzu"} tags={["iazzu", "gallery"]} related={["iazzu"]}>
                  <CIcon id={"FooterTwitter"} color={CONFIG.layout.colors.trout} />
                </TwitterShareButton>
              </ShareIcon>
              <ShareIcon {...d} yOffset={-1}>
                <LinkedinShareButton url={shareUrl} title={shareTitle}>
                  <CIcon id={"FooterLinkedin"} color={CONFIG.layout.colors.trout} />
                </LinkedinShareButton>
              </ShareIcon>
              <ShareIcon {...d} yOffset={2}>
                <EmailShareButton url={shareUrl} subject={shareTitle} body={encodeURI(shareDescription)}>
                  <CIcon id={"FooterEmail"} color={CONFIG.layout.colors.trout} />
                </EmailShareButton>
              </ShareIcon>
            </ShareContainer>

            {!!contentFilteredParts && (
              <CContentParts
                //
                parts={contentFilteredParts}
                onClick={this.onContentClick}
              />
            )}
          </PaddedContainer>

          <PaddedContainer {...d} variant={"header"}>
            <PrevNextContainer {...d}>
              <PrevNextItem {...d} left>
                {!!prevArticle && (
                  <CLink to={prevArticle.pathLocalized} color={CONFIG.layout.colors.codGray}>
                    <>
                      <PrevNextItemHeader {...d} left>
                        <PrevNextItemLine left />
                        <PrevNextItemHeaderLabel>{intl.formatMessage({id: `KnowledgeBaseArticlePrevLabel`})}</PrevNextItemHeaderLabel>
                      </PrevNextItemHeader>
                      <PrevNextItemLink {...d} left>
                        {prevArticle.title}
                      </PrevNextItemLink>
                    </>
                  </CLink>
                )}
              </PrevNextItem>
              <PrevNextItem {...d} right>
                {!!nextArticle && (
                  <CLink to={nextArticle.pathLocalized} color={CONFIG.layout.colors.codGray}>
                    <>
                      <PrevNextItemHeader {...d} right>
                        <PrevNextItemLine right />
                        <PrevNextItemHeaderLabel>{intl.formatMessage({id: `KnowledgeBaseArticleNextLabel`})}</PrevNextItemHeaderLabel>
                      </PrevNextItemHeader>
                      <PrevNextItemLink {...d} right>
                        {nextArticle.title}
                      </PrevNextItemLink>
                    </>
                  </CLink>
                )}
              </PrevNextItem>
            </PrevNextContainer>

            <CLink to={"/docs"}>
              <ShowAllArticlesContainer {...d} onClick={this.clearSearchInput}>
                <CIcon id={"AllKbArticlesCaretLeft"} />
                <ShowAllArticlesButtonLabel {...d}>{intl.formatMessage({id: `KnowledgeBaseAllTutorialsButtonLabel`})}</ShowAllArticlesButtonLabel>
              </ShowAllArticlesContainer>
            </CLink>
          </PaddedContainer>

          <CFooter variant={"umbrella"} />

          <CEditInWordPress fixed {...editInWordPressProps} />
          {d.debug && (
            <div style={{position: "fixed", pointerEvents: "none", fontSize: 9, left: 0, bottom: 0, zIndex: 20000, color: "blue", backgroundColor: "rgba(255,255,255,0.5)", padding: 10}}>
              <div>SKnowledgeBaseArticle_web.js</div>
              <div>intlLocale: {intlLocale}</div>
              <div>wordPressUserId: {wordPressUserId}</div>
              <div>
                editInWordPressProps: <pre>{JSON.stringify(editInWordPressProps, null, 2)}</pre>
              </div>
            </div>
          )}
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  text-align: ${props => (props.align == "center" ? "center" : "left")};
  ${props =>
    props.hasMaxWidth &&
    css`
      max-width: 900px;
      margin: 0 auto;
    `}

  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant, "padding")}
  ${props => props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.15)`)}
`

const HeaderContainer = styled.div`
  padding-top: 180px;
  padding-bottom: 30px;
  ${props => css`
    ${props.theme.media.mddown} {
      padding-top: 110px;
    }
  `}

  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(0,255,0,0.35)`)};
`

const Date = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
  font-family: ${props => props.theme.vars.fonts.families.default};
  font-style: italic;
  font-size: 16px;
  color: #778080;
  letter-spacing: 1.6px;
  text-align: left;
  line-height: 27px;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `Date`, `rgba(0,255,0,0.15)`)}
`

const ShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 50px;
`

const ShareCaption = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid red;
  font-size: 14px;
  font-family: ${props => props.theme.vars.fonts.families.default};
  letter-spacing: 3px;
  text-transform: uppercase;
  border-bottom: 1px solid #1e1e1e;
  color: #1e1e1e;
  padding-bottom: 4px;
  padding-top: 4px;
  margin-right: 15px;
`

const ShareIcon = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transform: translate3d(0px, ${props => props.yOffset || 0}px, 0px);
`

const PrevNextContainer = styled.div`
  display: flex;
  flex-direction: row;

  padding-top: 100px;
  padding-bottom: 150px;
  ${props => css`
    ${props.theme.media.mddown} {
      padding-top: 50px;
    }
    ${props.theme.media.smdown} {
      padding-bottom: 30px;
      flex-direction: column;
    }
  `}

  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `PrevNextContainer`, `rgba(255,255,0,0.15)`)}
`

const PrevNextItem = styled.div`
  flex: 1;
  align-items: center;
  justify-content: ${props => (props.left ? "flex-start" : "flex-end")};
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `PrevNextItem`, `rgba(0,255,255,0.15)`)}
`

const PrevNextItemHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.left ? "row" : "row-reverse")};
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  padding-bottom: 15px;
`

const PrevNextItemLine = styled.div`
  width: 100px;
  height: 1px;
  background-color: ${CONFIG.layout.colors.codGray};
  margin-right: ${props => (props.left ? 10 : 0)}px;
  margin-left: ${props => (props.right ? 10 : 0)}px;

  ${props => css`
    ${props.theme.media.smdown} {
      display: none;
    }
  `}
`

const PrevNextItemHeaderLabel = styled.div`
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 20px;
  color: ${CONFIG.layout.colors.codGray};

  ${props => css`
    ${props.theme.media.smdown} {
      text-align: center;
      margin: 0 auto;
      padding-bottom: 5px;
      border-bottom: 1px solid black;
    }
  `}
`

const PrevNextItemLink = styled.div`
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  color: #1e1e1e;
  letter-spacing: 3px;
  line-height: 1.3;
  text-align: ${props => (props.left ? "left" : "right")};
  padding-right: ${props => (props.left ? 10 : 0)}px;
  padding-left: ${props => (props.right ? 10 : 0)}px;
  font-size: 28px;

  ${props => css`
    ${props.theme.media.smdown} {
      text-align: center;
      font-size: 20px;
      margin-bottom: 40px;
    }
  `}
`

const ShowAllArticlesContainer = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 10px 0px;
  // margin-top: 30px;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `ShowAllArticlesContainer`, `rgba(0,0,255,0.15)`)}
  margin-bottom: 30px;

  justify-content: flex-start;
  ${props => css`
    ${props.theme.media.smdown} {
      justify-content: center;
    }
  `}
`

const ShowAllArticlesButtonLabel = styled.div`
  font-size: 20px;
  color: #1e1e1e;
  letter-spacing: 2.5px;
  text-align: left;
  line-height: 27px;
  // font-weight: ${props => props.theme.vars.fonts.weights.bold};
  padding-left: 10px;
`

const getIntl = (state, props) => props.intl // eslint-disable-line

const mapStateToProps = (state, props) => ({
  articles: selectors.getKnowledgeBaseArticles(state, props),
  singleArticleEnglishOnlyAccepted: god(props.cookies, `cookies.${COOKIE_SINGLE_ARTICLE_ENGLISH_ONLY_ACCEPTED_NAME}`, "false") == "true", // message on top
})
export default withCookies(withController(withScreenProps(connect(mapStateToProps)(SKnowledgeBaseArticle))))
