import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {injectIntl} from "react-intl"
import {createSelector} from "reselect"
import {replace} from "connected-react-router"
import queryString from "query-string"
import isEqual from "lodash/isEqual"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line
import Masonry from "react-masonry-component"

import {CONFIG} from "../../../config" // eslint-disable-line
import * as selectors from "../../../data/selectors"
import {getObjectDeep as god} from "../../../data/selectors/helpers" // eslint-disable-line

import CLink from "../../../view/components/CLink"
import CButton from "../../../view/components/CButton"
import CH3 from "../../../view/components/text/CH3"

const DEBUG = true && __DEV__
const debby = (funcName, data = null) => DEBUG && console.log("CSectionGalleryArtworks." + funcName, data) // eslint-disable-line

const AMOUNT_ARTWORKS_INIT = 20
const AMOUNT_ARTWORKS_ADDED_PER_LOAD_MORE_STEP = 10 // eslint-disable-line

class CSectionGalleryArtworks extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    debug: PropTypes.bool,
    embeddedMode: PropTypes.bool,
    defaultThemeProps: PropTypes.object,
    postData: PropTypes.object.isRequired,
    labelHeader: PropTypes.string,
    maxHeightDescription: PropTypes.number,
    gallerySlug: PropTypes.string.isRequired,
    artworkDetailsByLabel: PropTypes.string.isRequired,
    artworks: PropTypes.array,
    loadMoreButtonLabel: PropTypes.string,
    queryProps: PropTypes.object,
    artworksAmountShown: PropTypes.number,
  }
  static defaultProps = {
    debug: false && __DEV__,
    defaultThemeProps: {
      // backgroundColor: "white",
      textColor: "black",
    },
    maxHeightDescription: 200,
  }

  constructor(props) {
    super(props)
    this.state = {
      descriptionCollapsed: true,
      descriptionNeedsCollapsing: true,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onDescriptionContentHeightChanged = height => {
    const descriptionNeedsCollapsing = height > this.props.maxHeightDescription
    debby(`onDescriptionContentHeightChanged()`, {height, descriptionNeedsCollapsing})
    this.setState({descriptionNeedsCollapsing})
  }

  toggleExpandCollapse = () => this.setState({descriptionCollapsed: !this.state.descriptionCollapsed})

  loadMoreItems = () => {
    const {artworksAmountShown} = this.props
    this.updateQueryProps({ma: artworksAmountShown + AMOUNT_ARTWORKS_ADDED_PER_LOAD_MORE_STEP})
  }

  updateQueryProps = (propsShortKeyed = {}) => {
    let propsNext = {...this.props.queryProps, ...propsShortKeyed}
    let propsFiltered = {}
    for (const queryKey of Object.keys(propsNext)) {
      switch (queryKey) {
        case "embed":
          // ignore
          break
        case "mw":
        case "ma": {
          // max amount shown artworks
          propsFiltered[queryKey] = propsNext[queryKey]
          break
        }
        default: {
          if (__DEV__) {
            throw new Error(`updateQueryProps(): Unhandled queryKey '${queryKey}'.`)
          }
        }
      }
    }
    this.props.dispatch(replace({search: queryString.stringify(propsFiltered, {encode: false})}))
  }

  render = () => {
    const {debug, embeddedMode, defaultThemeProps, artworks, artworkDetailsByLabel, gallerySlug, labelHeader, loadMoreButtonLabel, artworksAmountShown} = this.props
    const d = {debug: debug || CSectionGalleryArtworks.defaultProps.debug}

    const masonryClassName = "artworks" // see _masonry.scss

    const amountArtworks = !!artworks ? artworks.length : 0
    const hasMoreItems = amountArtworks > artworksAmountShown

    const artworksUsed = !artworks ? null : artworks.slice(0, Math.min(artworksAmountShown, artworks.length))

    debby("render()", {artworksAmountShown})
    return (
      <ThemeProvider theme={{...defaultThemeProps}}>
        <PaddedContainer {...d} variant={"header"}>
          <Content
            //
            {...d}
          >
            <HeaderContainer {...d}>
              <CH3 text={labelHeader} />
            </HeaderContainer>
            {!!artworksUsed && (
              <MasonryContainerNegativeMargin>
                <Masonry
                  elementType={"div"}
                  ref={obj => (this.masonryRef = obj)}
                  options={{
                    // transitionDuration: 500,
                    transitionDuration: 0,
                    percentPosition: true,
                    columnWidth: `.grid-sizer-${masonryClassName}`,
                    itemSelector: `.grid-item-${masonryClassName}`,
                  }}
                  disableImagesLoaded={false} // default false
                  updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
                >
                  <div className={`grid-sizer-${masonryClassName}`} />
                  {artworksUsed.map((artwork, ai) => (
                    <CArtworkMasonryItem
                      //
                      {...d}
                      key={`masonry-item-${artwork.slug}`}
                      itemIndex={ai}
                      embeddedMode={embeddedMode}
                      masonryClassName={masonryClassName}
                      gallerySlug={gallerySlug}
                      artworkDetailsByLabel={artworkDetailsByLabel} // intl
                      {...artwork}
                    />
                  ))}
                </Masonry>
              </MasonryContainerNegativeMargin>
            )}
            {hasMoreItems && (
              <div style={{backgroundColor: debug ? "red" : "none", width: "100%", paddingTop: 20}}>
                <CButton
                  //
                  variant={"home-whitepaper"}
                  label={loadMoreButtonLabel}
                  onClick={this.loadMoreItems}
                />
              </div>
            )}
          </Content>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

class CArtworkMasonryItem extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    masonryClassName: PropTypes.string.isRequired,
    gallerySlug: PropTypes.string.isRequired,
    embeddedMode: PropTypes.bool,

    // artwork
    itemIndex: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    artistsNames: PropTypes.array.isRequired,
    imageMasonry: PropTypes.object.isRequired,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    // const {gallerySlug, slug, masonryClassName} = this.props
    const {debug, slug, title, artistsNames, gallerySlug, masonryClassName, imageMasonry, embeddedMode} = this.props // eslint-disable-line
    const imageUrl = imageMasonry.url
    const imageWidth = imageMasonry.width
    const imageHeight = imageMasonry.height
    const aspectRatio = imageWidth / imageHeight // 16/9

    // classes defined in styles/_masonry.scss
    const wide = aspectRatio >= 2
    const superwide = aspectRatio > 3 // Manshuron
    // const classNamePostfix = superwide ? "-superwide" : wide ? "-wide" : ""
    let classNamePostfix = superwide ? "-wide" : wide ? "-wide" : "" // 2 cols max.
    // classNamePostfix = "" // 1 col max

    const d = {debug: debug || CArtworkMasonryItem.defaultProps.debug}
    return (
      <div className={`grid-item-${masonryClassName} grid-item-${masonryClassName}${classNamePostfix}`}>
        <CLink
          //
          // debug
          stretched
          search={embeddedMode ? "?embed=true" : ""}
          to={`/g/${gallerySlug}/p/${slug}`}
          preloadPostData={this.props.itemIndex < 20}
          preloadDelayMs={this.props.itemIndex * 1000}
        >
          <MasonryItemContainer {...d}>
            <img src={imageUrl} width={"100%"} style={{maxHeight: 600, minHeight: 150, objectFit: "contain"}} />
            <MasonryItemOverlay>
              <MasonryItemBottom>
                <MasonryItemArtistName>{artistsNames.join(", ")}</MasonryItemArtistName>
                <br />
                <MasonryItemArtworkTitle>{title}</MasonryItemArtworkTitle>
              </MasonryItemBottom>
            </MasonryItemOverlay>
          </MasonryItemContainer>
        </CLink>
      </div>
    )
  }
}

const PaddedContainer = styled.div`
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}

  padding-top: 20px;
  padding-bottom: 20px;

  ${props => props.debug && selectors.getDebugOverlayCss(props, "CSectionGalleryArtworks.PaddedContainer", "rgba(0,0,255,0.35)")}
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;

  // background-color: #ebebeb;
  // background-color: #ebebeb;

  background: #ebebeb;
  background: linear-gradient(180deg, #ebebeb 0%, #ffffff 100%);
`

const Content = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  ${props => selectors.getDebugOverlayCss(props, "Content", "rgba(0,255,0,0.35)")}
`

const HeaderContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 40px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "HeaderContainer", "rgba(0,0,255,0.35)")}
  ${props => css`
    ${props.theme.media.mddown} {
      text-align: center;
    }
  `}
`

const MasonryContainerNegativeMargin = styled.div`
  position: relative;
  display: block;
  margin-left: -5px;
  margin-right: -5px;
`

const MasonryItemContainer = styled.div`
  padding: 5px;
  div {
    opacity: ${props => (props.debug ? 0.5 : 0)};
  }
  :hover {
    div {
      opacity: 1;
    }
  }
`

const MasonryItemOverlay = styled.div`
  position: absolute;
  left: 5px;
  right: 5px;
  top: 5px;
  bottom: 5px;
  color: white;
  // background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
`
const MasonryItemBottom = styled.div`
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 10px;
`
const MasonryItemArtistName = styled.div`
  font-size: 14px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  letter-spacing: 0.15em;
  font-kerning: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  display: inline-block;
  padding: 5px;
`
const MasonryItemArtworkTitle = styled.div`
  font-size: 20px;
  font-weight: ${props => props.theme.vars.fonts.weights.extrabold};
  letter-spacing: 0.15em;
  font-kerning: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  display: inline-block;
  padding: 5px;
  hyphens: auto;
  // text-transform: uppercase;
`

const getPropsArtworks = (state, props) => props.artworks
const getArtworksAmountShown = createSelector([getPropsArtworks, selectors.getQueryProps], (artworks, queryProps) => {
  if (!artworks || !artworks.length) {
    return 0
  }
  const queryAmount = parseInt(god(queryProps, "ma", AMOUNT_ARTWORKS_INIT))
  const amount = Math.min(artworks.length, queryAmount)
  return amount
})

const mapStateToProps = (state, props) => ({
  embeddedMode: state.session.embeddedMode,
  queryProps: selectors.getQueryProps(state, props),
  artworksAmountShown: getArtworksAmountShown(state, props),
})
export default injectIntl(withRouter(connect(mapStateToProps)(CSectionGalleryArtworks)))
