import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import {withRouter} from "react-router-dom"
import styled, {css} from "styled-components"
import {Row, Col} from "react-grid-system"

import {LogoWhite, SwissMadeSofwareLogo} from "../../images"

import * as selectors from "../../data/selectors"
const god = selectors.getObjectDeep

import CAppStoreButton from "../../view/components/CAppStoreButton"
import CIcon from "../../view/components/CIcon"
import CLink from "../../view/components/CLink"
import CFooterEmbeddedMode from "../../view/components/CFooterEmbeddedMode"
// import CFooterUmbrella from "../../view/components/CFooterUmbrella"
import CFooterNewsletter from "../../view/components/CFooterNewsletter"

const DEBUG = false && __DEV__
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CFooter", ...args)

class CFooter extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    variant: PropTypes.oneOf(["default", "umbrella"]),
    dispatch: PropTypes.func.isRequired,
    globalsLocalized: PropTypes.object,
    menuItems: PropTypes.array,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    embeddedMode: PropTypes.bool,
    locationPathname: PropTypes.string.isRequired,
  }
  static defaultProps = {
    debug: false,
    variant: "default",
  }

  componentDidMount = () => {
    debby("componentDidMount()")
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentDidCatch = (error, errorInfo) => {
    __DEV__ && console.error(error, errorInfo)
  }

  render = () => {
    const {variant, intl, globalsLocalized, intlLocale, menuItems, embeddedMode, locationPathname, galleryTitle, smsUrl} = this.props // eslint-disable-line

    if (embeddedMode) {
      return <CFooterEmbeddedMode galleryTitle={galleryTitle} />
    }
    // if (variant == "umbrella") {
    //   return <CFooterUmbrella />
    // }

    const d = {debug: DEBUG || this.props.debug}
    const rowProps = {
      type: "flex",
      align: "start",
      justify: "start",
      width: "100%",
      height: "100%",
      gutter: 30,
    }

    if (!!globalsLocalized) {
      // debugger
    }

    debby("render()", {smsUrl})
    return (
      <Container {...d} paddingVariant={"header"}>
        <Content {...d}>
          <Row {...rowProps}>
            <ColLogoButtons {...d} sm={6} md={4}>
              <LogoImg {...d} src={LogoWhite} />
              <LogoSubheader {...d} dangerouslySetInnerHTML={{__html: globalsLocalized.logoSubheader}} />
              <AppStoreContainerButtons>
                <AppStoreButton>
                  <CAppStoreButton intlLocale={intlLocale} platform={"ios"} />
                </AppStoreButton>
                <AppStoreButton>
                  <CAppStoreButton intlLocale={intlLocale} platform={"android"} />
                </AppStoreButton>
              </AppStoreContainerButtons>

              <SwissMadeSoftwareContainer {...d}>
                <SmsLink href={smsUrl} target="_blank">
                  <SmsImg {...d} src={SwissMadeSofwareLogo} />
                </SmsLink>
              </SwissMadeSoftwareContainer>
            </ColLogoButtons>

            <ColNavigation {...d} sm={6} md={4}>
              <NavigationHeader>{globalsLocalized.navigationHeader}</NavigationHeader>
              {!!menuItems && (
                <Row {...rowProps}>
                  {menuItems.map(item => (
                    <Col key={`nav-${item.key}`} xs={6}>
                      <CLink {...(!!item.linkTo ? {to: item.linkTo} : {})} {...(!!item.hrefTo ? {url: item.hrefTo} : {})} preloadPostData={false}>
                        <NavigationItem active={item.active}>
                          <span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: item.intlId})}} />
                        </NavigationItem>
                      </CLink>
                    </Col>
                  ))}
                </Row>
              )}
            </ColNavigation>

            {/* Disclaimer, Social Icons, Newsletter */}
            <ColDisclaimerIconsNewsletter {...d} md={4}>
              <Disclaimer {...d}>{globalsLocalized.disclaimer || "-"}</Disclaimer>
              <SocialIcons {...d}>
                {!!globalsLocalized.contact.instagram && <CSocialIcon href={globalsLocalized.contact.instagram} iconId={"FooterInstagram"} iconColor={"#778080"} />}
                {!!globalsLocalized.contact.facebook && <CSocialIcon href={globalsLocalized.contact.facebook} iconId={"FooterFacebook"} iconColor={"#778080"} />}
                {!!globalsLocalized.contact.youtube && <CSocialIcon href={globalsLocalized.contact.youtube} iconId={"FooterYoutube"} iconColor={"#778080"} />}
                {!!globalsLocalized.contact.linkedin && <CSocialIcon href={globalsLocalized.contact.linkedin} iconId={"FooterLinkedin"} iconColor={"#778080"} />}
                {!!globalsLocalized.contact.twitter && false && <CSocialIcon href={globalsLocalized.contact.twitter} iconId={"FooterTwitter"} iconColor={"#778080"} />}
                {!!globalsLocalized.contact.email && <CSocialIcon href={`mailto:${globalsLocalized.contact.email}`} iconId={"FooterEmail"} iconColor={"#778080"} />}
              </SocialIcons>

              <CFooterNewsletter />
            </ColDisclaimerIconsNewsletter>
          </Row>
          <Copyright>{god(globalsLocalized, "copyright", "-")}</Copyright>
        </Content>
      </Container>
    )
  }
}

const Container = styled.div`
  position: relative;
  z-index: 4;
  display: flex;
  padding-top: 50px;
  padding-bottom: 30px;
  flex-direction: row;
  background-color: ${props => props.theme.vars.colors.codGray};
  color: white;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,0,0.85)")}
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.paddingVariant)}
`

const Content = styled.div`
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.25)")}
`

const ColLogoButtons = styled(Col)`
  display: flex;
  flex-direction: column;

  ${props =>
    css`
      ${props.theme.media.xs} {
        align-items: center;
      }
    `}
`

const LogoImg = styled.img`
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.5)")}
  width: 140px;
  ${props =>
    css`
      ${props.theme.media.mddown} {
        width: 100px;
      }
    `}
`

const LogoSubheader = styled.div`
  padding-top: 10px;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.vars.colors.siccoro};
`

const AppStoreContainerButtons = styled.div`
  padding-top: 50px;
  display: flex;
`

const SwissMadeSoftwareContainer = styled.div`
  padding-top: 20px;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.25)")}
`

const SmsLink = styled.a``

const SmsImg = styled.img`
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.25)")}
  width: 140px;
  ${props =>
    css`
      ${props.theme.media.mddown} {
        width: 100px;
      }
    `}
`

const AppStoreButton = styled.div`
  margin-right: 15px;
  width: 100%;
  max-width: 140px;

  ${props =>
    css`
      ${props.theme.media.xs} {
        margin-left: 15px;
      }
    `}
`

const ColNavigation = styled(Col)`
  ${props =>
    css`
      ${props.theme.media.xs} {
        margin-top: 30px;
      }
    `}
`

const NavigationHeader = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: 12px;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
  padding: 0px 10px;
  ${props =>
    css`
      ${props.theme.media.xs} {
        padding: 0px;
      }
    `}
`

const NavigationItem = styled.div`
  color: ${props => (props.active ? "white" : props.theme.vars.colors.siccoro)};
  font-size: 14px;
  padding: 10px;
  ${props =>
    css`
      ${props.theme.media.mddown} {
        font-size: 12px;
      }
      ${props.theme.media.xs} {
        padding: 10px 0px;
      }
    `}
`

const ColDisclaimerIconsNewsletter = styled(Col)`
  display: flex;
  flex-direction: column;
  ${props =>
    css`
      ${props.theme.media.smdown} {
        align-items: center;
      }
    `}
`

const Disclaimer = styled.div`
  color: ${props => props.theme.vars.colors.siccoro};
  font-size: 11px;
  line-height: 15px;
  ${props =>
    css`
      ${props.theme.media.smdown} {
        margin-top: 30px;
      }
      ${props.theme.media.sm} {
        text-align: center;
      }
    `}
`

const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.5)")}
  padding: 25px 0px;
  ${props =>
    css`
      ${props.theme.media.mddown} {
        padding: 10px 0px;
      }
    `}
`

const CSocialIcon = ({href, iconId, iconColor}) => (
  <SocialIcon href={href} target={"_blank"}>
    <CIcon scale={0.9} id={iconId} color={iconColor} />
  </SocialIcon>
)
CSocialIcon.propTypes = {href: PropTypes.string, iconId: PropTypes.string, iconColor: PropTypes.string}

const SocialIcon = styled.a`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`

const Copyright = styled.div`
  text-align: center;
  color: ${props => props.theme.vars.colors.siccoro};
  font-size: 11px;
  margin-top: 30px;
`

const getMenuItems = () => {
  // prettier-ignore
  return [
    // {key: "app",               intlId: `FooterApp`,                linkTo: "/"},
    {key: "portfolio-pricing", intlId: `FooterPortfolioPricing`,   linkTo: "/portfolio"},
    {key: "docs",              intlId: `FooterKnowledgeBase`,      linkTo: "/docs"},
    {key: "faq",               intlId: `FooterFAQ`,                linkTo: "/other/faq"},
    {key: "press",             intlId: `FooterPress`,              linkTo: "/press"},
    {key: "terms-conditions",  intlId: `FooterTermsConditions`,    linkTo: "/other/terms-conditions"},
    {key: "privacy-policy",    intlId: `FooterPrivacyPolicy`,      linkTo: "/other/privacy-policy"},
    {key: "smart-assistant",   intlId: `FooterSmartAssistant`,     hrefTo: "https://chat.openai.com/g/g-C0hnIECxd-iazzu-art-assistant"},
    {key: "about",             intlId: `FooterAbout`,              linkTo: "/about-us"},
    {key: "contact",           intlId: `FooterContact`,            linkTo: "/contact"},
  ]
}

const getMenuItemsWithActive = createSelector([selectors.getLocationPathname, selectors.getSitemapData], (locationPathname, sitemapData) => {
  const actPath = selectors.getPathnameLocalized(locationPathname, "en", sitemapData)
  const menuItemsWithActive = getMenuItems().map(item => ({
    ...item,
    active: item.linkTo == actPath,
  }))
  return menuItemsWithActive
})

const getSmsUrl = createSelector([selectors.getIntlLocale], intlLocale => {
  switch (intlLocale) {
    case "de":
      return "https://www.swissmadesoftware.org/about/swiss-made-software.html" // de

    case "fr":
      return "https://www.swissmadesoftware.org/fr/about/swiss-made-software.html" // fr

    default:
      return "https://www.swissmadesoftware.org/en/about/swiss-made-software.html" // en
  }
})

const mapStateToProps = (state, props) => ({
  intlLocale: selectors.getIntlLocale(state),
  globalsLocalized: selectors.getGlobalsLocalized(state),
  menuItems: getMenuItemsWithActive(state, props),
  locationPathname: selectors.getLocationPathname(state, props),
  embeddedMode: state.session.embeddedMode,
  smsUrl: getSmsUrl(state),
})
export default withRouter(injectIntl(connect(mapStateToProps)(CFooter)))
