import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {injectIntl} from "react-intl"
import styled, {css} from "styled-components"
import {createSelector} from "reselect"
import FlipMove from "react-flip-move"
import {replace} from "connected-react-router"
import queryString from "query-string"

import * as selectors from "../../../data/selectors"

import CLink from "../../../view/components/CLink"
import CImage from "../../../view/components/CImage"
import CH3 from "../../../view/components/text/CH3"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CEventsList", ...args)

const OPTIONS = [
  //
  "present",
  "future",
  "past",
]

const getIntlIdByFilter = filter => {
  switch (filter) {
    case "present":
      return "HomeEventsCurrentHeader"

    case "future":
      return "HomeFutureEventsHeader"

    case "past":
      return "HomePastEventsHeader"

    default:
      return "HomeEventsCurrentHeader"
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class CEventsList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    debug: PropTypes.bool,
    eventsPathLocalized: PropTypes.string,
    // Post Data
    headerText: PropTypes.string,
    items: PropTypes.shape({
      present: PropTypes.array,
      future: PropTypes.array,
      past: PropTypes.array,
    }),
    // Query Params
    queryProps: PropTypes.object,
    filter: PropTypes.oneOf(["present", "future", "past"]),
    // Connect
    eventsFinal: PropTypes.array,
    // mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
  }
  static defaultProps = {
    debug: DEBUG,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onFilterOptionClick = key => {
    if (key == this.props.filter) {
      return
    }

    debby("onFilterOptionClick()", {key})
    this.updateQueryProps({f: key})
  }

  updateQueryProps = (propsShortKeyed = {}) => {
    let propsNext = {...this.props.queryProps, ...propsShortKeyed}
    let propsFiltered = {}
    for (const queryKey of Object.keys(propsNext)) {
      switch (queryKey) {
        case "f": {
          // filter (present, future, past)
          if (propsNext[queryKey] != "") {
            propsFiltered[queryKey] = propsNext[queryKey]
          }
          if (propsFiltered["f"] === "present") {
            delete propsFiltered["f"]
          }
          break
        }
        default: {
          if (__DEV__) {
            throw new Error(`updateQueryProps(): Unhandled queryKey '${queryKey}'.`)
          }
        }
      }
    }
    this.props.dispatch(replace({search: queryString.stringify(propsFiltered, {encode: false})}))
  }

  render = () => {
    const {intl, filter} = this.props
    const {headerText, eventsFinal, eventsPathLocalized} = this.props
    const d = {debug: DEBUG || this.props.debug}
    debby("render()", {filter, amountEvents: eventsFinal ? eventsFinal.length : ".."})

    return (
      <Container {...d} {...d}>
        <HeaderContainer {...d}>
          <H3Container {...d}>
            <CH3 text={headerText} noWordWrap />
          </H3Container>
          {false && (
            <HeaderFilterContainer
              //
              {...d}
              onClick={this.toggleFilters}
              // hasSettings={sortBy != SORT_BY_DEFAULT || galleriesFinal.length != galleries.length}
              hasSettings
            >
              Filter Container
            </HeaderFilterContainer>
          )}
        </HeaderContainer>
        <FiltersContainer {...d}>
          <OptionsHeaderLabel {...d}>{intl.formatMessage({id: "EventFiltersButtonTimeRangeSingleLabel"})}: </OptionsHeaderLabel>
          <OptionsContainer {...d}>
            {OPTIONS.map(optionKey => {
              return (
                <OptionButton
                  //
                  key={`filter_${optionKey}`}
                  selected={filter == optionKey}
                  onClick={() => this.onFilterOptionClick(optionKey)}
                >
                  {intl.formatMessage({id: getIntlIdByFilter(optionKey)})}
                </OptionButton>
              )
            })}
          </OptionsContainer>
        </FiltersContainer>

        <ItemsContainer {...d}>
          <FlipMove
            //
            staggerDurationBy={"50"}
            duration={500}
            typeName={"div"}
            key={`flipmove_${filter}`}
          >
            {!!eventsFinal &&
              !!eventsPathLocalized &&
              !!eventsFinal.length &&
              eventsFinal.map((event, i) => {
                // data structure see https://iazzu.com/api/postdata?path=/events
                const {slug, title, timeRange, showLocation, location} = event
                const {src, srcSet} = selectors.getSrcAndSrcSetByWordpressImage(god(event, "image")) // eslint-disable-line
                const src1000 = god(event, "image.sizes.1000.url") // eslint-disable-line
                // const linkTo = `/event/${slug}?lang=${intlLocale}`

                const linkTo = `${eventsPathLocalized}/${slug}`

                const locationReadable = showLocation && !!location ? location : ""
                return (
                  <SingleItem {...d} key={`event_${slug}`}>
                    <CLink to={linkTo} preloadDelayMs={i * 300} stretched>
                      <SinglePaddedItem {...d}>
                        <CImage
                          //
                          src={src1000}
                          ratio={365 / 300}
                          // ratio={365 / 250}
                          // ratio={16 / 9}
                          ratiosBreakpoints={{
                            //
                            md: 16 / 9,
                            sm: 16 / 9,
                            xs: 16 / 9,
                          }}
                          borderColor={"#EEE"} // pure-white images
                        />
                        <ItemTitle>{title}</ItemTitle>
                        <ItemSubTitle>{timeRange}</ItemSubTitle>
                        {!!locationReadable && <ItemLocation>{locationReadable}</ItemLocation>}
                      </SinglePaddedItem>
                    </CLink>
                  </SingleItem>
                )
              })}
          </FlipMove>
        </ItemsContainer>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column; // so it's vertical, for spacer
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.25)")}

  padding-top: 100px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 50px;
    }
  `}
`

const HeaderContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "HeaderContainer", "rgba(0,255,255,0.35)")};
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  display: flex;
  padding-bottom: 30px;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
      padding-bottom: 10px;
    }
  `}
`

const FiltersContainer = styled.div`
  padding-bottom: 30px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  ${props => selectors.getDebugOverlayCss(props, "FiltersContainer", "rgba(0,255,255,0.35)")};
`

const OptionsHeaderLabel = styled.div`
  letter-spacing: 0.06em;
  font-size: 15px;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  padding: 5px 0px;
`

// const BUTTON_HEIGHT_PX = 40
// const BUTTON_HEIGHT_PX_SMDOWN = 30

const OptionsContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "OptionsContainer", "rgba(0,0,255,0.35)")};
  overflow-y: scroll;
  background-color: white;
  display: flex;
  flex-direction: row;
  // gap: 10px;
`

const OptionButton = styled.div`
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 0.08em;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  transition: all 0.2s;

  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ${props =>
    props.selected &&
    css`
      background-color: ${props.theme.vars.colors.codGray};
      color: white;

      :hover {
        background-color: ${props.theme.vars.colors.codGray};
        opacity: 0.9;
      }
    `}
`

const H3Container = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
  ${props => selectors.getDebugOverlayCss(props, "H3Container", "rgba(0,0,255,0.35)")};
`

const HeaderFilterContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.3s;
  ${props =>
    props.hasSettings &&
    css`
      opacity: 1;
    `}
  ${props => selectors.getDebugOverlayCss(props, "FBC", "rgba(255,0,255,0.35)")};
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 10px;
      justify-content: flex-end;
    }
  `}
`

const GALLERIES_GUTTER_PX = 20

const ItemsContainer = styled.div`
  padding-top: 10px;
  position: relative;
  margin: 0px -${GALLERIES_GUTTER_PX}px;
  background-color: white;
  text-align: center;
  ${props => selectors.getDebugOverlayCss(props, "ItemsContainer", "rgba(255,0,0,0.35)")};
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
`

const SingleItem = styled.div`
  display: inline-block;
  vertical-align: top; // remove space below
  text-align: left;
  cursor: pointer !important;

  width: 32.7%;
  padding-bottom: 30px;
  ${props => css`
    ${props.theme.media.md} {
      width: 50%;
      padding-bottom: 20px;
    }
  `}
  ${props => css`
    ${props.theme.media.smdown} {
      width: 100%;
      padding-bottom: 20px;
    }
  `}
`

const SinglePaddedItem = styled.div`
  padding: ${GALLERIES_GUTTER_PX / 2}px;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
`

const ItemTitle = styled.div`
  padding-top: 15px;
  letter-spacing: 0.06em;
  font-size: 17px;
  line-height: 1.3;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  color: ${props => props.theme.vars.colors.codGray} !important;
`

const ItemSubTitle = styled.div`
  padding-top: 5px;
  letter-spacing: 0.08em;
  font-size: 14px;
  line-height: 1.3;
  color: ${props => props.theme.vars.colors.codGray} !important;
  opacity: 0.7;
  // font-weight: ${props => props.theme.vars.fonts.weights.bold};
`

const ItemLocation = styled.div`
  padding-top: 5px;
  letter-spacing: 0.08em;
  font-size: 11px;
  line-height: 1.3;
  color: ${props => props.theme.vars.colors.codGray} !important;
  opacity: 0.7;
  font-weight: ${props => props.theme.vars.fonts.weights.semibold};
`

const getPropsItems = (state, props) => props.items

const getFilter = createSelector([selectors.getQueryProps], queryProps => {
  const filterValue = god(queryProps, "f", "present")

  switch (filterValue) {
    case "future":
      return "future"
    case "past":
      return "past"
    default:
      return "present"
  }
})

const getEventsFinal = createSelector([getPropsItems, getFilter], (items, filter) => {
  const eventsFinal = god(items, filter)
  return eventsFinal
})

const mapStateToProps = (state, props) => ({
  queryProps: selectors.getQueryProps(state, props),
  // filtersShown: getFiltersShown(state, props),
  filter: getFilter(state, props),
  eventsFinal: getEventsFinal(state, props),
  mediaQueryClass: selectors.getMediaQueryClass(state),
  eventsPathLocalized: selectors.getEventsPathLocalized(state, props),
})

export default injectIntl(withRouter(connect(mapStateToProps)(CEventsList)))
