import {createSelector} from "reselect"
import {getObjectDeep as god, getArrayShuffledWithSeed, getArrayOrNull} from "./helpers"
import {getIntlLocale} from "./intl"
import {CONFIG} from "../../config"

const _getMatchFixed = match => {
  let matchFixed = match
  if (!!matchFixed && matchFixed.indexOf("?") != -1) {
    matchFixed = matchFixed.substr(0, matchFixed.indexOf("?"))
  }
  return matchFixed
}

export const getGallerySlugByMatch = (_, props) => _getMatchFixed(god(props, "match.params.gallerySlug"))
export const getArtworkSlugByMatch = (_, props) => _getMatchFixed(god(props, "match.params.artworkSlug"))
export const getLinkingKeyByMatch = (_, props) => _getMatchFixed(god(props, "match.params.linkingKey"))
export const getSlugByMatch = (_, props) => _getMatchFixed(god(props, "match.params.slug"))

const getStatePostData = state => state.api.postData

export const getPostDataGalleryLanding = createSelector([getStatePostData], statePostData => (!!statePostData && statePostData.hasOwnProperty("/g") ? statePostData["/g"].data : null))

export const getPostDataGalleryLandingArtwork = createSelector([getStatePostData], statePostData => (!!statePostData && statePostData.hasOwnProperty("/p") ? statePostData["/p"].data : null))

export const getGalleryDataByMatch = createSelector([getStatePostData, getGallerySlugByMatch], (statePostData, gallerySlug) => {
  const path = `/g/${gallerySlug}`
  return !!statePostData && statePostData.hasOwnProperty(path) ? statePostData[path].data : null
})

export const getGalleryTitleByMatch = createSelector([getGalleryDataByMatch], galleryData => {
  return god(galleryData, "title")
})

export const getGalleryContactEmailByMatch = createSelector([getGalleryDataByMatch], galleryData => {
  return god(galleryData, "contactEmail")
})

export const getGallerySecretCodeByMatch = createSelector([getGalleryDataByMatch], galleryData => {
  return god(galleryData, "secretCode")
})

export const getGalleryEmbedEnabledByMatch = createSelector([getGalleryDataByMatch], galleryData => {
  return god(galleryData, "embedEnabled", false)
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getGalleryArtworkByArtworkGql = artworkGql => {
  const galleryArtwork = {
    _isFromGql: true,
    slug: artworkGql.slug,
    slugByTitle: artworkGql.slugByTitle || artworkGql.slug,
    title: artworkGql.title,
    artistsNames: [artworkGql.artist],
    imageMasonry: {
      url: god(artworkGql, "featuredImageWp.sizes.u500.url"),
      width: god(artworkGql, "featuredImageWp.sizes.u500.width"),
      height: god(artworkGql, "featuredImageWp.sizes.u500.height"),
    },
    imageDetails: {
      url: god(artworkGql, "featuredImageWp.sizes.u1500.url"),
      width: god(artworkGql, "featuredImageWp.sizes.u1500.width"),
      height: god(artworkGql, "featuredImageWp.sizes.u1500.height"),
    },
    // yearStarted: !!yearStarted ? parseInt(yearStarted) : null,
    yearStarted: god(artworkGql, "yearStarted", null),
    yearCreated: god(artworkGql, "year", null),
    //
    // dimensionsReadable: god(artwork, `languageVariants.${intlLocale}.dimensionsReadable`, artwork.dimensionsReadable),
    dimensionsReadable: god(artworkGql, "dimensions"),
    //
    // techniquesAmount: god(artwork, "techniques", []).length,
    techniquesAmount: god(artworkGql, "amountTechniques", 0),
    // techniquesReadable: god(artwork, `languageVariants.${intlLocale}.techniquesReadable`, artwork.techniquesReadable),
    techniquesReadable: god(artworkGql, "techniques"),
    //
    // materialsAmount: god(artwork, "materials", []).length,
    materialsAmount: god(artworkGql, "amountMaterials", 0),
    // materialsReadable: god(artwork, `languageVariants.${intlLocale}.materialsReadable`, artwork.materialsReadable),
    materialsReadable: god(artworkGql, "materials"),
    availabilityStatus: god(artworkGql, "availability"),
    purchaseLink: god(artworkGql, "purchaseLink"),
    content: god(artworkGql, "content"),
    variants: god(artworkGql, "variants"),
    authCertificate: god(artworkGql, "authCertificate", false),
    // galleryDefaultCurrency: god(galleryData.galleryGql, "defaultCurrency"), // !!!!!!!!!!
    priceEuro: god(artworkGql, "priceEuro", 0),
    additionalImages: god(artworkGql, "additionalImagesWp", null),
    // NEW
    price: god(artworkGql, "price", 0), // readable
    //
    /*
          priceAllCurrencies, // not required any more
          additionalImages,
          */
  }
  return galleryArtwork
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const getGalleryArtworks = createSelector([getGalleryDataByMatch, getIntlLocale], (galleryData, intlLocale) => {
  if (galleryData && galleryData.galleryGql) {
    // debugger // from GraphQL!

    if (!galleryData.galleryGql.artworks?.length) {
      return null
    }
    const galleryArtworks = galleryData.galleryGql.artworks.map(artworkGql => getGalleryArtworkByArtworkGql(artworkGql))
    return galleryArtworks
  }

  const relatedArtworks = god(galleryData, "relatedArtworks")
  const relatedArtists = god(galleryData, "relatedArtists")

  const galleryDefaultCurrency = god(galleryData, "defaultCurrency", "EUR")
  // const galleryCode = god(galleryData, "secretCode")

  const dontShowArtworkPrices = god(galleryData, "dontShowArtworkPrices", false)

  if (!!relatedArtworks) {
    const imageSize = `500`
    const imageSizeWide = `1000`
    const imageSizeDetails = `1500`

    const artworks = relatedArtworks
      .filter(a => !a.status || a.status != "draft")
      .map(artwork => {
        let imageUrl = god(artwork, `featuredImage.sizes.${imageSize}.url`)
        let imageWidth = god(artwork, `featuredImage.sizes.${imageSize}.width`, 100)
        let imageHeight = god(artwork, `featuredImage.sizes.${imageSize}.height`, 100)
        const aspectRatio = imageWidth / imageHeight // 16/9
        const aspectRatioInv = imageHeight / imageWidth
        if (aspectRatio >= 2 || aspectRatioInv >= 2) {
          imageUrl = god(artwork, `featuredImage.sizes.${imageSizeWide}.url`)
          imageWidth = god(artwork, `featuredImage.sizes.${imageSizeWide}.width`, 100)
          imageHeight = god(artwork, `featuredImage.sizes.${imageSizeWide}.height`, 100)
        }
        const priceEuro = !dontShowArtworkPrices ? god(artwork, "price") : 0 // can be 0
        const priceAllCurrencies = god(artwork, "priceAllCurrencies", {EUR: priceEuro})
        const purchaseLink = god(artwork, "purchaseLink")

        const {year, yearStarted, variants} = artwork

        let additionalImages = []
        if (!!artwork.additionalImages && !!artwork.additionalImages.length) {
          additionalImages = artwork.additionalImages.filter(image => !!image && !image.isDraft)
        }
        additionalImages = getArrayOrNull(additionalImages)

        return {
          slug: artwork.slug,
          slugByTitle: artwork.slugByTitle || artwork.slug,
          title: artwork.title,
          artistsNames: artwork.artists.map(artistSlug => god(relatedArtists.find(artist => artist.slug == artistSlug), "title", "")), // prettier-ignore
          imageMasonry: {
            url: imageUrl,
            width: imageWidth,
            height: imageHeight,
          },
          imageDetails: {
            url: god(artwork, `featuredImage.sizes.${imageSizeDetails}.url`),
            width: god(artwork, `featuredImage.sizes.${imageSizeDetails}.width`, 100),
            height: god(artwork, `featuredImage.sizes.${imageSizeDetails}.height`, 100),
          },
          dimensionsReadable: god(artwork, `languageVariants.${intlLocale}.dimensionsReadable`, artwork.dimensionsReadable),
          techniquesAmount: god(artwork, "techniques", []).length,
          techniquesReadable: god(artwork, `languageVariants.${intlLocale}.techniquesReadable`, artwork.techniquesReadable),
          materialsAmount: god(artwork, "materials", []).length,
          materialsReadable: god(artwork, `languageVariants.${intlLocale}.materialsReadable`, artwork.materialsReadable),
          priceEuro,
          priceAllCurrencies,
          galleryDefaultCurrency,
          content: god(artwork, `languageVariants.${intlLocale}.contentFiltered`, god(artwork, `contentFiltered`)),
          availabilityStatus: artwork.availabilityStatus,
          authCertificate: artwork.authCertificate,
          additionalImages,
          yearStarted: !!yearStarted ? parseInt(yearStarted) : null,
          yearCreated: !!year ? parseInt(year) : null,
          purchaseLink,
          variants,
        }
      })
    const day = new Date().getUTCDate() + 1
    const artworksShuffled = getArrayShuffledWithSeed(artworks, day)
    return !!artworksShuffled.length ? artworksShuffled : null
  }
})

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const getStateApiPublicWalls = state => state.api.publicWalls

export const getGalleryPublicWalls = createSelector(
  //
  [getGalleryDataByMatch, getStateApiPublicWalls, getGallerySlugByMatch, getGalleryArtworks],
  (galleryData, stateApiPublicWalls, gallerySlug, galleryArtworks) => {
    if (galleryData && galleryData.galleryGql) {
      // debugger // check publicWalls
      if (!galleryData.galleryGql.publicWalls || !galleryData.galleryGql.publicWalls.length) {
        return null
      }
      const publicWalls = galleryData.galleryGql.publicWalls.map(wallGql => {
        return {
          id: wallGql.id,
          imageUrlSquare: wallGql.thumb,
          imageUrlFull: wallGql.image,
          artworkSlug: wallGql.artworkSlug,
          artwork: {
            title: wallGql.artwork,
            artistsNames: [wallGql.artist],
          },
        }
      })

      return publicWalls
    }

    // TODO: Remove this
    const publicWallsRaw = god(stateApiPublicWalls, `${gallerySlug}.data`)
    const urlPrefix = CONFIG.server.root + CONFIG.server.folderWalls // https://iazzu.com/wp/wp-content/plugins/iazzu-plugin/api/uploads/walls/
    let publicWalls = !publicWallsRaw
      ? null
      : publicWallsRaw.map(wall => {
          const {id, lastImageUploadHash, artworkSlug} = wall
          const imageSizeSquare = `_1000c`
          const imageSizeFull = ``
          const artwork = !galleryArtworks ? {} : galleryArtworks.find(a => a.slug == artworkSlug)
          return {
            ...wall,
            imageUrlSquare: `${urlPrefix}${id}_${lastImageUploadHash}${imageSizeSquare}.jpg`,
            imageUrlFull: `${urlPrefix}${id}_${lastImageUploadHash}${imageSizeFull}.jpg`,
            artwork,
          }
        })

    if (!!publicWalls) {
      publicWalls = publicWalls.sort((a, b) => b.created - a.created)
    }

    return !!publicWalls && !!publicWalls.length ? publicWalls : null
  },
)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const getStateApiPublicWallsFetchState = state => state.api.publicWallsFetchState

export const getGalleryPublicWallsFetching = createSelector(
  //
  [getStateApiPublicWallsFetchState, getGallerySlugByMatch],
  (stateApiPublicWallsFetchState, gallerySlug) => {
    const fetching = god(stateApiPublicWallsFetchState, `${gallerySlug}.busy`, false)
    return fetching
  },
)
