import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import isEqual from "lodash/isEqual"
import {createSelector} from "reselect"
import styled from "styled-components"

import * as actions from "../../../data/actions"
import * as selectors from "../../../data/selectors"
import {getObjectDeep as god} from "../../../data/selectors/helpers"
import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, pageTemplate, ..}

import CSpacer from "../../../view/components/CSpacer"
import CFooter from "../../../view/components/CFooter"
import CButton from "../../../view/components/CButton"
import CHeaderEmbeddedMode from "../../../view/components/CHeaderEmbeddedMode"

import CSectionGalleryArtworkDetails from "./CSectionGalleryArtworkDetails"

import CSectionGalleryPublicWalls from "../SGalleryLanding/CSectionGalleryPublicWalls"
import CSectionGalleryDemo from "../SGalleryLanding/CSectionGalleryDemo"

import {BranchManager} from "../../../data/utils"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SGalleryLandingArtwork", ...args)

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class SGalleryLandingArtwork extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    gallerySlug: PropTypes.string.isRequired,
    galleryTitle: PropTypes.string,
    galleryContactEmail: PropTypes.string,
    gallerySecretCode: PropTypes.string,
    artworkSlug: PropTypes.string.isRequired,
    artwork: PropTypes.object,
    postDataGalleryLandingArtwork: PropTypes.object,
    publicWalls: PropTypes.array,
    seoData: PropTypes.object,
    match: PropTypes.object,
    galleryEmbedEnabled: PropTypes.bool,
    galleryBranchShareInfos: PropTypes.object,
    artworkBranchShareInfos: PropTypes.object,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    // postData: PropTypes.object,
    wordPressUserId: PropTypes.number,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    embeddedMode: PropTypes.bool,
    locationPathname: PropTypes.string,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  }
  static defaultProps = {
    debug: DEBUG,
  }

  componentDidMount = async () => {
    const {dispatch, embeddedMode, gallerySlug, artworkSlug} = this.props
    if (embeddedMode) {
      dispatch(actions.sendAnalyticsEvent(`embedded_site_visit`, {GallerySlug: gallerySlug}))
      dispatch(actions.sendAnalyticsEvent(`embedded_site_visit_landing_artwork`, {GallerySlug: gallerySlug, ArtworkSlug: artworkSlug}))
    }
    // postData is fetched first..
    await Promise.all([
      //
      dispatch(actions.requestPostData("/p")), // gallery-landing-artwork
      dispatch(actions.requestPostData(`/g/${gallerySlug}`)), // no page template, custom post
      dispatch(actions.requestPublicWallsForGallery(gallerySlug)),
      // dispatch(actions.createShareLink("gallery", {slug: gallerySlug})),
      dispatch(actions.createShareLink("artwork", {slug: artworkSlug})),
    ])
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {intl, intlLocale, artworkBranchShareInfos, match, locationPathname, artworkSlug, gallerySlug, galleryTitle, galleryEmbedEnabled, galleryContactEmail, gallerySecretCode, postDataGalleryLandingArtwork, embeddedMode, publicWalls, artwork, seoData} = this.props
    const d = {debug: DEBUG}
    const showNoEmbedPlaceholder = !!galleryTitle && embeddedMode && !galleryEmbedEnabled
    const appLinkUrl = god(artworkBranchShareInfos, "url")

    const centerContent = selectors.getMediaQueryMatching(this.props.mediaQueryClass, "<=", "md")

    debby("render()", {match, artworkSlug, artwork, artworkBranchShareInfos})
    return (
      <Container {...d}>
        <CSpacer {...d} variant={embeddedMode ? `headermenu-embedded` : `headermenu`} />
        {embeddedMode && (
          <CHeaderEmbeddedMode
            locationPathname={locationPathname}
            shareButtonLabel={intl.formatMessage({id: `WallEditorShareWallButton`})}
            galleryTitle={galleryTitle}
            //
          />
        )}
        {showNoEmbedPlaceholder && (
          <NoEmbedPlaceholderContainer {...d}>
            <NoEmbedPlaceholderText {...d} dangerouslySetInnerHTML={{__html: intl.formatMessage({id: `PlaceholderEmbeddedVersionNotAvailable`}).split("\n").join("<br/>")}} />
            {!!appLinkUrl && (
              <CButton
                //
                urlTo={appLinkUrl}
                variant={"centered"}
                label={intl.formatMessage({id: `ButtonLabelEmbeddedVersionNotAvailable`})}
              />
            )}
          </NoEmbedPlaceholderContainer>
        )}
        {!!postDataGalleryLandingArtwork && !showNoEmbedPlaceholder && (
          <Fragment>
            {!!artwork && (
              <CSectionGalleryArtworkDetails
                //
                {...d}
                artwork={artwork}
                gallerySlug={gallerySlug}
                galleryContactEmail={galleryContactEmail}
                artworkUrlForEmail={god(seoData, "og:url")}
                // salesInquiryButtonLabel={intl.formatMessage({id: `GalleryLandingArtworkSectionGeneralSalesInquiryButtonLabel`})}
                salesInquiryButtonLabel={!artwork ? ".." : intl.formatMessage({id: selectors.getIntlIdForArtworkButton(artwork.availabilityStatus, artwork.priceEuro, !!artwork.purchaseLink)})}
                backToGalleryLabel={intl.formatMessage({id: `GalleryLandingArtworkSectionGeneralBackToGalleryLabel`})}
                artworkBranchShareInfos={artworkBranchShareInfos}
                centerContent={centerContent}
              />
            )}
            <CSectionGalleryPublicWalls
              //
              {...d}
              intl={intl}
              intlLocale={intlLocale}
              publicWalls={publicWalls}
              gallerySlug={gallerySlug}
              galleryTitle={galleryTitle}
              labelHeader={intl.formatMessage({id: `GalleryLandingArtworkSectionPublicWallsLabelHeader`})}
              labelSubheader={intl.formatMessage({id: `GalleryLandingArtworkSectionPublicWallsLabelSubheader`})}
              makeYourOwnGalleryButtonLabel={intl.formatMessage({id: `GalleryLandingArtworkSectionPublicWallsMakeYourOwnGalleryButtonLabel`})}
              noWallsYetLabel={intl.formatMessage({id: `GalleryLandingArtworkSectionPublicWallsNoWallsYetLabel`})}
              gallerySecretCode={gallerySecretCode}
              ownGalleryUrl={!!artworkBranchShareInfos ? artworkBranchShareInfos.url : BranchManager.getBranchUrl(gallerySecretCode)}
              centerContent={centerContent}
            />
            <CSectionGalleryDemo
              //
              {...d}
              galleryTitle={galleryTitle}
              gallerySecretCode={gallerySecretCode}
              infoParagraph={intl.formatMessage({id: `GalleryLandingArtworkSectionDemoInfoParagraph`})}
              buttonLabel={intl.formatMessage({id: `GalleryLandingArtworkSectionDemoButtonLabel`})}
              bottomLabel={intl.formatMessage({id: `GalleryLandingArtworkSectionFooterBottomLabel`})}
              ownGalleryUrl={!!artworkBranchShareInfos ? artworkBranchShareInfos.url : BranchManager.getBranchUrl(gallerySecretCode)}
            />
          </Fragment>
        )}

        <CFooter galleryTitle={galleryTitle} />
      </Container>
    )
  }
}

const Container = styled.div`
  position: relative;
  // background-color: ${props => props.theme.vars.colors.westar};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
`

const NoEmbedPlaceholderContainer = styled.div`
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  ${props => selectors.getDebugOverlayCss(props, "NoEmbedPlaceholderContainer", "rgba(0,255,255,0.35)")};
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const NoEmbedPlaceholderText = styled.div`
  font-size: 17px;
  line-height: 1.4;
  text-align: center;
  letter-spacing: 0.08em;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 50px; // possible CButton below
`

const getGalleryLandingArtwork = createSelector(
  //
  [selectors.getPostData, selectors.getGalleryArtworks, selectors.getArtworkSlugByMatch],
  (postData, galleryArtworks, artworkSlug) => {
    // const USE_POSTDATA = true
    if (postData && postData.artworkGql) {
      return selectors.getGalleryArtworkByArtworkGql(postData.artworkGql)
    }
    // if (USE_POSTDATA) {
    //   return null
    // }

    return !galleryArtworks || !artworkSlug ? null : galleryArtworks.find(artwork => artwork.slug == artworkSlug || artwork.slugByTitle == artworkSlug)
  },
)

export const getPublicWallsGalleryLandingArtwork = createSelector(
  //
  [selectors.getGalleryPublicWalls, selectors.getArtworkSlugByMatch],
  (galleryPublicWalls, artworkSlugByMatch) => {
    const publicWallsGalleryLandingArtwork = !galleryPublicWalls || !artworkSlugByMatch ? null : galleryPublicWalls.filter(wall => wall.artworkSlug == artworkSlugByMatch)
    return !!publicWallsGalleryLandingArtwork && !!publicWallsGalleryLandingArtwork.length ? publicWallsGalleryLandingArtwork : null
  },
)

const getBranchLinksState = state => state.branchLinks
const getGalleryBranchShareInfosKey = createSelector([selectors.getIntlLocale, selectors.getGallerySlugByMatch], (lang, gallerySlug) => (!!gallerySlug ? selectors.getBranchShareLinkKey({type: "gallery", lang, slug: gallerySlug}) : null))
const getGalleryBranchShareInfos = createSelector([getBranchLinksState, getGalleryBranchShareInfosKey], (branchLinksState, galleryBranchShareInfosKey) => {
  const qrData = !galleryBranchShareInfosKey ? null : god(branchLinksState, [galleryBranchShareInfosKey, "qrData"])
  return !qrData
    ? null
    : {
        url: god(qrData, "targetUrl"),
        qrImageUrl: god(qrData, "url"),
      }
})
const getArtworkBranchShareInfosKey = createSelector([selectors.getIntlLocale, selectors.getArtworkSlugByMatch], (lang, artworkSlug) => (!!artworkSlug ? selectors.getBranchShareLinkKey({type: "artwork", lang, slug: artworkSlug}) : null))
const getArtworkBranchShareInfos = createSelector([getBranchLinksState, getArtworkBranchShareInfosKey], (branchLinksState, artworkBranchShareInfosKey) => {
  const qrData = !artworkBranchShareInfosKey ? null : god(branchLinksState, [artworkBranchShareInfosKey, "qrData"])
  return !qrData
    ? null
    : {
        url: god(qrData, "targetUrl"),
        qrImageUrl: god(qrData, "url"),
      }
})

const mapStateToProps = (state, props) => ({
  gallerySlug: selectors.getGallerySlugByMatch(state, props),
  galleryTitle: selectors.getGalleryTitleByMatch(state, props),
  galleryContactEmail: selectors.getGalleryContactEmailByMatch(state, props),
  gallerySecretCode: selectors.getGallerySecretCodeByMatch(state, props),
  galleryEmbedEnabled: selectors.getGalleryEmbedEnabledByMatch(state, props),
  galleryBranchShareInfos: getGalleryBranchShareInfos(state, props),
  artworkBranchShareInfos: getArtworkBranchShareInfos(state, props),
  artworkSlug: selectors.getArtworkSlugByMatch(state, props),
  artwork: getGalleryLandingArtwork(state, props),
  postDataGalleryLandingArtwork: selectors.getPostDataGalleryLandingArtwork(state, props),
  publicWalls: getPublicWallsGalleryLandingArtwork(state, props),
  seoData: selectors.getSeoDataByLocationPathname(state, props), // routes.js
  match: props.match,
})
export default withScreenProps(connect(mapStateToProps)(SGalleryLandingArtwork))
