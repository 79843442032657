import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"
import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, pageTemplate, ..}

import CEditInWordPress from "../../../view/components/CEditInWordPress" // eslint-disable-line

import COVRFeatured from "./COVRFeatured"
import COVRExhibitors from "./COVRExhibitors"

import CSpacer from "../../../view/components/CSpacer"
import CFooter from "../../../view/components/CFooter"

const DEBUG = true && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SOVR", ...args)

const god = selectors.getObjectDeep

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class SOVR extends Component {
  static propTypes = {
    sitemapData: PropTypes.array,
    fixedSectionsItems: PropTypes.array,
    defaultThemeProps: PropTypes.object,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    wordPressUserId: PropTypes.number,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    locationPathname: PropTypes.string,
  }
  static defaultProps = {
    defaultThemeProps: {
      backgroundColor: "white",
      textColor: "black",
    },
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {intlLocale, intl, defaultThemeProps, mediaQueryClass, wordPressUserId, postData, locationPathname} = this.props // eslint-disable-line
    const d = {debug: DEBUG}
    const themeOverrides = {}
    const gallery = god(postData, "sectionFeatured.gallery")
    debby("render()", {mediaQueryClass, wordPressUserId})
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <Container {...d}>
          <CSpacer variant={"headermenu"} />
          <COVRFeatured
            //
            intlLocale={intlLocale} // City, Country
            headerText={god(postData, "sectionFeatured.headerText")}
            gallery={gallery}
            galleryCountryReadable={intl.formatMessage({id: `Country${god(gallery, "country", "CH").toUpperCase()}`})}
          />
          <COVRExhibitors
            //
            intlLocale={intlLocale} // City, Country
            headerText={god(postData, "sectionExhibitors.headerText")}
            filterLabelText={god(postData, "sectionExhibitors.filterLabelText")}
            sortByText={god(postData, "sectionExhibitors.sortByText")}
            sortByCountryText={god(postData, "sectionExhibitors.sortByCountryText")}
            resetFiltersText={god(postData, "sectionExhibitors.resetFiltersText")}
            searchTextPlaceholder={god(postData, "sectionExhibitors.searchTextPlaceholder")}
            noItemsText={god(postData, "sectionExhibitors.noItemsText")}
            galleries={god(postData, "sectionExhibitors.galleries")}
          />
        </Container>
        <CFooter locationPathname={locationPathname} />
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  padding-bottom: 50px;
`

const mapStateToProps = state => ({
  sitemapData: selectors.getSitemapData(state),
})
export default withScreenProps(connect(mapStateToProps)(SOVR))
