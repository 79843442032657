import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
// import {createSelector} from "reselect"
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line
import {FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton} from "react-share" // eslint-disable-line
import {withController} from "react-scroll-parallax"

import * as actions from "../../../data/actions"
import * as selectors from "../../../data/selectors"
import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, pageTemplate, ..}

import CFooter from "../../../view/components/CFooter"
import CH2 from "../../../view/components/text/CH2"
import CH4 from "../../../view/components/text/CH4"
import CIcon from "../../../view/components/CIcon"
import CImage from "../../../view/components/CImage"
import CLink from "../../../view/components/CLink"
import CContentParts from "../../../view/components/CContentParts"
import CEditInWordPress from "../../../view/components/CEditInWordPress"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SBlogDetails", ...args)

import {CONFIG} from "../../../config"

const god = selectors.getObjectDeep

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class SBlogDetails extends Component {
  static propTypes = {
    stageWidth: PropTypes.number,
    sitemapData: PropTypes.array,

    // withController
    parallaxController: PropTypes.object.isRequired,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    wordPressUserId: PropTypes.number,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    locationPathname: PropTypes.string,
    blogPathLocalized: PropTypes.string,
  }
  static defaultProps = {
    defaultThemeProps: {
      backgroundColor: "white",
      textColor: "black",
    },
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  getShareProps = () => {
    const {postData} = this.props
    const shareUrl = god(postData, "url")
    const shareTitle = god(postData, "title")
    const shareDescription = "" // TODO?
    return {shareUrl, shareTitle, shareDescription}
  }

  onContentClick = event => {
    const {nodeName, parentNode} = event.target
    // event.preventDefault()

    switch (nodeName) {
      case "A": {
        event.preventDefault()
        const href = event.target.href
        debby("onContentClick(): A", {href})
        // debugger
        break
      }
    }

    if (nodeName == "IMG") {
      event.preventDefault()
      debby("onContentClick(): IMG")
      let title = null
      if (parentNode.nodeName == "FIGURE") {
        const childNodes = parentNode.childNodes
        for (let i = 0; i < childNodes.length; i++) {
          const child = childNodes[i] // eslint-disable-line
          if (child.nodeName == "FIGCAPTION") {
            title = child.innerText
          }
        }
      }
      const subtitle = !!title ? god(this.props.postData, "title") : null
      const url = event.target.getAttribute("src") // full width
      this.props.dispatch(actions.showImageOverlay({title, subtitle, url}))
    }
  }

  render = () => {
    const {intlLocale, blogPathLocalized, intl, defaultThemeProps, mediaQueryClass, wordPressUserId, postData, locationPathname} = this.props // eslint-disable-line
    const {parallaxController, editInWordPressProps} = this.props
    const d = {debug: DEBUG}
    const themeOverrides = {}

    const shareProps = this.getShareProps()
    const {shareUrl, shareTitle, shareDescription} = shareProps

    const contentFilteredParts = selectors.getContentParts(god(postData, "contentFiltered"))

    const blogData = god(postData, "blogData")

    const parallaxRatio = 0.1
    const heroImageSrcData = selectors.getSrcAndSrcSetByWordpressImage(god(postData, "featuredImage"), {useCropped: !!parallaxRatio})

    const createdReadable = god(blogData, "createdReadableWeb")

    const labels = {
      content: god(postData, "sectionLabels.content"),
      relatedBlog: god(postData, "sectionLabels.relatedBlog"),
      relatedBlogs: god(postData, "sectionLabels.relatedBlogs"),
      source: god(postData, "sectionLabels.source"),
      sources: god(postData, "sectionLabels.sources"),
      links: god(postData, "sectionLabels.links"),
    }

    const relatedBlogs = god(blogData, "relatedBlogs")
    const sources = god(blogData, "sources")
    const links = god(blogData, "links")

    // if (blogData) debugger
    debby("render()", {blogData, shareUrl})
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <Container {...d}>
          <CImage
            //
            src={heroImageSrcData}
            backgroundColor={"#191919"}
            srcSet={heroImageSrcData.srcSet}
            overlayColor={`rgba(0,0,0,0.5)`}
            parallaxRatio={parallaxRatio}
            parallaxController={parallaxController}
            heightGrows
            blurPx={5}
          >
            <PaddedContainer {...d} variant={`content`}>
              <HeaderContainer>
                <CH2 {...d} text={god(postData, "title", "")} />
              </HeaderContainer>
            </PaddedContainer>
          </CImage>

          {!!blogData && (
            <PaddedContainer {...d} variant={`header`} hasMaxWidth>
              <InfosContainer {...d}>
                <InfoPart {...d}>{createdReadable}</InfoPart>
              </InfosContainer>

              {!!shareUrl && (
                <ShareContainer {...d}>
                  <ShareCaption {...d}>{intl.formatMessage({id: `KnowledgeBaseArticleShareLabel`})}</ShareCaption>
                  <ShareIcon {...d}>
                    <FacebookShareButton url={shareUrl}>
                      <CIcon id={"FooterFacebook"} color={CONFIG.layout.colors.trout} />
                    </FacebookShareButton>
                  </ShareIcon>
                  {CONFIG.app.showTwitter && (
                    <ShareIcon {...d}>
                      <TwitterShareButton url={shareUrl} title={shareTitle} via={"iazzu"} tags={["iazzu", "gallery"]} related={["iazzu"]}>
                        <CIcon id={"FooterTwitter"} color={CONFIG.layout.colors.trout} />
                      </TwitterShareButton>
                    </ShareIcon>
                  )}
                  <ShareIcon {...d} yOffset={-1}>
                    <LinkedinShareButton url={shareUrl} title={shareTitle}>
                      <CIcon id={"FooterLinkedin"} color={CONFIG.layout.colors.trout} />
                    </LinkedinShareButton>
                  </ShareIcon>
                  <ShareIcon {...d} yOffset={2}>
                    <EmailShareButton
                      //
                      url={shareUrl}
                      subject={shareTitle}
                      // body={encodeURI(shareDescription)}
                      body={shareDescription}
                    >
                      <CIcon id={"FooterEmail"} color={CONFIG.layout.colors.trout} />
                    </EmailShareButton>
                  </ShareIcon>
                </ShareContainer>
              )}

              {!!contentFilteredParts && !!contentFilteredParts.length && (
                <SectionContainer {...d}>
                  <SectionHeaderContainer {...d}>
                    <CH4 {...d} text={labels.content} />
                  </SectionHeaderContainer>
                  <CContentParts
                    //
                    {...d}
                    parts={contentFilteredParts}
                    onClick={this.onContentClick}
                  />
                </SectionContainer>
              )}

              {!!relatedBlogs && !!relatedBlogs.length && (
                <SectionContainer {...d}>
                  <SectionHeaderContainer {...d}>
                    <CH4 {...d} text={labels[relatedBlogs.length == 1 ? "relatedBlog" : "relatedBlogs"]} />
                  </SectionHeaderContainer>
                  <SectionContentContainer {...d}>
                    {relatedBlogs.map((entry, i) => (
                      <CLink key={`line_${i}`} to={`${blogPathLocalized}/${entry.slugInLang}`} debug={false}>
                        <SectionLine
                          //
                          dangerouslySetInnerHTML={{__html: entry.title}}
                          underlined
                        />
                      </CLink>
                    ))}
                  </SectionContentContainer>
                </SectionContainer>
              )}

              {!!sources && !!sources.length && (
                <SectionContainer {...d}>
                  <SectionHeaderContainer {...d}>
                    <CH4 {...d} text={labels[sources.length == 1 ? "source" : "sources"]} />
                  </SectionHeaderContainer>
                  <SectionContentContainer {...d}>
                    {sources.map((entry, i) => (
                      <CLink key={`line_${i}`} url={entry.url} debug={false}>
                        <SectionLine
                          //
                          dangerouslySetInnerHTML={{__html: !!entry.title ? entry.title : entry.url}}
                          underlined
                        />
                      </CLink>
                    ))}
                  </SectionContentContainer>
                </SectionContainer>
              )}

              {!!links && !!links.length && (
                <SectionContainer {...d}>
                  <SectionHeaderContainer {...d}>
                    <CH4 {...d} text={labels["links"]} />
                  </SectionHeaderContainer>
                  <SectionContentContainer {...d}>
                    {links.map((entry, i) => (
                      <CLink key={`line_${i}`} url={entry.url} debug={false}>
                        <SectionLine
                          //
                          dangerouslySetInnerHTML={{__html: !!entry.title ? entry.title : entry.url}}
                          underlined
                        />
                      </CLink>
                    ))}
                  </SectionContentContainer>
                </SectionContainer>
              )}
            </PaddedContainer>
          )}
        </Container>

        <CFooter locationPathname={locationPathname} />

        <CEditInWordPress fixed {...editInWordPressProps} />
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  background-color: ${props => props.theme.backgroundColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  padding-bottom: 50px;
`

const PaddedContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant, "padding")}
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `PaddedContainer`, `rgba(0,0,255,0.15)`)}
  text-align: ${props => (props.align == "center" ? "center" : "left")};
  ${props =>
    props.hasMaxWidth &&
    css`
      max-width: 900px;
      margin: 0 auto;
    `}
`
const HeaderContainer = styled.div`
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-direction: column;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `HeaderContainer`, `rgba(255,0,0,0.15)`)}
`

const InfosContainer = styled.div`
  padding: 30px 0px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `InfosContainer`, `rgba(255,0,0,0.15)`)}
`

const InfoPart = styled.div`
  padding: 5px 0px;
  font-family: ${props => props.theme.vars.fonts.families.default};
  font-weight: ${props => props.theme.vars.fonts.weights.semibold};
  font-style: italic;
  font-size: 22px;
  line-height: 32px;
  color: #333;
  letter-spacing: 1.6px;
  text-align: left;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `InfoPart`, `rgba(0,255,0,0.15)`)}
`

const ShareContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin-bottom: 50px;
`

const ShareCaption = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid red;
  font-size: 14px;
  font-family: ${props => props.theme.vars.fonts.families.default};
  letter-spacing: 3px;
  text-transform: uppercase;
  border-bottom: 1px solid #1e1e1e;
  color: #1e1e1e;
  padding-bottom: 4px;
  padding-top: 4px;
  margin-right: 15px;
`

const ShareIcon = styled.div`
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transform: translate3d(0px, ${props => props.yOffset || 0}px, 0px);
`

const SectionContainer = styled.div`
  padding-top: 60px;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SectionContainer`, `rgba(0,255,0,0.15)`)}
`

const SectionHeaderContainer = styled.div`
  padding-bottom: 10px;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SectionHeaderContainer`, `rgba(0,255,0,0.15)`)}
`

const SectionContentContainer = styled.div`
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SectionContentContainer`, `rgba(0,255,0,0.15)`)}
`

const SectionLine = styled.div`
  padding-bottom: 10px;
  font-family: ${props => props.theme.vars.fonts.families.default};
  font-size: 16px;
  color: #1e1e1e;
  letter-spacing: 1.6px;
  text-align: left;
  line-height: 23px;
  // background-color: blue;
  display: table;

  ${props =>
    props.underlined &&
    css`
      text-decoration: underline;
    `}
`

const mapStateToProps = (state, props) => ({
  stageWidth: state.device.stageDimensions.width,
  sitemapData: selectors.getSitemapData(state),
  blogPathLocalized: selectors.getBlogPathLocalized(state, props),
})
export default withController(withScreenProps(connect(mapStateToProps)(SBlogDetails)))
