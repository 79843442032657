import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import {Waypoint} from "react-waypoint"
import isEqual from "lodash/isEqual"
import {Element as ScrollElement} from "react-scroll"

import WaypointContext from "../context/WaypointContext"

const USE_DUMMY = false

// const DEBUG = true && __DEV__
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CWaypoint", ...args)

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class CWaypoint extends Component {
  static propTypes = {
    children: PropTypes.any,
    debug: PropTypes.bool,
    id: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    onEnter: PropTypes.func,
    onLeave: PropTypes.func,
    bottomOffset: PropTypes.number,
    topOffset: PropTypes.number,

    contextValue: PropTypes.any,
    onMount: PropTypes.func,
    onUnmount: PropTypes.func,
    onEnterProvider: PropTypes.func,
    onLeaveProvider: PropTypes.func,
    extraData: PropTypes.object,
    scrollElementName: PropTypes.string,
  }
  static defaultProps = {
    debug: false && __DEV__,
    bottomOffset: 1,
    topOffset: 1,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentDidMount = () => {
    const {onMount, id, themeKey, extraData} = this.props
    !!onMount && onMount(id, themeKey, extraData)
  }

  componentWillUnmount = () => {
    const {onUnmount, id} = this.props
    !!onUnmount && onUnmount(id)
  }

  onWaypointEnter = ({previousPosition, currentPosition}) => {
    const {id, onEnter, onEnterProvider} = this.props
    debby(`onWaypointEnter()`, {id})
    !!onEnter && onEnter(id, previousPosition, currentPosition)
    !!onEnterProvider && onEnterProvider(id, this.props.extraData)
  }

  onWaypointLeave = ({previousPosition, currentPosition}) => {
    const {id, onLeave, onLeaveProvider} = this.props
    debby(`onWaypointLeave()`, {id})
    !!onLeave && onLeave(id, previousPosition, currentPosition)
    !!onLeaveProvider && onLeaveProvider(id)
  }

  render = () => {
    const {bottomOffset, topOffset, children, id, scrollElementName} = this.props
    if (USE_DUMMY) {
      return children
    }
    return (
      <Fragment>
        {!!scrollElementName && <ScrollElement name={scrollElementName} />}
        <Waypoint
          key={`waypoint_${id}`}
          scrollableAncestor={window}
          fireOnRapidScroll={false}
          // topOffset={"1px"}
          // bottomOffset={"1px"}
          bottomOffset={`${bottomOffset}px`} // when scrolling down
          topOffset={`${topOffset}px`} // when scrolling up
          onLeave={this.onWaypointLeave}
          onEnter={this.onWaypointEnter}
        >
          <div>{!!children ? children : <div />}</div>
        </Waypoint>
      </Fragment>
    )
  }
}

class CWaypointWithContext extends Component {
  static propTypes = {
    children: PropTypes.any,
  }
  render = () => (
    <WaypointContext.Consumer>
      {value => (
        <CWaypoint
          //
          onMount={value.onWaypointMount}
          onUnmount={value.onWaypointUnmount}
          onEnterProvider={value.onEnterProvider}
          onLeaveProvider={value.onLeaveProvider}
          {...this.props}
        >
          {this.props.children}
        </CWaypoint>
      )}
    </WaypointContext.Consumer>
  )
}

export default CWaypointWithContext
