import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import {connect} from "react-redux"

import * as selectors from "../../data/selectors"
import * as actions from "../../data/actions"

const getHrefRecursively = target => {
  if (target.nodeName == "A") {
    return target.href
  } else if (!!target.parentNode) {
    return getHrefRecursively(target.parentNode)
  }
  return null
}
const getHrefByClickEvent = event => (!event ? null : getHrefRecursively(event.target)) // TODO: Move to helpers

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class CContent extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    resourceId: PropTypes.number,
    dispatch: PropTypes.func.isRequired,
    html: PropTypes.string,
    //
    textColor: PropTypes.string,
    onImageClick: PropTypes.func,
    onLinkClick: PropTypes.func,
    onClick: PropTypes.func,
  }
  static defaultProps = {
    debug: false && __DEV__,
    textColor: "#1e1e1e",
    resourceId: -1,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onClick = event => {
    event.preventDefault() // always
    const {onImageClick, onLinkClick, onClick} = this.props
    const nodeName = event.target.nodeName

    if (nodeName == "IMG") {
      const imageId = parseInt(event.target.getAttribute("data-id"))
      if (!!imageId) {
        !!onImageClick && onImageClick({imageId})
        // return
      }
    } else {
      const href = getHrefByClickEvent(event)
      if (!!href) {
        !!onLinkClick && onLinkClick({href})
        if (!onLinkClick) {
          this.props.dispatch(actions.handleUrl(href))
        }
      }
    }

    if (!!onClick) {
      onClick(event)
    }
  }

  render = () => {
    const {debug, html, textColor, resourceId} = this.props
    const d = {debug: debug || CContent.defaultProps.debug}
    return (
      <Content
        //
        {...d}
        textColor={textColor}
        dangerouslySetInnerHTML={{__html: selectors.getHtmlSafe(html, resourceId)}}
        onClick={this.onClick}
      />
    )
  }
}

const Content = styled.div`
  max-width: 100%;
  color: ${props => props.textColor};

  font-size: 16px;
  letter-spacing: 1.6px;
  text-align: left !important;
  line-height: 1.6;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `Content`, `rgba(255,128,0,0.5)`)}
  border: ${props => (props.debug ? 1 : 0)}px solid blue;

  p,
  * > p {
    margin-block-start: 12px;
    margin-block-end: 12px;
    text-align: left !important;
  }

  a,
  * > a {
    color: black !important;
    font-weight: ${props => props.theme.vars.fonts.weights.semibold};
  }

  li {
    // background-color: red;
    margin-bottom: 10px;
  }

  figure,
  * > figure {
    display: block;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 30px 0px;

    ${props => css`
      ${props.theme.media.sm} {
        padding: 25px 0px;
      }
      ${props.theme.media.xs} {
        padding: 15px 0px;
      }
    `}

    figcaption {
      font-style: italic;
      text-align: center;
      font-size: 16px;

      ${props => css`
        ${props.theme.media.sm} {
          font-size: 15px;
        }
        ${props.theme.media.xs} {
          font-size: 14px;
        }
      `}
    }

    img {
      width: 100%;
      object-fit: contain;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.04);

      max-height: 400px;
      padding: 20px 0px;
      ${props => css`
        ${props.theme.media.md} {
          max-height: 350px;
        }
        ${props.theme.media.sm} {
          max-height: 300px;
          padding: 15px 0px;
        }
        ${props.theme.media.xs} {
          max-height: 250px;
          padding: 10px 0px;
        }
      `}
    }
  }

  ${props => props.debug && selectors.getDebugOverlayCss(props, `Content`, `rgba(255,255,0,0.35)`)}
`

// eslint-disable-next-line
const mapStateToProps = (state, props) => ({})
export default connect(mapStateToProps)(CContent)
