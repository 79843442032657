import React, {Component, Fragment} from "react" // eslint-disable-line
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import throttle from "lodash/throttle"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import {Link} from "react-router-dom"
import {motion, useViewportScroll, useTransform, AnimatePresence} from "framer-motion" // eslint-disable-line
import styled, {css, ThemeProvider} from "styled-components"

import * as selectors from "../../data/selectors"

import {LogoBlack} from "../../images"
import {LogoWhite} from "../../images"

import CCollapse from "../../view/components/CCollapse"
import CIcon from "../../view/components/CIcon"
import CLink from "../../view/components/CLink"

import {CONFIG} from "../../config"

const DEBUG = false && __DEV__
const debby = (funcName, data = null) => DEBUG && console.log("CHeaderMenu." + funcName, data)

const SCROLL_Y_TIL_NON_TRANSPARENT = 20

const debounce = (method, delay) => {
  clearTimeout(method._tId)
  method._tId = setTimeout(() => {
    method()
  }, delay)
}

const REACT_TO_SCROLL = true

const DEBOUNCE_NOT_THROTTLE = true

const USE_INTERVAL = true

class CHeaderMenu extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    mobileMenuDebug: PropTypes.bool,
    intl: PropTypes.object.isRequired,
    menuItems: PropTypes.array,
    intlLocale: PropTypes.string.isRequired,
    otherLocales: PropTypes.array,
    currentPageLinkOtherLangs: PropTypes.object,
    locationPathname: PropTypes.string.isRequired,
    globalsLocalized: PropTypes.object,
    filledWhenScrolledUp: PropTypes.bool,
    subMenuHideDelay: PropTypes.number,
    showLinkedInIcon: PropTypes.bool,
    showAppIcon: PropTypes.bool,
  }
  static defaultProps = {
    debug: DEBUG,
    mobileMenuDebug: false && DEBUG, // if true, menu is initially visible
    subMenuHideDelay: 3000,
    showAppIcon: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      submenuKey: null,
      langsExpanded: false,
      scrolledDown: selectors.getWindowScrollY() > SCROLL_Y_TIL_NON_TRANSPARENT,
      visible: true,
      mobileMenuVisible: props.debug && props.mobileMenuDebug,
      mobileMenuItemKeyExpanded: null,
    }
    this.prevScrollPos = selectors.getWindowScrollY()
  }

  componentDidMount = () => {
    if (!REACT_TO_SCROLL) {
      return
    }

    debby("componentDidMount()", {}, true)

    if (USE_INTERVAL) {
      this.intervalIdScrollListener = setInterval(this.handleScroll, 250)
    } else {
      this.timeoutIdAddScrollListener = setTimeout(() => {
        window.addEventListener("scroll", this.handleScrollDebounced) // timeout so initial load doesn't trigger behaviour
      }, 1000)
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentWillUnmount = () => {
    debby("componentWillUnmount()", {}, true)
    clearTimeout(this.timeoutIdAddScrollListener)
    clearInterval(this.intervalIdScrollListener)
    this.clearHideSubmenuWithDelay()
    window.removeEventListener("scroll", this.handleScrollDebounced)
  }

  handleScrollDebounced = () => {
    if (!DEBOUNCE_NOT_THROTTLE) {
      // throttle(this.handleScroll, 500, {trailing: true, leading: true})
      throttle(this.handleScroll, 500)
    } else {
      debounce(this.handleScroll, 100)
    }
  }

  handleScroll = () => {
    const currentScrollPos = selectors.getWindowScrollY()
    if (currentScrollPos === this.prevScrollPos) {
      return
    }

    const {mobileMenuVisible, langsExpanded, submenuKey} = this.state
    const scrolledDown = currentScrollPos > SCROLL_Y_TIL_NON_TRANSPARENT
    const visible = !scrolledDown || this.prevScrollPos >= currentScrollPos || mobileMenuVisible

    this.prevScrollPos = currentScrollPos

    const stateNew = {
      visible,
      scrolledDown: scrolledDown && visible,
      langsExpanded: visible ? langsExpanded : false,
      submenuKey: visible ? submenuKey : null,
    }
    if (stateNew.visible == this.state.visible && stateNew.scrolledDown == this.state.scrolledDown && stateNew.langsExpanded == this.state.langsExpanded && stateNew.submenuKey == this.state.submenuKey) {
      return
    }
    debby("handleScroll()", stateNew)
    this.setState(stateNew)
  }

  clearHideSubmenuWithDelay = () => clearTimeout(this.hideSubmenuWithDelayTimeoutId)
  hideSubmenuWithDelay = () => {
    this.clearHideSubmenuWithDelay()
    this.hideSubmenuWithDelayTimeoutId = setTimeout(() => {
      this.setState({submenuKey: null})
    }, this.props.subMenuHideDelay)
  }

  onContainerMouseLeave = () => {
    debby(`onContainerMouseLeave()`)
    this.setState({langsExpanded: false})
    this.hideSubmenuWithDelay()
  }

  onItemHover = key => {
    debby(`onItemHover()`, {key})
    this.hideSubmenuWithDelay()
  }

  onItemWithSubItemsHover = key => {
    debby(`onItemWithSubItemsHover()`, {key})
    const {menuItems} = this.props
    const item = menuItems.find(item => item.key == key)

    this.clearHideSubmenuWithDelay()
    item && this.setState({submenuKey: key, langsExpanded: false})
  }

  onSubItemHover = () => {
    debby(`onSubItemHover()`)
    this.clearHideSubmenuWithDelay()
  }

  onItemWithSubItemsClick = key => {
    debby(`onItemWithSubItemsClick()`, {key})
    const {menuItems} = this.props
    const item = menuItems.find(item => item.key == key)
    if (!!item) {
      const {submenuKey} = item
      this.clearHideSubmenuWithDelay()
      this.setState({submenuKey: submenuKey == key ? null : key, langsExpanded: false})
    }
  }

  onItemClickDummy = key => debby(`onItemClickDummy()`, {key})

  onSubItemClick = (key, subItemKey) => {
    debby(`onItemWithSubItemsClick()`, {key, subItemKey})
    this.hideSubmenuWithDelay()
  }

  onLangDropdownClick = () => {
    debby(`onLangDropdownClick()`)

    const {currentPageLinkOtherLangs} = this.props
    if (!currentPageLinkOtherLangs || !Object.keys(currentPageLinkOtherLangs).length) {
      return
    }
    this.setState({
      submenuKey: null,
      langsExpanded: !this.state.langsExpanded,
    })
  }

  onSearchClick = () => alert("SEARCH") // TODO: Implement Search Function (v3.0.1)

  onOtherLangClick = locale => {
    debby(`onOtherLangClick()`, {locale})
    this.setState({
      submenuKey: null,
      langsExpanded: false,
      mobileMenuVisible: false,
    })
  }

  //  Mobile

  toggleMobileMenuVisible = () => this.setState({mobileMenuVisible: !this.state.mobileMenuVisible})

  onMobileMenuItemClick = key => this.setState({mobileMenuItemKeyExpanded: key})

  onMobileLinkWithSubItemsClick = (key, linkTo) => {
    debby(`onMobileLinkWithSubItemsClick()`, {key})
    if (!!linkTo) {
      this.toggleMobileMenuVisible()
    }
  }

  onMobileLinkWithoutSubItemsClick = key => {
    debby(`onMobileLinkWithoutSubItemsClick()`, {key})
    this.toggleMobileMenuVisible()
  }

  onMobileSubItemLinkClick = (key, keySubItem) => {
    debby(`onMobileSubItemLinkClick()`, {key, keySubItem})
    this.toggleMobileMenuVisible()
  }

  getTheme = () => {
    const {scrolledDown} = this.state
    const {filledWhenScrolledUp} = this.props
    return {
      textColorInactive: !filledWhenScrolledUp || scrolledDown ? "#888" : "#AAA",
      textColorActive: !filledWhenScrolledUp || scrolledDown ? "black" : "white",
    }
  }

  render = () => {
    const {submenuKey, scrolledDown, visible, langsExpanded, mobileMenuVisible, mobileMenuItemKeyExpanded} = this.state
    const {intl, menuItems, intlLocale, otherLocales, currentPageLinkOtherLangs, globalsLocalized, filledWhenScrolledUp, showLinkedInIcon, showAppIcon} = this.props
    const d = {debug: DEBUG || this.props.debug}

    const theme = this.getTheme() // text & border colors
    let iconColorSocialIconsDesktop = "#778080"
    if (!scrolledDown && filledWhenScrolledUp) {
      // iconColorSocialIconsDesktop = "white"
      iconColorSocialIconsDesktop = "#778080"
    }
    const logoIconSrc = selectors.getObjectDeep(globalsLocalized, "logoIcon.sizes.300c.url")
    debby("render()", {logoIconSrc, menuItems, scrolledDown, filledWhenScrolledUp})
    return (
      <div style={{position: "relative"}}>
        <ThemeProvider theme={theme}>
          <Container
            //
            {...d}
            paddingVariant={"header"}
            scrolledDown={scrolledDown}
            filledWhenScrolledUp={filledWhenScrolledUp}
            visible={visible}
            onMouseLeave={this.onContainerMouseLeave}
          >
            <ContContent {...d}>
              <CLink to={showLinkedInIcon ? "/app" : "/"}>
                <LogoContainer {...d}>
                  {!!logoIconSrc && showAppIcon && <LogoIconImg src={logoIconSrc} />}
                  <LogoImage src={!filledWhenScrolledUp || scrolledDown ? LogoBlack : LogoWhite} />
                </LogoContainer>
              </CLink>

              {false && (
                <SocialIconsDesktopContainer {...d}>
                  {!!globalsLocalized.contact.linkedin && showLinkedInIcon && <CSocialIcon href={globalsLocalized.contact.linkedin} iconId={"FooterLinkedin"} iconColor={iconColorSocialIconsDesktop} />}
                  {!!globalsLocalized.contact.instagram && <CSocialIcon href={globalsLocalized.contact.instagram} iconId={"FooterInstagram"} iconColor={iconColorSocialIconsDesktop} />}
                  {!!globalsLocalized.contact.facebook && <CSocialIcon href={globalsLocalized.contact.facebook} iconId={"FooterFacebook"} iconColor={iconColorSocialIconsDesktop} />}
                  {!!globalsLocalized.contact.twitter && CONFIG.app.showTwitter && <CSocialIcon href={globalsLocalized.contact.twitter} iconId={"FooterTwitter"} iconColor={iconColorSocialIconsDesktop} />}
                  {!!globalsLocalized.contact.email && true && <CSocialIcon href={`mailto:${globalsLocalized.contact.email}`} iconId={"FooterEmail"} iconColor={iconColorSocialIconsDesktop} />}
                </SocialIconsDesktopContainer>
              )}

              <LinksDesktopContainer {...d}>
                {!!currentPageLinkOtherLangs && (
                  <CCollapse collapsed={!langsExpanded} heightTransition={"0.2s ease"}>
                    <OtherLangsGroupDesktop {...d}>
                      {!!otherLocales &&
                        otherLocales.map(
                          locale =>
                            !!currentPageLinkOtherLangs[locale] && (
                              <Link
                                //
                                key={`lang-${locale}`}
                                style={{textDecoration: "none"}}
                                to={currentPageLinkOtherLangs[locale]}
                                onClick={() => this.onOtherLangClick(locale)}
                              >
                                <OtherLangButton {...d}>
                                  <span>{locale.toUpperCase()}</span>
                                </OtherLangButton>
                              </Link>
                            ),
                        )}
                    </OtherLangsGroupDesktop>
                  </CCollapse>
                )}

                <HierarchyGroup {...d}>
                  {menuItems.map(item => {
                    const {key, intlId, active, linkTo, subItems, hash} = item
                    const label = intl.formatMessage({id: intlId})
                    debby("renderMenuItem()", {key, label, linkTo, hash})
                    return (
                      <CTopLink
                        //
                        {...d}
                        key={`top_link_${key}`}
                        to={linkTo}
                        hash={hash}
                        id={key}
                        active={active}
                        label={label}
                        // onMouseEnter={!!subItems ? this.onItemWithSubItemsHover : this.onItemHover}
                        onClick={!!subItems ? this.onItemWithSubItemsClick : this.onItemClickDummy}
                      />
                    )
                  })}
                </HierarchyGroup>

                <HierarchyGroup debug={d.debug && !!submenuKey}>
                  {menuItems.map(item => {
                    if (!item.subItems || !item.subItems.length) {
                      return null
                    }
                    const subItemsWithLabels = item.subItems.map(subItem => ({...subItem, label: intl.formatMessage({id: subItem.intlId})}))
                    return (
                      <CBottomLinkRow
                        //
                        {...d}
                        topLinkId={item.key}
                        key={`subitem_${item.key}`}
                        shown={submenuKey == item.key}
                        items={subItemsWithLabels}
                        onClick={this.onSubItemClick}
                        onMouseEnter={this.onSubItemHover}
                      />
                    )
                  })}
                </HierarchyGroup>
              </LinksDesktopContainer>

              {/* Spacer */}
              <LinksMobilePlaceholder />

              {/* Language Selector */}
              <LangCurrentDesktop {...d} langsExpanded={langsExpanded}>
                <CurrentLangButton {...d} expanded={langsExpanded} onClick={this.onLangDropdownClick}>
                  <CurrentLangButtonLabel>
                    <span>{intlLocale.toUpperCase()}</span>
                  </CurrentLangButtonLabel>
                  <CurrentLangButtonCaret
                    //  props
                    {...d}
                    disabled={!currentPageLinkOtherLangs || !Object.keys(currentPageLinkOtherLangs).length}
                    variants={{normal: {rotate: 0, y: -2}, active: {rotate: 180, y: 2}}}
                    animate={langsExpanded ? "active" : "normal"}
                  >
                    <CIcon id={`HeaderMenuCaretDown`} color={theme.textColorActive} />
                  </CurrentLangButtonCaret>
                </CurrentLangButton>
              </LangCurrentDesktop>

              {/* Search */}
              {false && (
                <SearchContainer {...d} onClick={this.onSearchClick}>
                  <SearchIconContainerDesktop>
                    <CIcon id={`HeaderMenuSearch`} />
                  </SearchIconContainerDesktop>
                  <SearchIconContainerMobile>
                    <CIcon id={`MobileMenuSearch`} />
                  </SearchIconContainerMobile>
                </SearchContainer>
              )}

              {/* Mobile Hamburger */}
              <HamburgerContainer {...d} onClick={this.toggleMobileMenuVisible}>
                <CIcon id={`MobileMenuHamburger`} color={theme.textColorActive} />
              </HamburgerContainer>
            </ContContent>
          </Container>

          <MobileMenuContainer {...d} paddingVariant={"header"} shown={mobileMenuVisible}>
            <MobileMenuTop {...d} paddingVariant={"header"}>
              <MobileMenuXContainer {...d} onClick={this.toggleMobileMenuVisible}>
                <CIcon id={`MobileMenuClose`} color={"white"} />
              </MobileMenuXContainer>
            </MobileMenuTop>
            <MobileMenuScroll {...d}>
              {menuItems.map(item => {
                const {key, intlId, active, linkTo, subItems} = item // eslint-disable-line
                const hasSubItems = !!subItems && !!subItems.length
                const hasActiveSubItems = hasSubItems ? !!subItems.find(item => item.active) : false
                return (
                  <MobileMenuItem
                    //
                    {...d}
                    key={`mobile_item_${key}`}
                    onClick={() => this.onMobileMenuItemClick(key)}
                    active={active && !hasActiveSubItems}
                  >
                    <CLink
                      //
                      // to={!hasSubItems ? linkTo : null} // A
                      to={linkTo} // B
                      onClick={() => (hasSubItems ? this.onMobileLinkWithSubItemsClick(key, linkTo) : this.onMobileLinkWithoutSubItemsClick(key, linkTo))}
                      // preloadPostData={false}
                      color={"white"}
                    >
                      <MobileMenuMainItem {...d} active={active && !hasActiveSubItems}>
                        {intl.formatMessage({id: intlId})}
                      </MobileMenuMainItem>
                    </CLink>
                    {hasSubItems && (
                      <CCollapse
                        //
                        {...d}
                        // collapsed={mobileMenuItemKeyExpanded != key && !active} // A
                        collapsed={false && mobileMenuItemKeyExpanded} // B
                      >
                        <MobileMenuSubItemsContent {...d}>
                          {subItems.map(subItem => {
                            return (
                              <CLink
                                //
                                key={`sub_item_${key}_${subItem.key}`}
                                to={subItem.linkTo}
                                onClick={() => this.onMobileSubItemLinkClick(key, subItem.key)}
                                color={"white"}
                                // preloadPostData={false}
                              >
                                <MobileMenuSubItem active={subItem.active}>
                                  <span>{intl.formatMessage({id: subItem.intlId})}</span>
                                </MobileMenuSubItem>
                              </CLink>
                            )
                          })}
                        </MobileMenuSubItemsContent>
                      </CCollapse>
                    )}
                  </MobileMenuItem>
                )
              })}
            </MobileMenuScroll>
            <MobileMenuBottom {...d}>
              <SocialIconsMobileContainer {...d}>
                {!!globalsLocalized.contact.twitter && CONFIG.app.showTwitter && <CSocialIcon href={globalsLocalized.contact.twitter} iconId={"FooterTwitter"} iconColor={"#778080"} />}
                {!!globalsLocalized.contact.facebook && <CSocialIcon href={globalsLocalized.contact.facebook} iconId={"FooterFacebook"} iconColor={"#778080"} />}
                {!!globalsLocalized.contact.instagram && <CSocialIcon href={globalsLocalized.contact.instagram} iconId={"FooterInstagram"} iconColor={"#778080"} />}
                {!!globalsLocalized.contact.email && <CSocialIcon href={`mailto:${globalsLocalized.contact.email}`} iconId={"FooterEmail"} iconColor={"#778080"} />}
              </SocialIconsMobileContainer>
              <MobileBottomSeparator />
              <MobileLanguagesContainer {...d}>
                <MobileLangButton active>{intlLocale.toUpperCase()}</MobileLangButton>
                {!!currentPageLinkOtherLangs &&
                  !!otherLocales &&
                  otherLocales.map(
                    locale =>
                      !!currentPageLinkOtherLangs[locale] && (
                        <Link
                          //
                          key={`lang-${locale}`}
                          style={{textDecoration: "none"}}
                          to={currentPageLinkOtherLangs[locale]}
                          onClick={() => this.onOtherLangClick(locale)}
                        >
                          <MobileLangButton {...d}>
                            <span>{locale.toUpperCase()}</span>
                          </MobileLangButton>
                        </Link>
                      ),
                  )}
              </MobileLanguagesContainer>
            </MobileMenuBottom>
          </MobileMenuContainer>
        </ThemeProvider>
      </div>
    )
  }
}

const CSocialIcon = ({href, iconId, iconColor, variant = "desktop"}) => (
  <SocialIcon href={href} target={"_blank"} variant={variant}>
    <CIcon scale={0.9} id={iconId} color={iconColor} />
  </SocialIcon>
)
CSocialIcon.propTypes = {href: PropTypes.string, iconId: PropTypes.string, iconColor: PropTypes.string, variant: PropTypes.oneOf(["desktop", "mobile"])}
const SocialIcon = styled.a`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1px;
  ${props => css`
    ${props.theme.media.smdown} {
      margin: 0px 10px;
    }
  `}
`

const Container = styled.div`
  position: fixed;
  z-index: 10; // higher than CFixedSections
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.5s;

  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.paddingVariant)}

  padding-top: 20px;
  padding-bottom: 20px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `}

  ${selectors.getNoSelectCss()}

  border-bottom: 1px solid ${props => (props.scrolledDown ? "#CCC" : "none")};
  background-color: ${props => (props.scrolledDown ? "white" : props.filledWhenScrolledUp ? "rgba(0,0,0,0.3)" : "none")};

  transform: translate3d(0px, ${props => (props.visible ? 0 : -100)}px, 0px);

  ${props => true && selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.25)")}
`

const MobileMenuContainer = styled.div`
  position: fixed;
  z-index: 20000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${props => props.theme.vars.colors.codGray};
  pointer-events: ${props => (props.shown ? "auto" : "none")};
  opacity: ${props => (props.shown ? (props.debug ? 0.95 : 1) : 0)};
  // opacity: 1;
  transition: all 0.3s ease-in-out;
  color: white;
  display: flex;
  flex-direction: column;
`

const MobileMenuTop = styled.div`
  margin-right: -10px;
  height: 60px;
  ${props => selectors.getDebugOverlayCss(props, "MobileMenuTop", "rgba(0,0,255,0.15)")}
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.paddingVariant)}
`

const MobileMenuScroll = styled.div`
  flex: 1;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  text-align: right;
  padding: 30px 0px;
  ${props => selectors.getDebugOverlayCss(props, "MobileMenuScroll", "rgba(0,0,255,0.15)")}
`

const MobileMenuItem = styled.div`
  color: white;
  font-weight: ${props => props.theme.vars.fonts.weights.regular};
  padding: 20px 0px;
  letter-spacing: 0.1em;
  font-kerning: none;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  ${props => selectors.getDebugOverlayCss(props, "MobileMenuItem", "rgba(0,0,255,0.15)")}
`

const MobileMenuMainItem = styled.div`
  font-size: 20px;
  opacity: ${props => (props.active ? 1 : 0.7)};
  border-bottom: 1px solid ${props => (props.active ? "white" : "rgba(0,0,0,0)")};
  display: inline-block;
  ${props => selectors.getDebugOverlayCss(props, "MobileMenuMainItem", "rgba(0,0,255,0.15)")}
`

const MobileMenuSubItemsContent = styled.div`
  padding-top: 10px;
  ${props => selectors.getDebugOverlayCss(props, "MobileMenuSubItemsContent", "rgba(255,255,0,0.15)")}
`

const MobileMenuSubItem = styled.div`
  font-size: 20px;
  opacity: ${props => (props.active ? 1 : 0.5)};
  padding: 5px 20px 5px 0px;
`

const MobileMenuXContainer = styled.div`
  align-self: stretch;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.65)")}
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; // vertical
  ${props => false && selectors.getDebugOverlayCss(props, "ContContent", "rgba(0,0,255,0.35)")}
`

const LogoContainer = styled.div`
  align-self: stretch; // vertical
  padding-right: 20px;
  display: flex;
  align-items: center;
  ${props => selectors.getDebugOverlayCss(props, "LogoContainer", "rgba(0,0,255,0.35)")}
`
const LogoIconImg = styled.img`
  height: 55px;
  margin-right: 25px;
  ${props => css`
    ${props.theme.media.smdown} {
      height: 45px;
      margin-right: 15px;
    }
  `}
`

const LogoImage = styled.img`
  height: 30px;
  transtion: all 2s;
`

const SocialIconsDesktopContainer = styled.div`
  display: flex;
  ${props => css`
    ${props.theme.media.smdown} {
      display: none;
    }
  `}
  align-self: stretch;
  align-items: center;
`

const MobileMenuBottom = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center; // horizontal
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  ${props => selectors.getDebugOverlayCss(props, "MobileMenuBottom", "rgba(255,0,255,0.15)")}
`

const SocialIconsMobileContainer = styled.div`
  display: none;
  ${props => css`
    ${props.theme.media.smdown} {
      display: flex;
    }
  `}
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  margin-right: -10px;
  ${props => true && selectors.getDebugOverlayCss(props, "SocialIconsMobileContainer", "rgba(255,255,0,0.35)")}
`

const MobileBottomSeparator = styled.div`
  height: 1px;
  background-color: white;
  // margin: 0px 20px;
  align-self: stretch;
`

const MobileLanguagesContainer = styled.div`
  display: flex;
  align-self: stretch;
  padding: 10px 0px;
  align-items: center;
  justify-content: center;
  ${props => true && selectors.getDebugOverlayCss(props, "MobileLanguagesContainer", "rgba(255,0,0,0.15)")}

  * {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const MobileLangButton = styled.div`
  height: 40px;
  width: 40px;
  color: white;
  font-size: 13px;

  letter-spacing: 0.15em;
  font-kerning: none;
  opacity: ${props => (props.active ? 1 : 0.5)};
`

const LinksDesktopContainer = styled.div`
  flex: 1;
  align-self: stretch;

  display: flex;
  flex-direction: column;
  align-items: flex-end; // right
  justify-content: center;
  ${props => true && selectors.getDebugOverlayCss(props, "LinksDesktopContainer", "rgba(255,255,0,0.35)")}
  ${props => css`
    ${props.theme.media.smdown} {
      display: none;
    }
  `}
`

const LinksMobilePlaceholder = styled.div`
  flex: 1;
  align-self: stretch;
  display: none;
  ${props => css`
    ${props.theme.media.smdown} {
      display: block;
    }
  `}
`

const HierarchyGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  ${props => true && selectors.getDebugOverlayCss(props, "HierarchyGroup", "rgba(255,255,0,0.35)")}
`

class CTopLink extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    to: PropTypes.string,
    hash: PropTypes.string,
    id: PropTypes.string,
    active: PropTypes.bool,
    label: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onClick: PropTypes.func.isRequired,
  }
  static defaultProps = {
    debug: false && __DEV__,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onMouseEnter = () => !!this.props.onMouseEnter && this.props.onMouseEnter(this.props.id)
  onClick = () => !!this.props.onClick && this.props.onClick(this.props.id)

  render = () => {
    const {debug, to, active, label, hash} = this.props
    const d = {debug: debug || CTopLink.defaultProps.debug}
    debby("CTopLink.render()", {label, to, hash, active})
    return (
      <Link to={{pathname: to || "/", hash}} style={{textDecoration: "none", cursor: "pointer"}}>
        <TopLinkContainer {...d} active={active} onMouseEnter={this.onMouseEnter} onClick={this.onClick}>
          <TopLinkLabel active={active}>{label}</TopLinkLabel>
        </TopLinkContainer>
      </Link>
    )
  }
}

const TOP_ITEM_HEIGHT = 40 // px
const BOTTOM_ITEM_HEIGHT = 40

const MixinTopBottomLinkPaddingFont = props => `
  letter-spacing: 0.1em;
  font-kerning: none;

  padding: 0px 30px;
  font-size: 13px;
  ${props.theme.media.lg} {
    padding: 0px 20px;
    font-size: 13px;
  }
  ${props.theme.media.md} {
    padding: 0px 5px;
    font-size: 12px;
    opacity: 0.5;
  }
  ${props.theme.media.smdown} {
    padding: 0px 5px;
    font-size: 12px;
    opacity: 0.2;
  }
`

//  prettier-ignore
const TopLinkContainer = styled.div `
  height: ${TOP_ITEM_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${MixinTopBottomLinkPaddingFont}

  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.65)")}
  margin-left: 8px;

  ${props => selectors.getDebugOverlayCss(props, "TopLinkContainer", "rgba(255,255,0,0.35)")}
  transition: color 1.0s;
  color: ${props => props.active ? props.theme.textColorActive : props.theme.textColorInactive};
`

const TopLinkLabel = styled.div`
  padding: 6px 0px;
  border-bottom: 1px solid ${props => (props.active ? props.theme.textColorActive : "rgba(0,0,0,0)")};
  // text-shadow: 0px 0px 3px #000000;
`

class CBottomLinkRow extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    topLinkId: PropTypes.string.isRequired,
    intl: PropTypes.object,
    items: PropTypes.array,
    shown: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onMouseEnter = () => !!this.props.onMouseEnter && this.props.onMouseEnter()

  render = () => {
    const {debug, shown, items} = this.props
    const d = {debug}
    return (
      <CCollapse key={``} collapsed={!shown} heightTransition={"0.2s ease"}>
        <BottomLinkRowGroup onMouseEnter={this.onMouseEnter}>
          {items.map(subItem => (
            <CLink
              //
              // preloadPostData={false}
              color={"black"}
              key={`sub-item-${subItem.key}`}
              style={{textDecoration: "none"}}
              to={subItem.linkTo}
            >
              <BottomLinkContainer {...d} active={subItem.active}>
                <BottomLinkLabel {...d} active={subItem.active}>
                  {subItem.label}
                </BottomLinkLabel>
              </BottomLinkContainer>
            </CLink>
          ))}
        </BottomLinkRowGroup>
      </CCollapse>
    )
  }
}
const BottomLinkRowGroup = styled.div`
  display: flex;
`

const BottomLinkContainer = styled.div`
  height: ${BOTTOM_ITEM_HEIGHT}px;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  // opacity: ${props => (props.active ? 1 : 0.5)};
  ${MixinTopBottomLinkPaddingFont}
  ${props => selectors.getDebugOverlayCss(props, "BottomLinkContainer", "rgba(0,255,255,0.35)")}
`

const BottomLinkLabel = styled.div`
  padding: 6px 0px;
  border-bottom: 1px solid ${props => (props.active ? props.theme.textColorActive : "rgba(0,0,0,0)")};
  color: ${props => (props.active ? props.theme.textColorActive : props.theme.textColorInactive)};
  transition: border-bottom 1s, color 1s;
`

const LangCurrentDesktop = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  transition: all 0.5s;
  // justify-content: ${props => (props.langsExpanded ? "flex-end" : "center")};
  // justify-content: flex-start;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0);

  ${props => css`
    ${props.theme.media.smdown} {
      display: none;
    }
  `}
  ${props => selectors.getDebugOverlayCss(props, "LangCurrentDesktop", "rgba(255,255,0,0.35)")}
`

const CurrentLangButton = styled.div`
  width: 100%;
  height: ${TOP_ITEM_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,0,0.35)")}
  cursor: pointer;
`

const CurrentLangButtonLabel = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: ${props => props.theme.textColorActive};
  letter-spacing: 0.2em;
  padding-right: 10px;
  padding-left: 10px;
`

//  prettier-ignore
const CurrentLangButtonCaret = styled(motion.div)`
  width: 12px; // size for CIcon
  margin-right: 10px;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,255,0,0.75)")}
  ${props => props.disabled && css `
    pointer-events: none;
    opacity: 0.5;
  `}
`

const OtherLangsGroupDesktop = styled.div`
  height: ${TOP_ITEM_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${props => css`
    ${props.theme.media.smdown} {
      display: none;
    }
  `}
  ${props => selectors.getDebugOverlayCss(props, "OtherLangsGroupDesktop", "rgba(255,255,0,0.35)")}
`

const OtherLangButton = styled.div`
  font-size: 10px;
  font-weight: 600;
  width: ${TOP_ITEM_HEIGHT}px;
  height: ${TOP_ITEM_HEIGHT}px;
  margin: 0px 3px;
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.textColorActive};
  letter-spacing: 0.2em;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,255,0.35)")}
`

const SearchContainer = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,255,0.35)")}
  ${props => selectors.getDebugOverlayCss(props, "SearchContainer", "rgba(255,255,0,0.35)")}

  margin-left: 10px;
  cursor: pointer;

  // margin-right: -10px; // move to edge
`

const SearchIconContainerDesktop = styled.div`
  width: 15px;
  ${props => selectors.getDebugOverlayCss(props, "SearchIconContainerDesktop", "rgba(255,255,0,0.35)")}
  ${props => css`
    ${props.theme.media.smdown} {
      display: none;
    }
  `}
`

const SearchIconContainerMobile = styled.div`
  width: 20px;
  ${props => selectors.getDebugOverlayCss(props, "SearchIconContainerMobile", "rgba(255,255,0,0.35)")}
  display: none;
  ${props => css`
    ${props.theme.media.smdown} {
      display: block;
    }
  `}
`

const HamburgerContainer = styled.div`
  align-self: stretch;
  display: none;
  ${props => css`
    ${props.theme.media.smdown} {
      display: flex;
    }
  `}
  align-items: center;
  justify-content: center;
  width: 40px;
  margin-right: -10px;
  margin-left: 10px;
  ${props => selectors.getDebugOverlayCss(props, "HamburgerContainer", "rgba(255,255,0,0.35)")}
  cursor: pointer;
`

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const getPropsLocationPathname = (state, props) => props.locationPathname

const getMenuItems = () => {
  // prettier-ignore
  return [
    {key: "app",            intlId: `HeaderMenuApp`,                            linkTo: "/app", active: true}, // either linkTo, or subItems
    {key: "portfolio",      intlId: `HeaderMenuPortfolio`,                      linkTo: "/portfolio", subItems: [
      {key: "art-galleries",      intlId: `HeaderMenuPortfolioArtGalleries`,    linkTo: "/portfolio/art-galleries"},
      {key: "art-institutions",   intlId: `HeaderMenuPortfolioArtInstitutions`, linkTo: "/portfolio/art-institutions"},
      {key: "artists",            intlId: `HeaderMenuPortfolioArtists`,         linkTo: "/portfolio/artists"},
      // {key: "testimonials",     intlId: `HeaderMenuPortfolioTestimonials`, linkTo: "/portfolio/testimonials"},
    ]},
    {key: "ovr",            intlId: `HeaderMenuOVR`,                            linkTo: `/ovr`},
    {key: "knowledgebase",  intlId: `HeaderMenuKnowledgeBase`,                  linkTo: "/docs"},
    {key: "about-us",       intlId: `HeaderMenuAbout`,                          linkTo: "/about-us"},
    {key: "contact",        intlId: `HeaderMenuContact`,                        linkTo: "/contact"},
  ]
}

const getMenuItemsV3 = () => {
  // prettier-ignore
  return [
                                    {key: "app",    intlId: `HeaderMenuWhyIazzu`,   linkTo: "/app"},
                                    {key: "ovr",    intlId: `HeaderMenuExhibitors`, linkTo: `/ovr`},
    ...CONFIG.app.showMenuEvents ? [{key: "events", intlId: `HeaderMenuEvents`,     linkTo: `/events`}] : [],
    ...CONFIG.app.showMenuBlog   ? [{key: "blog",   intlId: `HeaderMenuBlog`,       linkTo: `/magazine`}] : [],
  ]
}

const getMenuItemsUmbrella = () => {
  // prettier-ignore
  return [
    {key: "artlovers",      intlId: `HeaderMenuUmbrellaArtLovers`,              linkTo: "/art-lovers"},
    {key: "exhibitors",     intlId: `HeaderMenuUmbrellaExhibitors`,             linkTo: "/exhibitors"},
    {key: "about-us",       intlId: `HeaderMenuAbout`,                          linkTo: "/about-us"},
  ]
}

const getMenuItemsExhibitors = () => {
  // prettier-ignore
  return [
    {key: "app",            intlId: `HeaderMenuUmbrellaOverview`,               linkTo: "/"},
    {key: "knowledgebase",  intlId: `HeaderMenuKnowledgeBase`,                  linkTo: "/docs"},
    {key: "contact",        intlId: `HeaderMenuContact`,                        linkTo: "/contact", hash: "#form"},
  ]
}

const getMenuItemsArtLovers = () => {
  // prettier-ignore
  return [
    {key: "app",            intlId: `HeaderMenuUmbrellaOverview`,               linkTo: "/"},
    {key: "ovr",            intlId: `HeaderMenuOVR`,                            linkTo: `/ovr`},
    {key: "knowledgebase",  intlId: `HeaderMenuKnowledgeBase`,                  linkTo: "/docs"},
    {key: "contact",        intlId: `HeaderMenuContact`,                        linkTo: "/contact", hash: "#form"},
  ]
}

const getMenuItemsWithActive = createSelector(
  //
  [selectors.getIntlLocale, getMenuItems, getMenuItemsV3, getMenuItemsUmbrella, getMenuItemsExhibitors, getMenuItemsArtLovers, getPropsLocationPathname, selectors.getSitemapData, selectors.getBlogPathLocalized, selectors.getEventsPathLocalized],
  (intlLocale, menuItems, menuItemsV3, menuItemsUmbrella, menuItemsExhibitors, menuItemsArtLovers, locationPathname, sitemapData, blogPathLocalized, eventsPathLocalized) => {
    const actPath = selectors.getPathnameLocalized(locationPathname, "en", sitemapData)

    let menuItemsUsed = menuItemsV3

    if (blogPathLocalized) {
      // debugger
    }

    const items = menuItemsUsed
      .map(item => {
        let active = false
        switch (item.key) {
          case "app":
            active = actPath == "/app"
            break
          case "ovr":
            active = actPath.indexOf("/ovr") == 0 || actPath.indexOf("/g/") != -1
            break
          case "portfolio":
            active = actPath.indexOf("/portfolio") == 0
            break
          case "events":
            active = actPath.indexOf("/events") == 0 || actPath.indexOf(eventsPathLocalized) == 0
            break
          case "blog":
            // active = actPath.indexOf(blogPathLocalized || "..") == 0
            active = actPath.indexOf("/magazine") == 0 || actPath.indexOf(blogPathLocalized) == 0
            break
          case "about-us":
            active = actPath.indexOf("/about-us") == 0
            break
          case "contact":
            active = actPath.indexOf("/contact") == 0
            break
        }

        if (!!item.subItems && !!item.subItems.length) {
          item.subItems = item.subItems.map(subItem => {
            let subActive = false
            switch (subItem.key) {
              case "art-galleries":
                subActive = actPath == "/portfolio/art-galleries"
                break
              case "art-institutions":
                subActive = actPath == "/portfolio/art-institutions"
                break
              case "artists":
                subActive = actPath == "/portfolio/artists"
                break
            }
            return {...subItem, active: subActive}
          })
        }
        return {...item, active}
      })
      .filter(i => !!i)

    return items
  },
)

const getMenuItemsLocalized = createSelector(
  //
  [selectors.getIntlLocale, getMenuItemsWithActive, getPropsLocationPathname, selectors.getSitemapData],
  (intlLocale, menuItems, locationPathname, sitemapData) =>
    menuItems
      .map(item => {
        let menuItem = {...item}
        if (!!menuItem.linkTo) {
          menuItem.linkTo = selectors.getPathnameLocalized(menuItem.linkTo, intlLocale, sitemapData)
        }
        if (!!menuItem.subItems) {
          menuItem.subItems = menuItem.subItems.map(subItem => ({
            ...subItem,
            linkTo: selectors.getPathnameLocalized(subItem.linkTo, intlLocale, sitemapData),
          }))
        }
        return menuItem
      })
      .filter(i => !!i),
)

/*
const getShowLinkedInIcon = createSelector(
  //
  [getPropsLocationPathname, selectors.getSitemapData],
  (locationPathname, sitemapData) => {
    const actPath = selectors.getPathnameLocalized(locationPathname, "en", sitemapData)
    switch (actPath) {
      case "/exhibitors":
      case "/art-lovers":
      case "/":
        return true
    }
    return false
  },
)
*/

const mapStateToProps = (state, props) => ({
  waypointThemeKey: selectors.getSessionWaypointThemeKey(state),
  menuItems: getMenuItemsLocalized(state, props),
  intlLocale: selectors.getIntlLocale(state),
  otherLocales: selectors.getOtherLocales(state),
  globalsLocalized: selectors.getGlobalsLocalized(state),
  // showLinkedInIcon: getShowLinkedInIcon(state, props),
  showLinkedInIcon: false,
})
export default injectIntl(connect(mapStateToProps)(CHeaderMenu))
