import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import * as selectors from "../../../data/selectors"
import withScreenProps from "../../../data/hocs/withScreenProps" // {postData, pageTemplate, ..}

import CBlogsList from "./CBlogsList"
import CSpacer from "../../../view/components/CSpacer"
import CFooter from "../../../view/components/CFooter"
import CEditInWordPress from "../../../view/components/CEditInWordPress"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SBlog", ...args)

const god = selectors.getObjectDeep

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class SBlog extends Component {
  static propTypes = {
    stageWidth: PropTypes.number,
    sitemapData: PropTypes.array,

    //  withScreenProps
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    themeKey: PropTypes.string,
    postData: PropTypes.object,
    wordPressUserId: PropTypes.number,
    editInWordPressProps: PropTypes.object,
    pageTemplate: PropTypes.string,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
    locationPathname: PropTypes.string,
  }
  static defaultProps = {
    defaultThemeProps: {
      backgroundColor: "white",
      textColor: "black",
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      availWidthFeatureMatrix: undefined,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {intlLocale, intl, defaultThemeProps, mediaQueryClass, wordPressUserId, postData, locationPathname, editInWordPressProps} = this.props // eslint-disable-line
    const d = {debug: DEBUG}
    const themeOverrides = {}
    debby("render()", {mediaQueryClass, wordPressUserId})
    // if (postData) debugger
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <Container {...d}>
          <CSpacer variant={"headermenu"} />
          {true && (
            <CBlogsList
              //
              headerText={god(postData, "sectionBlog.header")}
              descriptionText={god(postData, "sectionBlog.description")}
              items={god(postData, "sectionBlog.items")}
              intlLocale={intlLocale}
            />
          )}
        </Container>
        <CFooter locationPathname={locationPathname} />
        <CEditInWordPress fixed {...editInWordPressProps} />
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  overflow-x: hidden; // because of huge images
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  padding-bottom: 50px;
`

const mapStateToProps = state => ({
  stageWidth: state.device.stageDimensions.width,
  sitemapData: selectors.getSitemapData(state),
})
export default withScreenProps(connect(mapStateToProps)(SBlog))
