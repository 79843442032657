import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {injectIntl} from "react-intl"
import styled, {css} from "styled-components"
import {createSelector} from "reselect"
import FlipMove from "react-flip-move"

import * as selectors from "../../../data/selectors"

import CLink from "../../../view/components/CLink"
import CImage from "../../../view/components/CImage"
import CH3 from "../../../view/components/text/CH3"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CBlogsList", ...args)

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class CBlogsList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    debug: PropTypes.bool,
    blogPathLocalized: PropTypes.string,
    // Post Data
    headerText: PropTypes.string,
    descriptionText: PropTypes.string,
    items: PropTypes.array,
    // Connect
    blogsFinal: PropTypes.array,
    intlLocale: PropTypes.string,
    // mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
  }
  static defaultProps = {
    debug: DEBUG,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {intlLocale, headerText, descriptionText, blogsFinal, blogPathLocalized} = this.props
    const d = {debug: DEBUG || this.props.debug}
    debby("render()", {amountEvents: blogsFinal ? blogsFinal.length : ".."})

    return (
      <Container {...d} {...d}>
        <HeaderContainer {...d}>
          <H3Container {...d}>
            <CH3 text={headerText} noWordWrap />
          </H3Container>
          {false && (
            <HeaderFilterContainer
              //
              {...d}
              onClick={this.toggleFilters}
              // hasSettings={sortBy != SORT_BY_DEFAULT || galleriesFinal.length != galleries.length}
              hasSettings
            >
              Filter Container
            </HeaderFilterContainer>
          )}
          <SubHeader {...d}>{descriptionText}</SubHeader>
        </HeaderContainer>

        <ItemsContainer {...d}>
          <FlipMove
            //
            staggerDurationBy={"50"}
            duration={500}
            typeName={"div"}
            key={`flipmove`}
          >
            {!!blogsFinal &&
              !!blogPathLocalized &&
              !!blogsFinal.length &&
              blogsFinal.map((blog, i) => {
                // data structure see https://iazzu.com/api/postdata?path=/blogs
                // debugger
                const {title, slug, slugs, created} = blog
                const src1000 = god(blog, "image.sizes.u1000.url") // eslint-disable-line

                const linkTo = `${blogPathLocalized}/${slugs[intlLocale]}`
                // const locationReadable = showLocation && !!location ? location : ""
                return (
                  <SingleItem {...d} key={`event_${slug}`}>
                    <CLink to={linkTo} preloadDelayMs={i * 300} stretched preloadPostData={!DEBUG}>
                      <SinglePaddedItem {...d}>
                        <CImage
                          //
                          src={src1000}
                          ratio={365 / 300}
                          // ratio={365 / 250}
                          // ratio={16 / 9}
                          ratiosBreakpoints={{
                            //
                            md: 16 / 9,
                            sm: 16 / 9,
                            xs: 16 / 9,
                          }}
                          borderColor={"#EEE"} // pure-white images
                        />
                        <ItemTitle>{title}</ItemTitle>
                        <ItemSubTitle>{created}</ItemSubTitle>
                      </SinglePaddedItem>
                    </CLink>
                  </SingleItem>
                )
              })}
          </FlipMove>
        </ItemsContainer>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column; // so it's vertical, for spacer
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.25)")}

  padding-top: 100px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 50px;
    }
  `}
`

const HeaderContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "HeaderContainer", "rgba(0,255,255,0.35)")};
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  // display: flex;
  padding-bottom: 30px;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
      padding-bottom: 10px;
    }
  `}
`

const SubHeader = styled.p`
  text-align: left;
  margin-block-start: 0;
  margin-block-end: 0;
  border: ${props => (props.debug ? 1 : 0)}px solid green;
  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `SubHeader`, `rgba(0,0,255,0.15)`)}

  padding-top: 40px;
  color: #1e1e1e;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 2px;
  ${props => css`
    ${props.theme.media.md} {
      padding-top: 20px;
      padding-bottom: 60px;
    }
    ${props.theme.media.smdown} {
      color: #282828;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 1.8px;
    }
  `}
  margin-bottom: 0px;
`

const H3Container = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
  ${props => selectors.getDebugOverlayCss(props, "H3Container", "rgba(0,0,255,0.35)")};
`

const HeaderFilterContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.3s;
  ${props =>
    props.hasSettings &&
    css`
      opacity: 1;
    `}
  ${props => selectors.getDebugOverlayCss(props, "FBC", "rgba(255,0,255,0.35)")};
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 10px;
      justify-content: flex-end;
    }
  `}
`

const GALLERIES_GUTTER_PX = 20

const ItemsContainer = styled.div`
  padding-top: 10px;
  position: relative;
  margin: 0px -${GALLERIES_GUTTER_PX}px;
  background-color: white;
  text-align: center;
  ${props => selectors.getDebugOverlayCss(props, "ItemsContainer", "rgba(255,0,0,0.35)")};
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
`

const SingleItem = styled.div`
  display: inline-block;
  vertical-align: top; // remove space below
  text-align: left;
  cursor: pointer !important;

  width: 32.7%;
  padding-bottom: 30px;
  ${props => css`
    ${props.theme.media.md} {
      width: 50%;
      padding-bottom: 20px;
    }
  `}
  ${props => css`
    ${props.theme.media.smdown} {
      width: 100%;
      padding-bottom: 20px;
    }
  `}
`

const SinglePaddedItem = styled.div`
  padding: ${GALLERIES_GUTTER_PX / 2}px;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
`

const ItemTitle = styled.div`
  padding-top: 15px;
  letter-spacing: 0.06em;
  font-size: 17px;
  line-height: 1.3;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  color: ${props => props.theme.vars.colors.codGray} !important;
`

const ItemSubTitle = styled.div`
  padding-top: 5px;
  letter-spacing: 0.08em;
  font-size: 14px;
  line-height: 1.3;
  color: ${props => props.theme.vars.colors.codGray} !important;
  opacity: 0.7;
  // font-weight: ${props => props.theme.vars.fonts.weights.bold};
`

const getPropsItems = (state, props) => props.items

const getBlogsFinal = createSelector([getPropsItems], items => {
  return items
})

const mapStateToProps = (state, props) => ({
  queryProps: selectors.getQueryProps(state, props),
  blogsFinal: getBlogsFinal(state, props),
  mediaQueryClass: selectors.getMediaQueryClass(state),
  blogPathLocalized: selectors.getBlogPathLocalized(state, props),
})

export default injectIntl(withRouter(connect(mapStateToProps)(CBlogsList)))
