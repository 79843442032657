import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components"

import * as selectors from "../../data/selectors"

import CImage from "../../view/components/CImage"
import CLink from "../../view/components/CLink"
import CIcon from "../../view/components/CIcon"
import CTestimonialButton from "../../view/components/CTestimonialButton"

import CEditInWordPress from "../../view/components/CEditInWordPress"

const debby = (funcName, data = null) => console.log("CPerson." + funcName, data) // eslint-disable-line

class CPerson extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    id: PropTypes.number,
    featuredImage: PropTypes.object,
    lineBelowImage: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
    textExtraMargin: PropTypes.number,
    links: PropTypes.array, // {type, url}
    linkTypesAvailable: PropTypes.array, // if not set, first available link will be used
    type: PropTypes.oneOf(["default", "advisoryBoard", "ambassador", "collaborator"]).isRequired,
    advisoryBoardRoles: PropTypes.array,
    showEditButton: PropTypes.bool,
    // Ambassadors
    roles: PropTypes.array, // ambassadors
    requestAccessData: PropTypes.shape({
      branchUrl: PropTypes.string,
      qrCodeUrl: PropTypes.string,
      label: PropTypes.string,
    }),
  }
  static defaultProps = {
    debug: false && __DEV__,
    textExtraMargin: 50,
    type: "default",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, id, featuredImage, lineBelowImage, role, email, textExtraMargin, links, linkTypesAvailable, type, advisoryBoardRoles, showEditButton} = this.props
    const {requestAccessData} = this.props // Ambassadors
    const isAdvisor = type == "advisoryBoard"
    const d = {debug: debug || CPerson.defaultProps.debug}
    const displayName = selectors.getPersonDisplayName(this.props)
    const imageData = selectors.getSrcAndSrcSetByWordpressImage(featuredImage)
    let roles = isAdvisor ? advisoryBoardRoles : null
    if (type == "ambassador") {
      roles = this.props.roles
      // debugger
      // check firstLink
    }
    const firstLink = selectors.getFirstAvailablePersonLink(links, linkTypesAvailable) // {type, url}
    const iconId = type !== "collaborator" ? "PersonLinkedin" : "PersonWeb"
    debby("render()", {type, iconId, displayName})
    return (
      <TeamMemberCard {...d}>
        <TeamMemberCardContent {...d}>
          <TeamMemberImageContainer {...d}>
            <CLink url={!!firstLink ? firstLink.url : null} stretched>
              <CImage
                //
                src={imageData.src}
                srcSet={imageData.srcSet}
                ratio={1}
                rounded
              >
                {!!firstLink && (
                  <ImageOverlayContainer>
                    <ImageIconContainer>
                      <CIcon id={iconId} color={"white"} />
                    </ImageIconContainer>
                  </ImageOverlayContainer>
                )}
              </CImage>
            </CLink>
          </TeamMemberImageContainer>
          <TeamMemberTextContainer {...d} extraMargin={textExtraMargin}>
            {!!lineBelowImage && <TeamMemberSubLabel {...d}>{lineBelowImage}</TeamMemberSubLabel>}
            {!!displayName && <TeamMemberName {...d}>{displayName}</TeamMemberName>}
            {!isAdvisor && !!role && <TeamMemberRole {...d}>{role}</TeamMemberRole>}
            {!isAdvisor && !!email && (
              <TeamMemberEmail {...d}>
                <CLink url={`mailto:${email}`}>{email}</CLink>
              </TeamMemberEmail>
            )}
            {!!roles &&
              !!roles.length &&
              roles.map((role, i) => (
                <TeamMemberRole {...d} key={`role_${i}`}>
                  {role}
                </TeamMemberRole>
              ))}
          </TeamMemberTextContainer>
          {!!requestAccessData && (
            <TeamMemberRequestAccessContainer {...d}>
              <CTestimonialButton
                //
                {...d}
                iosOrAndroid={selectors.getIsIosOrAndroid()}
                behaviour={"gallery"} // if undefined or "none": don't draw
                gallery={requestAccessData}
                label={requestAccessData.label}
                qrCodeLabel={requestAccessData.qrCodeLabel}
                // qrCodeLabel={intl.formatMessage({id: `TestimonialQrCodeLabel`})}
              />
            </TeamMemberRequestAccessContainer>
          )}
        </TeamMemberCardContent>
        {showEditButton && !!id && <CEditInWordPress resourceId={id} label={"EDIT"} />}
      </TeamMemberCard>
    )
  }
}

const TeamMemberCard = styled.div`
  flex: 1;
  text-align: center;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "TeamMemberCard", "rgba(255,0,255,0.15)")};
  color: ${props => props.theme.vars.colors.codGray};
`

const TeamMemberCardContent = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`

const TeamMemberImageContainer = styled.div`
  width: 100%;
  max-width: 300px;
  position: relative;
  overflow: hidden;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "TeamMemberImageContainer", "rgba(0,0,255,0.15)")};
  margin-bottom: 10px;

  border: 1px solid #ccc;
  border-radius: 1000px;

  ${props =>
    css`
      ${props.theme.media.smdown} {
        max-width: 200px;
      }
    `}
`

const TeamMemberTextContainer = styled.div`
  margin: 0px -${props => props.extraMargin}px;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "TeamMemberTextContainer", "rgba(0,255,0,0.15)")};

  ${props =>
    css`
      ${props.theme.media.smdown} {
        margin: 0px 0px;
      }
    `}
`

const TeamMemberRequestAccessContainer = styled.div`
  ${props => props.debug && selectors.getDebugOverlayCss(props, "TeamMemberRequestAccessContainer", "rgba(0,0,255,0.15)")};
`

const TeamMemberSubLabel = styled.div`
  font-size: 15px;
  padding: 10px;
  line-height: 1.5;
  letter-spacing: 0.1em;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "TeamMemberSubLabel", "rgba(0,0,255,0.15)")};
`

const TeamMemberName = styled.div`
  ${props => selectors.getPersonNameFontCss(props)}
  padding: 10px;
  // padding-top: 20px;
  align-self: stretch;
  text-align: center;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "TeamMemberName", "rgba(0,255,255,0.15)")};
`

const TeamMemberRole = styled.div`
  font-size: 13px;
  letter-spacing: 0.1em;
  padding: 5px;
  align-self: stretch;
  letter-spacing: 0.1em;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "TeamMemberRole", "rgba(255,0,0,0.15)")};
`

const TeamMemberEmail = styled.div`
  font-size: 11px;
  letter-spacing: 0.1em;
  padding: 5px 2px 2px 2px;
  align-self: stretch;
  // border-bottom: 1px solid black;
  display: inline-block;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "TeamMemberEmail", "rgba(0,255,0,0.15)")};

  opacity: 0.9;
  :hover {
    opacity: 1;
  }
  transition: all 0.3s ease-in-out;
`

const ImageOverlayContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 1000px;
  border: 1px solid #ddd;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  ${TeamMemberImageContainer}:hover & {
    opacity: 1;
  }
  transition: all 0.3s ease-in-out;
`

const ImageIconContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;

  transform: translate3d(0px, 10px, 0px);
  ${TeamMemberImageContainer}:hover & {
    transform: translate3d(0px, 0px, 0px);
  }
  transition: all 0.3s ease-in-out;
`

export default CPerson
