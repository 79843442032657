import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import {Link} from "react-router-dom"
import styled, {css, ThemeProvider} from "styled-components" // eslint-disable-line

import * as selectors from "../../data/selectors"
import * as actions from "../../data/actions"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CLink", ...args)

class CLink extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.any,

    to: PropTypes.string,
    hash: PropTypes.string,
    // .. or ..
    url: PropTypes.string,
    search: PropTypes.string,

    color: PropTypes.string,
    keepUnderline: PropTypes.bool,
    localize: PropTypes.bool,
    linkTo: PropTypes.string,
    onClick: PropTypes.func,
    stretched: PropTypes.bool,
    openInNewWindow: PropTypes.bool, // for embedded mode
    preloadPostData: PropTypes.bool,
    preloadDelayMs: PropTypes.number,
  }
  static defaultProps = {
    debug: false && __DEV__,
    color: null,
    localize: true,
    search: "",
    preloadPostData: true,
    preloadDelayMs: 0,
    stretched: false,
  }

  componentDidMount = () => {
    this.handleProps(this.props, true)
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  UNSAFE_componentWillReceiveProps = nextProps => {
    this.handleProps(nextProps, false)
  }

  componentWillUnmount = () => {
    clearTimeout(this.preloadPathTimeoutId)
  }

  handleProps = (nextProps, initial = false) => {
    if (nextProps.preloadPostData && !!nextProps.linkTo && (initial || nextProps.linkTo != this.props.linkTo)) {
      this.preloadPath(nextProps.linkTo)
    }
  }

  preloadPath = path => {
    if (path && path.toLowerCase().indexOf("mailto:") !== -1) {
      return
    }

    this.preloadPathTimeoutId = setTimeout(() => {
      this.props.dispatch(actions.requestPostData(path, {fromPreload: true}))
    }, 3000 + this.props.preloadDelayMs)
  }

  onStyledAClick = event => {
    if (this.props.debug) debugger

    event.preventDefault() // always
    this.props.dispatch(actions.handleUrl(selectors.getHrefByClickEvent(event)))
    !!this.props.onClick && this.props.onClick()
  }

  onLinkClick = event => {
    event.preventDefault() // always
    const {debug, openInNewWindow, search, hash} = this.props
    const href = selectors.getHrefByClickEvent(event)
    if (debug) { debugger } // prettier-ignore
    this.props.dispatch(actions.handleUrl(href, {debug, search, hash, openInNewWindow}))
    !!this.props.onClick && this.props.onClick()
  }

  onBlankClick = event => {
    event.preventDefault() // always
    !!this.props.onClick && this.props.onClick()
  }

  render = () => {
    const {debug, to, localize, color, keepUnderline, linkTo, url, children, stretched, search, hash} = this.props
    let linkStyle = {backgroundColor: debug ? "blue" : "transparent", display: "table"}
    if (!!color) {
      linkStyle = {...linkStyle, cursor: "pointer", color}
    }
    if (!keepUnderline) {
      linkStyle = {...linkStyle, cursor: "pointer", textDecoration: "none"}
    }
    if (stretched) {
      linkStyle = {...linkStyle, width: "100%"}
    }
    debug && debby("render()", {to, localize, linkTo, url, search, hash})
    if (!!linkTo) {
      return (
        <Link
          //
          style={{...linkStyle}}
          to={{pathname: linkTo + search, hash}}
          onClick={this.onLinkClick}
        >
          {children}
        </Link>
      )
    } else if (!!url) {
      return (
        <StyledA stretched={stretched} debug={debug} href={url + search} onClick={this.onStyledAClick} keepUnderline={keepUnderline}>
          {children}
        </StyledA>
      )
    }
    return (
      <div
        //
        // style={linkStyle}
        onClick={this.onBlankClick}
      >
        {children}
      </div>
    )
  }
}

const StyledA = styled.a`
  color: inherit;
  cursor: pointer;

  ${props =>
    props.debug &&
    css`
      background-color: red !important;
    `}

  ${props =>
    !props.keepUnderline &&
    css`
      text-decoration: inherit;
    `}

  ${props =>
    props.stretched &&
    css`
      width: 100%;
      text-align: center;
    `}
  ${props =>
    !props.stretched &&
    css`
      display: table;
    `}
`

const getPropsTo = (state, props) => props.to
const getPropsDebug = (state, props) => props.debug === true
const getPropsLocalize = (state, props) => props.localize || CLink.defaultProps.localize

const getLinkTo = createSelector(
  //
  [getPropsLocalize, getPropsTo, selectors.getIntlLocale, selectors.getSitemapData, getPropsDebug],
  (propsLocalize, propsTo, intlLocale, sitemapData, debug) => {
    return propsLocalize ? selectors.getPathnameLocalized(propsTo, intlLocale, sitemapData, debug) : propsTo
  },
)

const mapStateToProps = (state, props) => ({
  linkTo: getLinkTo(state, props),
})
export default injectIntl(connect(mapStateToProps)(CLink))
