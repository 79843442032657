import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css, ThemeProvider} from "styled-components"
import {Parallax} from "react-scroll-parallax"

import * as selectors from "../../../data/selectors"

import CButton from "../../../view/components/CButton"
import CSpacer from "../../../view/components/CSpacer"
import CImageFaded from "../../../view/components/CImageFaded"

import CHomeSubheader from "../../../view/components/text/CHomeSubheader"
import CH2 from "../../../view/components/text/CH2"

const DEBUG = false && __DEV__
import {debbify} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("CHomeTailorMade", ...args)

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// https://iazzu.com/app
class CHomeTailorMade extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    header: PropTypes.string,
    subheader: PropTypes.string,
    button: PropTypes.string,
    buttonAlt: PropTypes.string,
    themeKey: PropTypes.string,
    defaultThemeProps: PropTypes.object,
    fadedImages: PropTypes.array,
  }
  static defaultProps = {
    debug: DEBUG,
    defaultThemeProps: {
      // backgroundColor: "white",
      textColor: "black",
    },
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {themeKey, defaultThemeProps, header, subheader, fadedImages} = this.props
    const d = {debug: DEBUG || this.props.debug}
    const parallaxRatio = 0.1
    let themeOverrides = {}
    if (themeKey === "dark") {
      themeOverrides = {
        ...themeOverrides,
        // backgroundColor: CONFIG.layout.colors.codGray,
        textColor: "white",
      }
    }
    const buttonLabel = !!this.props.button ? this.props.button : this.props.buttonAlt

    debby("render()", {fadedImages})
    return (
      <ThemeProvider theme={{...defaultThemeProps, ...themeOverrides}}>
        <PaddedContainer {...d} variant={"content"} {...d}>
          <ContentText {...d}>
            <ContentTextPadded {...d}>
              <CH2 {...d} text={header} />
              <CSpacer {...d} variant={"herosections"} />
              <CHomeSubheader {...d} text={subheader} />
              <CSpacer {...d} variant={"herosections"} />
              <CButton {...d} label={buttonLabel} linkTo={"/portfolio"} />
            </ContentTextPadded>
          </ContentText>
          <ContentImage {...d}>
            <ImageFadedResponsive {...d}>
              <Parallax y={[`-${parallaxRatio * 100}%`, `${parallaxRatio * 100}%`]} styleOuter={{display: "flex", width: "100%", height: "100%"}} styleInner={{width: "100%"}}>
                <CImageFaded
                  //  params
                  {...d}
                  sectionKey={"homeTailorMade"}
                  sourcesWp={fadedImages}
                  baseTransform={`scale(1.9) translate(6.5%,4%)`}
                  extraTransforms={[`scale(1.1) translate(-3%,5%)`]} // depending on layout
                />
              </Parallax>
            </ImageFadedResponsive>
          </ContentImage>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

const PaddedContainer = styled.div`
  display: flex;
  align-items: center;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}

  min-height: 100vh;
  flex-direction: row; // 2 Cols, Image last
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column-reverse; // 2 Rows, Image first
      min-height: 0;
      padding-top: 80px;
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, "CHomeTailorMade.PaddedContainer", "rgba(0,0,255,0.35)")}
`

const ContentText = styled.div`
  padding: 50px 0px;
  z-index: 1;

  flex: 4;
  ${props => css`
    ${props.theme.media.smdown} {
      flex: 0;
      height: auto;
      // background-color: rgba(255, 255, 255, 0.5);
    }
  `}

  color: ${props => props.theme.textColor};
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;

  ${props => selectors.getDebugOverlayCss(props, "ContentText", "rgba(0,255,255,0.75)")}
`

const ContentTextPadded = styled.div`
  padding-right: 20px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-right: 0px;
    }
  `}
`

const ContentImage = styled.div`
  align-self: stretch;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 3;
  ${props => css`
    ${props.theme.media.smdown} {
      flex: 0;
      height: auto;
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, "ContentImage", "rgba(255,255,0,0.35)")}
`

const ImageFadedResponsive = styled.div`
  width: 100%;
  padding: 80px 0px;
  ${props => css`
    ${props.theme.media.sm} {
      width: 50%;
      padding: 80px 0px;
    }
    ${props.theme.media.xs} {
      width: 60%;
      padding: 50px 0px;
    }
  `}
  ${props => selectors.getDebugOverlayCss(props, "ImageFadedResponsive", "rgba(255,0,0,0.35)")}
`

export default CHomeTailorMade
