import * as actionsConsts from "../actionsConsts"
import {getObjectDeep} from "../../data/selectors/helpers" // eslint-disable-line

export const initialState = {
  contactRequests: {},
  postData: {},
  postDataLastUpdated: null,
  geoInfos: {},
  suggestedCurrency: undefined,
  publicWalls: {},
  publicWallsFetchState: {},
  knowledgeBaseArticles: {
    items: null,
    busy: false,
  },
  galleryStats: {}, // by secretCode
}

export function api(state = initialState, action) {
  switch (action.type) {
    case actionsConsts.CLEAR_CONTACT_REQUESTS: {
      return {...state, contactRequests: initialState.contactRequests}
    }
    case actionsConsts.REQUEST_SEND_CONTACT_REQUEST: {
      const {id, fields} = action
      return {...state, contactRequests: {...state.contactRequests, [id]: {busy: true, type: "getintouch", fields}}}
    }
    case actionsConsts.RECEIVE_SEND_CONTACT_REQUEST: {
      const {id, success, error} = action
      return {...state, contactRequests: {...state.contactRequests, [id]: {busy: false, type: "getintouch", success, error}}}
    }
    case actionsConsts.REQUEST_POST_DATA: {
      const {path} = action
      const entry = state.postData.hasOwnProperty(path) ? state.postData[path] : {}
      return {...state, postData: {...state.postData, [path]: {...entry, busy: true}}}
    }
    case actionsConsts.RECEIVE_POST_DATA: {
      const {path, data, pageTemplate, postType, success, debug} = action
      const dataClean = getPostDataReduced(data, pageTemplate, postType, debug)

      if (debug) debugger

      // if (!pageTemplate) { debugger } // prettier-ignore
      return {...state, postData: {...state.postData, [path]: {busy: false, success, pageTemplate, data: dataClean}, postDataLastUpdated: new Date().getTime()}}
    }
    case actionsConsts.CLEAR_POST_DATA: {
      // TODO: Remove actionsConsts.CLEAR_POST_DATA
      // console.error("Don't use CLEAR_POST_DATA any more.")
      let postDataCleared = {}
      Object.keys(state.postData).map(path => {
        const entry = state.postData[path]
        if (!entry.busy && !!entry.data && !!entry.pageTemplate) {
          postDataCleared[path] = entry
        }
      })
      return {...state, postData: postDataCleared}
    }

    case actionsConsts.REQUEST_GEO_INFOS: {
      return {...state, geoInfos: {...state.geoInfos, busy: true}}
    }
    case actionsConsts.RECEIVE_GEO_INFOS: {
      const {data} = action
      return {...state, geoInfos: {...state.geoInfos, busy: false, data}}
    }
    case actionsConsts.REQUEST_WHITEPAPER: {
      const {id, fields} = action
      return {...state, contactRequests: {...state.contactRequests, [id]: {busy: true, type: "whitepaper", fields}}}
    }
    case actionsConsts.RECEIVE_WHITEPAPER: {
      const {id, success, error} = action
      return {...state, contactRequests: {...state.contactRequests, [id]: {busy: false, type: "whitepaper", success, error}}}
    }
    case actionsConsts.REQUEST_ADD_NEWSLETTER_SUBSCRIBER: {
      const {id, fields} = action
      return {...state, contactRequests: {...state.contactRequests, [id]: {busy: true, type: "newsletter", fields}}}
    }
    case actionsConsts.RECEIVE_ADD_NEWSLETTER_SUBSCRIBER: {
      const {id, success, error} = action
      return {...state, contactRequests: {...state.contactRequests, [id]: {busy: false, type: "newsletter", success, error}}}
    }
    case actionsConsts.RECEIVE_SUGGESTED_CURRENCY: {
      const {suggestedCurrency} = action
      return {...state, suggestedCurrency}
    }
    case actionsConsts.REQUEST_PUBLIC_WALLS_FOR_GALLERY: {
      const {gallerySlug} = action
      return {
        ...state,
        publicWallsFetchState: {
          ...state.publicWallsFetchState,
          [gallerySlug]: {
            busy: true,
          },
        },
      }
    }
    case actionsConsts.RECEIVE_PUBLIC_WALLS_FOR_GALLERY: {
      const {gallerySlug, publicWalls} = action
      return {
        ...state,
        publicWalls: {
          ...state.publicWalls,
          [gallerySlug]: {
            busy: false,
            data: publicWalls,
          },
        },
      }
    }
    case actionsConsts.REQUEST_KNOWLEDGEBASE_ARTICLES: {
      return {...state, knowledgeBaseArticles: {...state.knowledgeBaseArticles, busy: true}}
    }
    case actionsConsts.RECEIVE_KNOWLEDGEBASE_ARTICLES: {
      const {items} = action
      return {...state, knowledgeBaseArticles: {...state.knowledgeBaseArticles, busy: false, items}}
    }
    case actionsConsts.RECEIVE_GALLERY_STATS: {
      const {secretCode, yearMonth, data} = action
      // debugger
      return {
        ...state,
        //
        galleryStats: {
          //
          ...state.galleryStats,
          [secretCode]: {
            ...(!!state.galleryStats[secretCode] ? {...state.galleryStats[secretCode]} : {}),
            [yearMonth]: data,
          },
        },
      }
    }
    default: {
      return state
    }
  }
}

const whitelistKeysDefault = [
  //
  "id",
  "slug",
  "title",
  "lang",
  "path",
  "url",
  "contentFiltered",
  "dateTimestamp",
  "seoData",
  "featuredImage",
  "sectionMenuProperties",
]
const whitelistKeysPortfolioSubpages = ["sectionTop", "galleryPackages", "sectionCustomPricingInfo", "sectionBelowPricing", "sectionDemo", "sectionGetInTouch"]

const whitelistKeysTemplates = [
  {
    //
    pageTemplate: "home", //.php
    keys: ["sectionTop", "sectionPartners", "sectionTailormade", "sectionRightAngle", "sectionBrandMap", "sectionBenefits", "sectionTutorials", "sectionWhitepaper", "sectionDemo", "sectionGetInTouch"],
  },
  {
    //
    pageTemplate: "portfolio", //.php
    keys: ["sectionTop", "sectionTestimonials", "sectionGetInTouch", "sectionSupportedBy"],
  },
  {
    //
    pageTemplate: "portfolio-art-galleries", //.php
    keys: [...whitelistKeysPortfolioSubpages],
  },
  {
    //
    pageTemplate: "portfolio-art-institutions", //.php
    keys: [...whitelistKeysPortfolioSubpages],
  },
  {
    //
    pageTemplate: "portfolio-artists", //.php
    keys: [...whitelistKeysPortfolioSubpages],
  },
  {
    //
    pageTemplate: "aboutus", //.php
    keys: ["sectionOurStory", "sectionAboutIazzu", "sectionTeamMembers", "sectionAdvisoryBoard", "sectionDemo", "sectionGetInTouch", "sectionAmbassadors", "sectionCollaborations", "sectionWhy"],
  },
  {
    //
    pageTemplate: "demo", //.php
    keys: ["sectionTop", "sectionQrCode", "sectionQrMobile", "sectionGetInTouch"],
  },
  {
    //
    pageTemplate: "other-terms-and-conditions", //.php
    keys: ["sectionTop", "dateTimestamp", "contentFilteredNew"],
  },
  {
    //
    pageTemplate: "other-privacy-policy", //.php
    keys: ["sectionTop", "dateTimestamp", "contentFilteredNew"],
  },
  {
    //
    pageTemplate: "other-faq", //.php
    keys: ["sectionTop", "sectionHowItWorks", "sectionFAQs", "sectionExtraSentences", "sectionIntroVideo", "sectionGetInTouch"],
  },
  {
    //
    pageTemplate: "other-faq", //.php
    keys: ["sectionTop", "sectionHowItWorks", "sectionFAQs", "sectionExtraSentences", "sectionIntroVideo", "sectionGetInTouch"],
  },
  {
    //
    pageTemplate: "press", //.php
    keys: ["sectionTop", "sectionPressAppearances", "sectionPressKit", "sectionLogoGuidelines", "sectionUsabilityTerms", "sectionGetInTouch"],
  },
  {
    //
    pageTemplate: "contact", //.php
    keys: ["top_v3", "contact_infos_v3", "app_v3", "sectionTop", "sectionContactInfos", "sectionApp", "sectionGetInTouch"],
  },
  {
    //
    pageTemplate: "linking", //.php
    keys: ["todoSteps", "demoData"],
    ignoreWhitelistKeys: true,
  },
  {
    //
    pageTemplate: "gallery-landing", //.php
    keys: ["sectionPublicWalls", "sectionArtworks", "sectionDemo", "sectionFooter"],
  },
  {
    //
    pageTemplate: "gallery-landing-artwork", //.php
    keys: ["sectionGeneral", "sectionPublicWalls", "sectionDemo", "sectionFooter"],
  },
  {
    //
    pageTemplate: "umbrella", //.php
    keys: ["sectionTop", "sectionCategories", "sectionWhyIazzu", "sectionAppDownload"],
  },
  {
    //
    pageTemplate: "exhibitors", //.php
    keys: ["sectionTop", "sectionDownloadTop", "sectionFeatures", "sectionStats", "sectionFeatureMatrix", "sectionAdditionalServices", "sectionPortfolioLinks", "sectionTestimonials", "sectionPartners", "sectionAppDownload", "sectionNewsletter"],
  },
  {
    //
    pageTemplate: "artlovers", //.php
    keys: ["sectionTop", "sectionDownloadTop", "sectionFeatures", "sectionTestimonials", "sectionPartners", "sectionAppDownload", "sectionNewsletter"],
  },
  {
    //
    pageTemplate: "ovr", //.php
    keys: ["sectionFeatured", "sectionExhibitors"],
  },
  {
    //
    pageTemplate: "knowledgebase",
    keys: ["sectionTop", "sectionSearch", "sectionResults", "sectionAdvancedSupport", "sectionAppDownload", "sectionNewsletter"],
  },
  {
    //
    postType: "knowledgebasearticle",
    keys: ["sectionPrevNext"],
  },
  {
    //
    pageTemplate: "events", // https://iazzu.com/wp/wp-admin/edit.php?orderby=5ef1f35cc5a51&order=desc&s&post_status=all&post_type=page&m=0&layout=5f1f59f8542bc&acp_filter%5B5aeb8e759d096%5D=page-templates%2Fevents.php&filter_action=Filter&action=-1&paged=1&action2=-1
    keys: ["sectionEvents"],
  },
  {
    //
    postType: "galleryeventsite", // NBPTGalleryEventSite.php
    keys: ["eventData", "translationsSanified"],
  },
  {
    //
    pageTemplate: "blog",
    keys: ["sectionBlog"],
  },
  {
    //
    postType: "blog", // NBPTBlog.php
    keys: ["blogData", "translationsSanified", "sectionLabels"],
  },
]

const _getPostDataReducedKeys = (pageTemplate, postType) => {
  // first, check by post type (more specific)
  const entryByPostType = whitelistKeysTemplates.find(entry => entry.postType == postType)
  if (!!entryByPostType && !!entryByPostType.keys) {
    return entryByPostType.keys
  }

  // then, check by page template
  const entry = whitelistKeysTemplates.find(entry => entry.pageTemplate == pageTemplate)
  if (!!entry && !!entry.keys) {
    return entry.keys
  }

  return null
}

export const getPostDataReduced = (postData, pageTemplate, postType = undefined, debug = false) => {
  const pageTemplateKeys = _getPostDataReducedKeys(pageTemplate, postType)

  let ignore = false
  switch (postType) {
    case "gallery":
    case "product": {
      // gallery-landing, gallery-landing-artwork
      ignore = true
      break
    }
    case "knowledgebase":
    case "events":
      ignore = true
      break
  }

  if (debug) debugger

  if (!pageTemplateKeys || ignore) {
    if (!ignore) {
      __DEV__ && console.warn(`No whitelist keys for postType:${postType} / pageTemplate:${pageTemplate}; returning full post data.`)
      debugger
    }
    return postData
  }

  const template = whitelistKeysTemplates.find(i => i.pageTemplate == pageTemplate)
  const ignoreWhitelistKeys = getObjectDeep(template, "ignoreWhitelistKeys", false)
  const whitelistKeys = [...whitelistKeysDefault, ...pageTemplateKeys]

  let postDataReduced = null
  if (!!postData) {
    for (const whitelistKey of whitelistKeys) {
      if (__DEV__ && !ignoreWhitelistKeys && !postData.hasOwnProperty(whitelistKey)) {
        debugger
        throw new Error(`getPostDataReduced(): Missing key '${whitelistKey}' for post data of page template '${pageTemplate}'.`)
      }
    }
    postDataReduced = {}
    Object.keys(postData).map(key => {
      if (whitelistKeys.indexOf(key) != -1) {
        postDataReduced[key] = postData[key]
      }
    })
  }
  return postDataReduced
}
