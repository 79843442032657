import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import * as selectors from "../../../data/selectors" // eslint-disable-line

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class CHomeSubheader extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    text: PropTypes.string,
    variant: PropTypes.oneOf(["default", "home-hero", `home-whitepaper`, `home-demo`]),
    fontScale: PropTypes.number,
  }
  static defaultProps = {
    debug: false && __DEV__,
    variant: "default",
    fontScale: 1,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, text, variant, fontScale = 1} = this.props
    const d = {debug: debug || CHomeSubheader.defaultProps.debug}
    return <HomeSubheader variant={variant} fontScale={fontScale} {...d} dangerouslySetInnerHTML={{__html: text}} />
  }
}

const HomeSubheader = styled.div`
  letter-spacing: 0.1em;
  font-kerning: none;

  line-height: 1.7em;
  font-weight: ${props => props.theme.vars.fonts.weights.regular};

  font-size: 20px;
  ${props => css`
    ${props.theme.media.md} {
      font-size: 18px;
    }
    ${props.theme.media.sm} {
      font-size: 16px;
    }
    ${props.theme.media.xs} {
      font-size: 15px;
    }
  `}

  ${props =>
    props.variant == "home-hero" &&
    css`
      font-weight: ${props => props.theme.vars.fonts.weights.medium};
      font-size: ${props.fontScale * 20}px;

      ${props.theme.media.md} {
        font-size: ${props.fontScale * 20}px;
      }
      ${props.theme.media.smdown} {
        font-size: 20px;
        text-align: center;
      }
    `}

  text-align: left;
  ${props =>
    props.variant == `home-whitepaper` &&
    css`
      text-align: center;
    `}
  ${props =>
    props.variant == `home-demo` &&
    css`
      text-align: center;
      color: white;
      font-style: italic;
    `}

  ${props => css`
    ${props.theme.media.smdown} {
      // margin: 0px 20px;
    }
  `}

  ${props => selectors.getDebugOverlayCss(props, `HomeSubheader${props.variant ? `.${props.variant}` : ``}`, "rgba(255,0,0,0.25)")}
`

export default CHomeSubheader
