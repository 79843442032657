import React, {Component} from "react"
import PropTypes from "prop-types"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import isEqual from "lodash/isEqual"
import styled, {css, ThemeProvider} from "styled-components"
import {Row, Col} from "react-grid-system"

import * as selectors from "../../../data/selectors"

import {BranchManager} from "../../../data/utils"

import CLink from "../../../view/components/CLink"
import CBranchQrCode from "../../../view/components/CBranchQrCode"
import CExpandableContent from "./CExpandableContent"

const DEBUG = false && __DEV__
const debby = (funcName, data = null) => DEBUG && console.log("CSectionGalleryBasics." + funcName, data) // eslint-disable-line

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
class CSectionGalleryBasics extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    defaultThemeProps: PropTypes.object,
    postData: PropTypes.object.isRequired,
    galleryBranchShareInfos: PropTypes.shape({
      qrImageUrl: PropTypes.string, // "https://iazzu.com/api/qr/galleries/priskapasquer.png"
      url: PropTypes.string, //        "https://iazzu.app.link/priskapasquer"
    }),
    maxHeightDescription: PropTypes.number,

    // connect
    intl: PropTypes.object.isRequired,
    intlLocale: PropTypes.string.isRequired,
    logoImageUrl: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string, // can be HTML..
  }
  static defaultProps = {
    debug: DEBUG,
    defaultThemeProps: {
      // backgroundColor: "white",
      textColor: "black",
    },
    maxHeightDescription: 250,
  }

  constructor(props) {
    super(props)
    this.state = {
      // descriptionCollapsed: true,
      // descriptionNeedsCollapsing: true,
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  onDescriptionContentHeightChanged = height => {
    const descriptionNeedsCollapsing = height > this.props.maxHeightDescription
    // debby(`onDescriptionContentHeightChanged()`, {height, descriptionNeedsCollapsing})
    this.setState({descriptionNeedsCollapsing})
  }

  toggleExpandCollapse = () => this.setState({descriptionCollapsed: !this.state.descriptionCollapsed})

  render = () => {
    // const {descriptionCollapsed, descriptionNeedsCollapsing} = this.state
    const {intl, debug, defaultThemeProps, logoImageUrl, title, url, description, maxHeightDescription, galleryBranchShareInfos} = this.props
    const d = {debug: debug || CSectionGalleryBasics.defaultProps.debug}

    const rowProps = {
      type: "flex",
      align: "start",
      justify: "start",
      width: "100%",
      height: "100%",
      gutter: 30,
    }
    // const descriptionCollapsedFinal = descriptionCollapsed && descriptionNeedsCollapsing
    return (
      <ThemeProvider theme={{...defaultThemeProps}}>
        <PaddedContainer {...d} variant={"header"}>
          <Content
            //
            {...d}
          >
            <Row {...rowProps}>
              {/* Logo */}
              <Col {...d} lg={3} md={12} style={{backgroundColor: debug ? "red" : "none"}}>
                <PaddedLogoContainer {...d}>
                  <AspectContainer {...d} ratio={1 / 1}>
                    <CircledContent {...d}>
                      {!!logoImageUrl && (
                        <img
                          //
                          src={logoImageUrl}
                          style={{width: "100%", height: "100%", objectFit: "contain"}}
                        />
                      )}
                    </CircledContent>
                  </AspectContainer>
                </PaddedLogoContainer>
              </Col>

              {/* Infos */}
              <Col {...d} lg={6} md={12} style={{backgroundColor: debug ? "green" : "none", padding: "0px 10px 0px 10px"}}>
                <GalleryTitle {...d}>{title}</GalleryTitle>

                <CLink url={url}>
                  <GalleryLink>{selectors.getUrlReadable(url)}</GalleryLink>
                </CLink>

                <CExpandableContent
                  //
                  // intl={intl}
                  header={intl.formatMessage({id: `HomeGalleryInfosHeader`})}
                  html={description}
                  // collapsed={descriptionCollapsedFinal}
                  collapsedHeight={maxHeightDescription}
                  // collapsedHeight={2000}
                  // maxHeightDescription={}
                />
              </Col>

              <Col {...d} lg={3} md={12} style={{backgroundColor: debug ? "blue" : "none"}}>
                {!!galleryBranchShareInfos && !!galleryBranchShareInfos.qrImageUrl && (
                  <QrCodeContainerDesktopOnly {...d}>
                    <CBranchQrCode
                      //
                      label={intl.formatMessage({id: `GalleryLandingViewInApp`})}
                      qrImageUrl={galleryBranchShareInfos.qrImageUrl}
                    />
                  </QrCodeContainerDesktopOnly>
                )}
              </Col>
            </Row>
          </Content>
        </PaddedContainer>
      </ThemeProvider>
    )
  }
}

const PaddedContainer = styled.div`
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, props.variant)}
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all ${props => props.theme.vars.transitions.themeTransitionDuration}s linear;
  ${props => props.debug && selectors.getDebugOverlayCss(props, "CSectionGalleryBasics.PaddedContainer", "rgba(0,0,255,0.35)")}
`

const Content = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  ${props => selectors.getDebugOverlayCss(props, "Content", "rgba(0,255,0,0.35)")}
`

const PaddedLogoContainer = styled.div`
  padding-right: 50px;
  ${props => css`
    ${props.theme.media.mddown} {
      padding-right: 0px;
      max-width: 200px;
      margin: 0 auto;
    }
  `}
`

const AspectContainer = styled.div`
  position: relative;
  padding-top: ${props => (1 / props.ratio) * 100}%;
  ${props => props.debug && selectors.getDebugOverlayCss(props, `AspectContainer`, `rgba(255,0,255,0.35)`)}
  overflow: visible;
`

const CircledContent = styled.div`
  position: absolute;
  left: 2px;
  right: 2px;
  top: 2px;
  bottom: 2px;
  border: 2px solid ${props => props.theme.vars.colors.trout};
  border-radius: 1000px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 10%; // TODO: Make dynamic
  justify-content: center;
`

const GalleryTitle = styled.div`
  font-size: 24px;
  line-height: 30px;
  font-weight: ${props => props.theme.vars.fonts.weights.extrabold};
  letter-spacing: 0.15em;
  font-kerning: none;
  // text-transform: uppercase;
  hyphens: auto;
  color: #282828;
  width: 100%;

  ${props => true && props.debug && selectors.getDebugOverlayCss(props, `GalleryTitle`, `rgba(0,255,255,0.35)`)}

  margin-bottom: 15px;
  ${props => css`
    ${props.theme.media.mddown} {
      margin-top: 30px;
      text-align: center;
    }
  `}
`

const GalleryLink = styled.div`
  font-size: 16px;
  font-weight: ${props => props.theme.vars.fonts.weights.default};
  letter-spacing: 0.15em;
  font-kerning: none;
  hyphens: auto;
  color: #282828;
  text-decoration: underline;
  margin-bottom: 40px;
  ${props => css`
    ${props.theme.media.mddown} {
      text-align: center;
    }
  `}
`

const QrCodeContainerDesktopOnly = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  ${props => css`
    ${props.theme.media.mddown} {
      display: none;
    }
  `}
`

const getPostData = (state, props) => props.postData

const getLogoImageUrl = createSelector([getPostData], postData => {
  if (postData?.galleryGql) {
    return postData.galleryGql?.webLogoImageUrl || null
  }

  const imageSize = "1000"
  const logoColorUrl = selectors.getObjectDeep(postData, `brandingInfosLogoColor.sizes.${imageSize}.url`)
  const logoWhiteUrl = selectors.getObjectDeep(postData, `brandingInfosLogoWhite.sizes.${imageSize}.url`)
  if (!!logoWhiteUrl) {
    return logoWhiteUrl
  }
  return logoColorUrl
})

const getGalleryTitle = createSelector([getPostData], postData => selectors.getObjectDeep(postData, `title`))
const getGalleryUrl = createSelector([getPostData], postData => BranchManager.getBranchUrl(selectors.getObjectDeep(postData, `secretCode`)))
const getGalleryDescription = createSelector([getPostData, selectors.getIntlLocale], (postData, intlLocale) => {
  return selectors.getObjectDeep(postData, `languageVariants.${intlLocale}.contentFiltered`, selectors.getObjectDeep(postData, `contentFiltered`))
})

const mapStateToProps = (state, props) => ({
  // fadedImageSources: getFadedImageSources(state, props),
  logoImageUrl: getLogoImageUrl(state, props),
  title: getGalleryTitle(state, props),
  url: getGalleryUrl(state, props),
  description: getGalleryDescription(state, props),
  intlLocale: selectors.getIntlLocale(state),
})
export default injectIntl(connect(mapStateToProps)(CSectionGalleryBasics))
