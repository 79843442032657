import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import styled, {css} from "styled-components" // eslint-disable-line
import * as selectors from "../../../data/selectors" // eslint-disable-line

class CH2 extends Component {
  static propTypes = {
    debug: PropTypes.bool,
    text: PropTypes.string,
    variant: PropTypes.oneOf(["default", "home-hero", "home-demo", "porfolio-top", "knowledgebase", "knowledgebase-article"]),
  }
  static defaultProps = {
    debug: false && __DEV__,
    variant: "default",
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {debug, text, variant} = this.props
    const d = {debug: debug || CH2.defaultProps.debug}
    return <H2 variant={variant} {...d} dangerouslySetInnerHTML={{__html: text}} />
  }
}

const H2 = styled.h2`
  letter-spacing: 0.15em;
  font-kerning: none;
  text-transform: uppercase;
  padding: 0;
  margin: 0;

  hyphens: auto;

  font-weight: ${props => props.theme.vars.fonts.weights.extrabold};
  line-height: 1.3;

  font-size: 36px;
  ${props => css`
    ${props.theme.media.md} {
      font-size: 30px;
    }
    ${props.theme.media.sm} {
      font-size: 28px;
    }
    ${props.theme.media.xs} {
      font-weight: ${props => props.theme.vars.fonts.weights.bold};
      font-size: 24px;
    }
  `}

  ${props =>
    props.variant == "home-hero" &&
    css`
      font-size: 30px;
      line-height: 1.2;
      font-weight: ${props => props.theme.vars.fonts.weights.medium};
    `}

  ${props =>
    props.variant == "home-demo" &&
    css`
      color: white;
      text-align: center;
    `}

  ${props =>
    props.variant == "knowledgebase" &&
    css`
      text-align: center;
    `}

  ${props =>
    props.variant == "knowledgebase-article" &&
    css`
      text-transform: none;
    `}

  ${props => selectors.getDebugOverlayCss(props, "H2", "rgba(0,255,0,0.25)")}
`

export default CH2
