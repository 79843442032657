import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {injectIntl} from "react-intl"
import {createSelector} from "reselect"
import {replace} from "connected-react-router"
import queryString from "query-string"
import styled, {css} from "styled-components"
import FlipMove from "react-flip-move"

import * as selectors from "../../../data/selectors"

import CIcon from "../../../view/components/CIcon"
import CImage from "../../../view/components/CImage"
import CLink from "../../../view/components/CLink"
import CButton from "../../../view/components/CButton"
import CH3 from "../../../view/components/text/CH3"

import COVRFilters from "./COVRFilters"

const DEBUG = false && __DEV__
import {debbify, getObjectDeep as god} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("COVRExhibitors", ...args)

// const SORT_BY_DEFAULT = "atoz"
const SORT_BY_DEFAULT = "newest"
const KEEP_FILTER_SETTINGS_WHEN_FILTERS_HIDDEN = true

const AMOUNT_INIT = 9
const AMOUNT_ADDED_PER_LOAD_MORE_STEP = 6 // eslint-disable-line

class COVRExhibitors extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    debug: PropTypes.bool,
    intlLocale: PropTypes.string,
    queryProps: PropTypes.object,
    resetFiltersText: PropTypes.string,
    headerText: PropTypes.string,
    filterLabelText: PropTypes.string,
    sortByText: PropTypes.string,
    sortByCountryText: PropTypes.string,
    galleries: PropTypes.array,
    // connect
    filtersShown: PropTypes.bool,
    // sortBy: PropTypes.oneOf(["atoz", "ztoa", "featured", "newest", "oldest"]),
    sortBy: PropTypes.oneOf(["newest", "oldest", "featured", "atoz", "ztoa"]),
    galleriesFinal: PropTypes.array,
    galleriesCountries: PropTypes.object,
    countriesSelected: PropTypes.array,
    galleriesAmountShown: PropTypes.number,
    mediaQueryClass: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]).isRequired,
    searchQuery: PropTypes.string,
    searchTextPlaceholder: PropTypes.string,
    noItemsText: PropTypes.string,
  }
  static defaultProps = {
    debug: DEBUG,
  }

  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     searchQuery: "",
  //   }
  // }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentWillUnmount = () => {
    clearTimeout(this.resetFiltersTimeoutId)
  }

  toggleFilters = () => {
    debby("toggleFilters()")
    const hidden = this.props.filtersShown
    this.updateQueryProps({f: !this.props.filtersShown ? "on" : "off"})

    clearTimeout(this.resetFiltersTimeoutId)
    if (hidden) {
      this.resetFiltersTimeoutId = setTimeout(this.resetFilters, 500)
    }
  }

  resetFilters = () => {
    debby("resetFilters()")
    this.updateQueryProps({s: SORT_BY_DEFAULT, c: "", q: ""})
  }

  onSortByChange = sortByKey => {
    debby("onSortByChange()", {sortByKey})
    this.updateQueryProps({s: sortByKey})
  }

  onCountryClick = country => {
    const {countriesSelected, galleriesCountries} = this.props
    debby("onCountryClick()", {country, countriesSelected})
    let countriesSelectedNew = [...countriesSelected]
    if (!countriesSelected.find(c => c.toLowerCase() == country.toLowerCase())) {
      // add
      countriesSelectedNew.push(country.toLowerCase())
    } else {
      // remove
      countriesSelectedNew.splice(countriesSelected.indexOf(country.toLowerCase()), 1)
    }
    if (countriesSelectedNew.length == Object.keys(galleriesCountries).length) {
      countriesSelectedNew = []
    }
    this.updateQueryProps({c: countriesSelectedNew.join(",")})
  }

  loadMoreItems = () => {
    const {galleriesAmountShown} = this.props
    this.updateQueryProps({m: galleriesAmountShown + AMOUNT_ADDED_PER_LOAD_MORE_STEP})
  }

  updateQueryProps = (propsShortKeyed = {}) => {
    let propsNext = {...this.props.queryProps, ...propsShortKeyed}
    let propsFiltered = {}
    for (const queryKey of Object.keys(propsNext)) {
      switch (queryKey) {
        case "f": {
          // filters shown
          if (propsNext[queryKey] == "on") {
            propsFiltered[queryKey] = "on"
          }
          break
        }
        case "s": {
          // sort by
          if (propsNext[queryKey] != SORT_BY_DEFAULT) {
            propsFiltered[queryKey] = propsNext[queryKey]
          }
          break
        }
        case "c": {
          // country
          if (propsNext[queryKey] != "") {
            propsFiltered[queryKey] = propsNext[queryKey]
          }
          break
        }
        case "m": {
          // max amount shown galleries
          propsFiltered[queryKey] = propsNext[queryKey]
          break
        }
        case "q": {
          // max amount shown galleries
          propsFiltered[queryKey] = propsNext[queryKey]
          break
        }
        default: {
          if (__DEV__) {
            throw new Error(`updateQueryProps(): Unhandled queryKey '${queryKey}'.`)
          }
        }
      }
    }
    this.props.dispatch(replace({search: queryString.stringify(propsFiltered, {encode: false})}))
  }

  onSearchQueryChange = searchQuery => {
    debby("onSearchQueryChange()", {searchQuery})
    // this.setState({searchQuery})
    this.updateQueryProps({q: searchQuery})
  }

  render = () => {
    // const {searchQuery} = this.state
    const {searchQuery} = this.props
    const {intl, intlLocale, resetFiltersText, headerText, sortByText, filterLabelText, sortByCountryText, searchTextPlaceholder, noItemsText} = this.props
    const {filtersShown, sortBy, countriesSelected, galleries, galleriesFinal, galleriesCountries, galleriesAmountShown} = this.props
    const d = {debug: DEBUG || this.props.debug}
    if (!galleries || !galleries.length) {
      return <div />
    }
    debby("render()", {filtersShown, searchQuery, galleriesCountries, sortBy, countriesSelected, amountGalleries: galleriesFinal.length, galleriesAmountShown})
    const galleriesShown = galleriesFinal.slice(0, galleriesAmountShown)
    const hasMoreItems = galleriesShown.length < galleriesFinal.length

    const showNoItemsAvailable = !!galleries && !!galleries.length && !galleriesFinal.length
    return (
      <Container {...d} {...d}>
        <HeaderContainer {...d}>
          <H3Container {...d}>
            <CH3 text={headerText} noWordWrap />
          </H3Container>
          <HeaderFilterContainer
            //
            {...d}
            onClick={this.toggleFilters}
            hasSettings={sortBy != SORT_BY_DEFAULT || galleriesFinal.length != galleries.length}
          >
            <CIconOvrFilterContainer {...d}>
              <CIcon id={"OvrFilters"} />
            </CIconOvrFilterContainer>
            <FilterButtonLabel {...d}>{filterLabelText}</FilterButtonLabel>
          </HeaderFilterContainer>
        </HeaderContainer>
        <FiltersContainer {...d}>
          <COVRFilters
            //
            intlLocale={intlLocale}
            shown={filtersShown}
            resetFiltersText={resetFiltersText}
            onResetClick={this.resetFilters}
            sortBy={sortBy}
            sortByHeader={sortByText}
            onSortByChange={this.onSortByChange}
            filterByHeader={sortByCountryText}
            galleriesCountries={galleriesCountries}
            countriesSelected={countriesSelected}
            onCountryClick={this.onCountryClick}
            // new
            showSearch={true}
            searchTextPlaceholder={searchTextPlaceholder}
            mediaQueryClass={this.props.mediaQueryClass}
            searchQuery={searchQuery}
            onSearchQueryChange={this.onSearchQueryChange}
          />
        </FiltersContainer>

        {showNoItemsAvailable && <NoItemsFoundContainer {...d}>{noItemsText}</NoItemsFoundContainer>}

        <ItemsContainer {...d}>
          <FlipMove
            //
            staggerDurationBy={"50"}
            duration={500}
            // enterAnimation={"accordianVertical"}
            // leaveAnimation={"accordianVertical"}
            typeName={"div"}
          >
            {galleriesShown.map((gallery, i) => {
              const {slug, title} = gallery
              const {src, srcSet} = selectors.getSrcAndSrcSetByWordpressImage(god(gallery, "featuredImage")) // eslint-disable-line
              const src1000 = god(gallery, "featuredImage.sizes.1000.url") // eslint-disable-line
              const linkTo = `/g/${slug}`
              const city = god(gallery, `city.${intlLocale}`)
              const countryReadable = intl.formatMessage({id: `Country${god(gallery, "country", "CH").toUpperCase()}`})
              return (
                <SingleItem {...d} key={`gallery_${slug}`}>
                  <CLink to={linkTo} preloadDelayMs={i * 100} stretched preloadPostData={i < 50}>
                    <SinglePaddedItem {...d}>
                      <CImage
                        //
                        // src={src}
                        // srcSet={srcSet}
                        src={src1000}
                        ratio={365 / 300}
                        ratiosBreakpoints={{
                          //
                          md: 16 / 9,
                          sm: 16 / 9,
                          xs: 16 / 9,
                        }}
                      />
                      <ItemTitle>{title}</ItemTitle>
                      <ItemSubTitle>
                        {!!city ? `${city}, ` : ``}
                        {countryReadable}
                      </ItemSubTitle>
                    </SinglePaddedItem>
                  </CLink>
                </SingleItem>
              )
            })}
          </FlipMove>

          {hasMoreItems && (
            <div style={{width: "100%", paddingTop: 20}}>
              <CButton
                //
                variant={"home-whitepaper"}
                label={intl.formatMessage({id: `LoadMoreItems`})}
                onClick={this.loadMoreItems}
              />
            </div>
          )}
        </ItemsContainer>
      </Container>
    )
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column; // so it's vertical, for spacer
  ${props => selectors.getBackgroundColorByProps(props, "rgba(0,0,255,0.25)")}

  padding-top: 100px;
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 50px;
    }
  `}
`

const HeaderContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "HeaderContainer", "rgba(0,255,255,0.35)")};
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
  display: flex;
  padding-bottom: 30px;
  ${props => css`
    ${props.theme.media.smdown} {
      flex-direction: column;
      padding-bottom: 10px;
    }
  `}
`
const H3Container = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
  ${props => selectors.getDebugOverlayCss(props, "H3Container", "rgba(0,0,255,0.35)")};
`
const HeaderFilterContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: opacity 0.3s;
  ${props =>
    props.hasSettings &&
    css`
      opacity: 1;
    `}
  ${props => selectors.getDebugOverlayCss(props, "FBC", "rgba(255,0,255,0.35)")};
  ${props => css`
    ${props.theme.media.smdown} {
      padding-top: 10px;
      justify-content: flex-end;
    }
  `}
`

const CIconOvrFilterContainer = styled.div`
  ${props => selectors.getBackgroundColorByProps(props, "rgba(255,0,255,0.25)")}
  margin-right: 10px;
`
const FilterButtonLabel = styled.div`
  font-size: 17px;
  letter-spacing: 0.11em;
`

const FiltersContainer = styled.div`
  ${props => selectors.getDebugOverlayCss(props, "FiltersContainer", "rgba(0,255,255,0.35)")};
`

const GALLERIES_GUTTER_PX = 20

const NoItemsFoundContainer = styled.div`
  // padding-top: 15px;
  // letter-spacing: 0.08em;
  font-size: 18px;
  line-height: 1.3;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  color: ${props => props.theme.vars.colors.codGray} !important;

  ${props => selectors.getDebugOverlayCss(props, "NoItemsFoundContainer", "rgba(255,0,0,0.35)")};
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
`

const ItemsContainer = styled.div`
  padding-top: 10px;
  position: relative;
  margin: 0px -${GALLERIES_GUTTER_PX}px;
  background-color: white;
  text-align: center;
  ${props => selectors.getDebugOverlayCss(props, "ItemsContainer", "rgba(255,0,0,0.35)")};
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "header")}
`

const SingleItem = styled.div`
  display: inline-block;
  vertical-align: top; // remove space below
  text-align: left;
  cursor: pointer !important;

  width: 32.7%;
  padding-bottom: 30px;
  ${props => css`
    ${props.theme.media.md} {
      width: 50%;
      padding-bottom: 20px;
    }
  `}
  ${props => css`
    ${props.theme.media.smdown} {
      width: 100%;
      padding-bottom: 20px;
    }
  `}
`

const SinglePaddedItem = styled.div`
  padding: ${GALLERIES_GUTTER_PX / 2}px;
  border: ${props => (props.debug ? 1 : 0)}px solid blue;
`

const ItemTitle = styled.div`
  padding-top: 15px;
  letter-spacing: 0.08em;
  font-size: 18px;
  line-height: 1.3;
  font-weight: ${props => props.theme.vars.fonts.weights.bold};
  color: ${props => props.theme.vars.colors.codGray} !important;
`

const ItemSubTitle = styled.div`
  padding-top: 5px;
  letter-spacing: 0.08em;
  font-size: 18px;
  line-height: 1.3;
  color: ${props => props.theme.vars.colors.codGray} !important;
  opacity: 0.7;
  // font-weight: ${props => props.theme.vars.fonts.weights.bold};
`

const getPropsGalleries = (state, props) => props.galleries

const getGalleriesCountries = createSelector([getPropsGalleries], galleries => {
  if (!galleries || !galleries.length) {
    return null
  }
  let countries = {}
  for (const gallery of galleries) {
    const {country} = gallery
    if (!countries.hasOwnProperty(country)) {
      countries[country] = {amount: 0}
    }
    countries[country].amount += 1
  }
  return countries
})

const getFiltersShown = createSelector([selectors.getQueryProps], queryProps => god(queryProps, "f", "off") == "on")
const getSortBy = createSelector([selectors.getQueryProps], queryProps => god(queryProps, "s", SORT_BY_DEFAULT))
const getCountriesSelected = createSelector([selectors.getQueryProps], queryProps =>
  god(queryProps, "c", "")
    .split(",")
    .filter(i => !!i)
    .map(c => c.toLowerCase()),
)

const getSearchQuery = createSelector([selectors.getQueryProps], queryProps => god(queryProps, "q", ""))

const getGalleriesFinal = createSelector([getPropsGalleries, getFiltersShown, getSortBy, getCountriesSelected, getSearchQuery], (galleries, filtersShown, sortBy, countriesSelected, searchQuery) => {
  if (!galleries) {
    return null
  }

  let galleriesFinal = [...galleries]

  if (filtersShown || KEEP_FILTER_SETTINGS_WHEN_FILTERS_HIDDEN) {
    if (!!countriesSelected && !!countriesSelected.length) {
      galleriesFinal = galleriesFinal.filter(g => countriesSelected.indexOf(g.country.toLowerCase()) != -1)
    }
    if (!!searchQuery) {
      galleriesFinal = galleriesFinal.filter(g => g.title.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1)
    }
    galleriesFinal = galleriesFinal.sort((a, b) => {
      const retNewest = a.dateTimestamp > b.dateTimestamp ? -1 : a.dateTimestamp < b.dateTimestamp ? 1 : 0
      switch (sortBy) {
        case "atoz":
          return a.title.localeCompare(b.title)
        case "ztoa":
          return b.title.localeCompare(a.title)
        case "featured":
          return a.isListed && !b.isListed ? -1 : !a.isListed && b.isListed ? 1 : retNewest
        case "newest":
          return retNewest
        case "oldest":
          return a.dateTimestamp < b.dateTimestamp ? -1 : a.dateTimestamp > b.dateTimestamp ? 1 : 0
        default:
          debugger
          throw new Error(`getGalleriesFinal(): Unhandled sortBy value '${sortBy}'.`)
        // return 0
      }
    })
  }

  return galleriesFinal
})

const getGalleriesAmountShown = createSelector([getGalleriesFinal, selectors.getQueryProps], (galleriesFinal, queryProps) => {
  if (!galleriesFinal || !galleriesFinal.length) {
    return 0
  }
  const queryAmount = parseInt(god(queryProps, "m", AMOUNT_INIT))
  const amount = Math.min(galleriesFinal.length, queryAmount)
  return amount
})

const mapStateToProps = (state, props) => ({
  queryProps: selectors.getQueryProps(state, props),
  filtersShown: getFiltersShown(state, props),
  sortBy: getSortBy(state, props),
  galleries: getPropsGalleries(state, props),
  galleriesFinal: getGalleriesFinal(state, props),
  galleriesCountries: getGalleriesCountries(state, props),
  galleriesAmountShown: getGalleriesAmountShown(state, props),
  countriesSelected: getCountriesSelected(state, props),
  searchQuery: getSearchQuery(state, props),
  mediaQueryClass: selectors.getMediaQueryClass(state),
})

export default injectIntl(withRouter(connect(mapStateToProps)(COVRExhibitors)))
